import {
	useEffect,
	useState
} from "react";

const useFetchData = (getter) => {
	const [results, setResults] = useState([]);
	const [status, setStatus] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let mounted = true;
		setIsLoading(true);

		const fetchData = async () => {
			try {
				await getter().then((response) => {
					if (!mounted) return;
					if (response.status !== 200) return;

					setResults(response.data);
					setStatus(true);
				}).catch(() => {
					setStatus(false);
				});

			} catch (e) {
				setStatus(false);
			}

			setIsLoading(false);
		};
		fetchData();

		return () => {
			mounted = false;
		};
	}, [getter]);

	return {
		status, results, isLoading
	};
};

export default useFetchData;
