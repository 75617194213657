import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
	List,
	ListItem,
	ListItemText,
	Button,
	Snackbar,
	TextField,
	FormControl,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Link
} from "@mui/material";
import Portal from "@mui/base/Portal";
import CircularProgress from "@mui/material/CircularProgress";
import { DataContext } from "../../../App";
import fileDownload from "js-file-download";
import { AlertBox } from "../../Shared/Alert/Alert";
import DropDown from "../../Shared/DropDown";
import {
	Form,
	Formik
} from "formik";
import ReactMarkdown from "react-markdown";
import "./GenerateAReturnLabel.scss";
import useFetchData from "../../Shared/FetchData";
import { LoadingStatusTable } from "../../Shared/LoadingStatus/LoadingStatus";
import { getDisplayDate } from "../../../utils/date";
import {
	capitalizeFirstLetter,
	capitalizeFirstLetterOfEachWord
} from "../../../utils/text";

export default function GenerateAReturnLabel({ orderID, seller, orderNumber, warehouseCurrency, postcode, productsList, isVisible, requireCourier }) {
	if (!isVisible) return;

	const context = React.useContext(DataContext);
	const [state, setState] = useState({
		isVisibleGenerateReturnLabelDiv: isVisible,
		isVisibleDownloadReturnLabelButton: false,
		isVisiblePollReturnLabelButton: false,
		isGenerateReturnLabelButtonDisabled: true,
		downloadLabelIsLoading: false,
		pollLabelIsLoading: false,
		returnLabelIsLoading: false,
		returnLabelStatus: null,
		returnLabelResults: "",
		showConfirmationAlert: false,
		returnLabelPollerToken: "",
		returnLabelDownloadToken: "",
		downloadReturnLabelStatus: false,
		showDownloadConfirmationAlert: false,
		createReturnErrorMessage: "",
	});

	const [returnCourierID, setReturnCourierID] = useState("");
	const [returnCourierCode, setReturnCourierCode] = useState("");
	const [returnCourierDeliveryOption, setReturnCourierDeliveryOption] = useState("");
	const [courierServices, setCourierServices] = useState({ data: [], isLoading: false });

	const existingReturnLabels = useFetchData(useCallback(() => context.dataProvider.getReturnLabels(seller, orderNumber), [context.dataProvider, seller, orderNumber]));

	useEffect(() => {
		if (orderID) {
			const getReturnCourierServiceList = async () => {
				setCourierServices({ isLoading: true });
				await context.dataProvider.getAllReturnCourierServicesForOrder(seller, orderID)
					.then((response) => {
						setCourierServices({ isLoading: false, data: response.data.sort((a, b) => a.name > b.name ? -1 : 1) });
					})
					.catch(() => {
						setCourierServices({ isLoading: false, data: [] });
					});
			};
			getReturnCourierServiceList();
		}
	}, [orderID, seller, context.dataProvider]);

	const handleCourierServiceChange = (e) => {
		const [carrier_code, id, delivery_option] = e.split(", ");
		setReturnCourierCode(carrier_code);
		setReturnCourierID(id);
		setReturnCourierDeliveryOption(delivery_option);

		setState(previousState => {
			return {
				...previousState,
				isGenerateReturnLabelButtonDisabled: itemQuantities.size === 0 || (id === "" && requireCourier === true)
			};
		});
	};
	
	const [itemQuantities, setItemQuantities] = useState(new Map());
	const defaultWeightLimit = 15.0;

	const CheckboxList = () => {
		const onQuantityChange = (e, name) => {
			const currentItemQuantities = itemQuantities;

			if (e.target.value <= 0) {
				currentItemQuantities.delete(name);
			} else {
				currentItemQuantities.set(name, parseInt(e.target.value, 10));
			}

			setItemQuantities(currentItemQuantities);
			setState(previousState => {
				return {
					...previousState,
					isGenerateReturnLabelButtonDisabled: currentItemQuantities.size === 0 || (returnCourierID === "" && requireCourier === true)
				};
			});
		};

		return (
			<>
				<List sx={{ width: "100%", bgcolor: "background.paper", padding: 1 }}>
					{productsList.map((item, index) => {
						const labelId = `checkbox-list-label-${index}`;
						const itemDisabled = (state.isVisibleDownloadReturnLabelButton || item.weight > defaultWeightLimit);
						return (
							<ListItem
								key={index}
								sx={{ padding: 0 }}
							>
								<ListItemText id={labelId} primary={item.name} secondary={item.sku} sx={{ maxWidth: "300px" }}/>
								{item.weight > defaultWeightLimit &&
									<AlertBox
										severity="warning"
										message="This product is too large to be returned with InPost,
										please email support@bezos.ai for an ad-hoc return label"
									/>
								}
								<TextField
									label="Quantity to return"
									type="number"
									size="small"
									sx={{ mr: 1, ml: 1, minWidth: 180 }}
									disabled={itemDisabled}
									defaultValue={itemQuantities.get(item.name)}
									onChange={(e) => onQuantityChange(e, item.name)}
									InputProps={{
										inputProps: {
											min: 0, max: item.quantity
										}
									}}
								/> of {item.quantity}
							</ListItem>
						);
					})}
				</List>
				<Grid container spacing={1}>
					<Grid item xs={12} container spacing={2} mb={2}>
						<Grid item xs="auto" sx={{ mt: 2 }}>
							<Button
								variant="contained"
								disabled={state.isGenerateReturnLabelButtonDisabled || state.isVisiblePollReturnLabelButton
									|| state.isVisibleDownloadReturnLabelButton}
								onClick={onClickGenerateReturnLabel}
							>
								Generate Label {state.returnLabelIsLoading &&
								<CircularProgress
									sx={{ color: "#FFFFFF", marginLeft: "0.5rem" }} size={15}/>}
							</Button>
						</Grid>
						{state.isVisiblePollReturnLabelButton &&
							<Grid item xs="auto" sx={{ mt: 2 }}>
								<Button
									variant="contained"
									onClick={onClickPollReturnLabel}
								>
									Poll Label {state.pollLabelIsLoading &&
									<CircularProgress
										sx={{ color: "#FFFFFF", marginLeft: "0.5rem" }}
										size={15}/>}
								</Button>
							</Grid>
						}
						{state.isVisibleDownloadReturnLabelButton &&
							<Grid item xs="auto" sx={{ mt: 2 }}>
								<Button
									variant="contained"
									onClick={onClickDownloadReturnLabel}
								>
									Download Label {state.downloadLabelIsLoading &&
									<CircularProgress
										sx={{ color: "#FFFFFF", marginLeft: "0.5rem" }}
										size={15}/>}
								</Button>
							</Grid>
						}
						<Grid item xs/>
					</Grid>
				</Grid>
				<AlertBox
					severity="info"
					message="If a label has been created, but is currently unvailable for download then the button 'Poll Label' will be visible.
					Clicking this will attempt to fetch the download link from the carrier. If after 5 minutes this is still unavailable please contact support@bezos.ai."
				/>
			</>
		);
	};

	const GenerateReturnLabelProductItems = () => {
		if (typeof productsList === "object" && productsList !== null) {
			return (
				<>
					<CheckboxList/>
				</>
			);
		} else {
			return (
				<Typography>No items found</Typography>
			);
		}
	};

	const pollReturnLabel = async (pollerToken) => {
		setState(previousState => {
			return {
				...previousState,
				pollLabelIsLoading: true,
			};
		});

		await context.dataProvider.pollSellerReturnLabel(seller, orderNumber, pollerToken)
			.then(response => {
				if (response.status !== 200 || !response.data.download_token) {
					return setState(previousState => {
						return {
							...previousState,
							returnLabelIsLoading: false,
							pollLabelIsLoading: false,
							isVisiblePollReturnLabelButton: true,
							isVisibleDownloadReturnLabelButton: false,
							showConfirmationAlert: true,
						};
					});
				}

				setState(previousState => {
					return {
						...previousState,
						returnLabelDownloadToken: response.data.download_token,
						isVisibleDownloadReturnLabelButton: true,
						isVisiblePollReturnLabelButton: false,
						pollLabelIsLoading: false,
						returnLabelIsLoading: false,
						showConfirmationAlert: true,
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						returnLabelIsLoading: false,
						pollLabelIsLoading: false,
						isVisiblePollReturnLabelButton: true,
						isVisibleDownloadReturnLabelButton: false,
						showConfirmationAlert: true,
					};
				});
			});
	};

	const onClickGenerateReturnLabel = async () => {
		if (!seller) return;

		setState(previousState => {
			return {
				...previousState,
				returnLabelIsLoading: true,
				createReturnErrorMessage: "",
			};
		});

		const jsonItemQuantities = Object.fromEntries(itemQuantities);

		const pollerToken = await context.dataProvider.createSellerReturnLabel(seller, orderNumber, postcode, returnCourierCode, returnCourierDeliveryOption, jsonItemQuantities)
			.then(response => {
				if (!response.data.poller_token || response.data.poller_token === "undefined") {
					return setState(previousState => {
						return {
							...previousState,
							returnLabelIsLoading: false,
							showConfirmationAlert: true,
							isVisibleGenerateReturnLabelDiv: false,
							returnLabelStatus: false,
						};
					});
				}
				return response.data.poller_token;
			})
			.catch(error => {
				if (error.response.status === 400) {
					setState(previousState => {
						return {
							...previousState,
							createReturnErrorMessage: capitalizeFirstLetter(error.response.data),
						};
					});
				}

				return setState(previousState => {
					return {
						...previousState,
						returnLabelIsLoading: false,
						showConfirmationAlert: true,
						isVisibleGenerateReturnLabelDiv: false,
						returnLabelStatus: false,
					};
				});
			});

		if (!pollerToken) {
			return;
		}

		setState(previousState => {
			return {
				...previousState,
				returnLabelPollerToken: pollerToken,
			};
		});

		await pollReturnLabel(pollerToken);

		setItemQuantities(new Map());
	};

	const onClickPollReturnLabel = async () => {
		if (!seller || state.returnLabelPollerToken === "" || !state.returnLabelPollerToken) return;

		setState(previousState => {
			return {
				...previousState,
				pollLabelIsLoading: true,
			};
		});

		await pollReturnLabel(state.returnLabelPollerToken);
	};

	const onClickDownloadReturnLabel = async () => {
		if (!seller || state.returnLabelDownloadToken === "" || !state.returnLabelDownloadToken) return;

		setState(previousState => {
			return {
				...previousState,
				downloadLabelIsLoading: true,
			};
		});

		await context.dataProvider.downloadSellerReturnLabel(seller, orderNumber, state.returnLabelDownloadToken)
			.then(response => {
				if (response.status !== 200) {
					return setState(previousState => {
						return {
							...previousState,
							downloadLabelIsLoading: false,
							showConfirmationAlert: true,
							isVisibleDownloadReturnLabelButton: true,
							returnLabelStatus: false,
						};
					});
				}

				fileDownload(response.data, orderNumber + "_Return_Label.pdf");

				setState(previousState => {
					return {
						...previousState,
						downloadLabelIsLoading: false,
						showConfirmationAlert: true,
						returnLabelStatus: true,
						isVisibleGenerateReturnLabelDiv: false,
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						downloadLabelIsLoading: false,
						showConfirmationAlert: true,
						returnLabelStatus: false,
					};
				});
			});

	};

	const returnCourierDetails = useMemo(() => {
		if (returnCourierID && Array.isArray(courierServices.data)) {
			return courierServices.data.filter(courier => courier.id === Number(returnCourierID));
		}
		return [];
	}, [returnCourierID, courierServices.data]);

	const ExternalLinks = ({ href, children }) => {
		return (
			<a href={href} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	};

	const components = {
		a: ExternalLinks,
	};

	const CourierSpecificInformation = () => {

		const description = returnCourierDetails[0]?.description || "";
		const parcelCharacteristics = returnCourierDetails[0]?.parcel_characteristics || "";
		const warning = returnCourierDetails[0]?.warning || "";

		return (
			<Box className="courier-description">
				{description.length > 0 && 
					<Grid item xs={12} sx={{ mb:1, pl:1, pr:1 }}>
						<Typography component="div" variant="body1">
							<ReactMarkdown components={components}>{description}</ReactMarkdown>
						</Typography>
					</Grid>
				}
				{parcelCharacteristics.length > 0 && 
					<Grid item xs={12} sx={{ mb:1, pl:1, pr:1 }}>
						<Typography component="div" variant="body1" sx={{ fontWeight: "900" }}>
							Characteristics of the parcel:
						</Typography>
						<List>
							{parcelCharacteristics.map((item, index) => (
								<ListItem key={index} sx={{ p:0 }}>
									<ListItemText primary={item} />
								</ListItem>
							))}
						</List>
					</Grid>
				}
				{warning.length > 0 && 
					<Grid item xs={12} sx={{ mb:1, pl:1, pr:1 }} className="warning-message">
						<AlertBox
							severity="warning"
							message={<ReactMarkdown>{warning}</ReactMarkdown>}
						/>
					</Grid>
				}
			</Box>
		);
	};

	const GenerateAReturnLabelConfirmation = () => {
		const handleClose = (event, reason) => {
			if (reason === "clickaway") {
				return;
			}
			setState(previousState => {
				return {
					...previousState,
					showConfirmationAlert: false
				};
			});
		};

		return (
			(state.returnLabelStatus !== null) && <Portal>
				<Snackbar open={state.showConfirmationAlert} autoHideDuration={12000} onClose={handleClose}>
					<Box>
						{(state.isVisiblePollReturnLabelButton ?
							<AlertBox
								severity="info"
								message="Return Label is not yet ready, please click 'Poll Label' to retry."
							/> :
							(!state.isVisibleDownloadReturnLabelButton ?
								(state.returnLabelStatus === true ?
									<AlertBox
										severity="success"
										message="Return Label created successfully"
									/>
									:
									(state.createReturnErrorMessage !== "" ?
										<AlertBox
											severity="error"
											message={"Error creating Return Label: " + state.createReturnErrorMessage}
										/>
										:
										<AlertBox
											severity="error"
											message="Error creating Return Label, please try again. If this problem
											continues then contact support@bezos.ai"
										/>))
								:
								(state.returnLabelStatus === true ?
									<AlertBox
										severity="success"
										message="Return Label downloaded successfully"
									/>
									:
									<AlertBox
										severity="error"
										message="Error downloading Return Label, please wait a moment and try again.
										If this problem continues then contact support@bezos.ai"
									/>))
						)}
					</Box>
				</Snackbar>
			</Portal>
		);
	};

	const onClickDownloadExistingLabel = async (label) => {
		let downloadToken = label.download_token;
		if (label.poller_token !== "" && downloadToken === "") {
			await context.dataProvider.pollSellerReturnLabel(seller, label.order_number, label.poller_token)
				.then(response => {
					if (response.status !== 200) {
						return setState(previousState => {
							return {
								...previousState,
								showDownloadConfirmationAlert: true,
								downloadReturnLabelStatus: false,
							};
						});
					}

					if (response.data.download_token) {
						downloadToken = response.data.download_token;
					}

					return setState(previousState => {
						return {
							...previousState,
							showDownloadConfirmationAlert: true,
							downloadReturnLabelStatus: true,
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							downloadReturnLabelStatus: false,
							showDownloadConfirmationAlert: true,
						};
					});
				});
		}

		if (downloadToken !== "") {
			await context.dataProvider.downloadSellerReturnLabel(seller, label.order_number, downloadToken)
				.then(response => {
					if (response.status !== 200 || response.data.size < 1000) {
						return setState(previousState => {
							return {
								...previousState,
								showDownloadConfirmationAlert: true,
								downloadReturnLabelStatus: false,
							};
						});
					}

					fileDownload(response.data, label.order_number + "_Return_Label.pdf");

					setState(previousState => {
						return {
							...previousState,
							showDownloadConfirmationAlert: true,
							downloadReturnLabelStatus: true,
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							showDownloadConfirmationAlert: true,
							downloadReturnLabelStatus: false,
						};
					});
				});
		}
	};

	const ReturnLabelsTableData = () => {
		if (existingReturnLabels.isLoading) {
			return (
				<LoadingStatusTable message="Loading ..." colSpan={10}/>
			);
		}

		if (!existingReturnLabels.status) return;

		if (typeof existingReturnLabels.results === "object" && existingReturnLabels.results.length > 0) {
			return (
				(existingReturnLabels.results.sort((b, a) => a.created_at < b.created_at ? -1 : 1).map((label, index) =>
					<TableRow key={index}>
						<TableCell className="tc-wrap">{getDisplayDate(label.created_at)}</TableCell>
						<TableCell className="tc-wrap">{(label.number_of_labels * label.rate_per_label).toFixed(2) + " " + warehouseCurrency}</TableCell>
						<TableCell className="tc-wrap">{capitalizeFirstLetterOfEachWord(label.status)}</TableCell>
						<TableCell className="tc-wrap">
							<Link
								href={label.tracking_url}
								target="_blank"
								style={{ textDecoration: "none" }}
							>
								{label.tracking_number}
							</Link>
						</TableCell>
						<TableCell className="tc-wrap">
							<Button
								variant="contained"
								sx={{ mr: 1, mb: 1 }}
								disabled={label.poller_token === "" && label.download_token === ""}
								color="info"
								onClick={() => onClickDownloadExistingLabel(label)}
							>
								Download
							</Button>
						</TableCell>
					</TableRow>
				))
			);
		}
	};

	const ReturnLabelsTable = () => {
		return (
			<>
				<Typography component="h2" variant="h6" mb={2} mt={2}>
					Previously Created
				</Typography>
				<TableContainer component={Paper} style={{ maxHeight: 300 }}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align="left">Created Date</TableCell>
								<TableCell align="left">Cost</TableCell>
								<TableCell align="left">Status</TableCell>
								<TableCell align="left">Tracking Number</TableCell>
								<TableCell align="left"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								existingReturnLabels.isLoading ?
									<LoadingStatusTable colSpan={7} message={"Loading..."}/>
									:
									<ReturnLabelsTableData/>
							}
						</TableBody>
					</Table>
				</TableContainer>
				<DownloadReturnLabelConfirmation/>
			</>
		);
	};

	const DownloadReturnLabelConfirmation = () => {
		const handleClose = (event, reason) => {
			if (reason === "clickaway") {
				return;
			}
			setState(previousState => {
				return {
					...previousState,
					showDownloadConfirmationAlert: false
				};
			});
		};

		return (
			(state.downloadReturnLabelStatus !== null) && <Portal>
				<Snackbar open={state.showDownloadConfirmationAlert} autoHideDuration={12000} onClose={handleClose}>
					<Box>
						{(state.downloadReturnLabelStatus === true ?
							<AlertBox
								severity="success"
								message="Return Label downloaded successfully"
							/>
							:
							<AlertBox
								severity="error"
								message="The label download link has expired, please contact support@bezos.ai to obtain a copy"
							/>)}
					</Box>
				</Snackbar>
			</Portal>
		);
	};

	const GenerateAReturnLabelHiddenDiv = () => {
		return (
			<Box mt={0} mb={4}>
				<Formik
					enableReinitialize
					validateOnChange={true}
					validateOnBlur={true}
					initialValues={{}}
					onSubmit={() => {
					}}>
					<Form noValidate autoComplete="off">
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<Box mb={0}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Grid item xs="auto">
											<Typography component="h2" variant="h6">
												Generate a Return Label
											</Typography>
										</Grid>
										<Grid item xs/>
									</Grid>
									<Grid item xs={12}>
										{
											courierServices.isLoading ?
												(
													<Typography sx={{ m: 1 }}>
														<CircularProgress size={15} sx={{ mr: 0.5 }}/>Loading...
													</Typography>
												)
												:
												courierServices.data.length > 0 && courierServices.data !== null ?
													(
														<FormControl sx={{ minWidth: 250 }}>
															<DropDown
																id="courier_service"
																name="courier_service"
																label="Courier Service"
																onChange={handleCourierServiceChange}
																value={returnCourierCode + ", " + returnCourierID + ", " + returnCourierDeliveryOption}
																data={courierServices.data.map((c) => ({
																	key: c.id,
																	value: c.carrier_code + ", " + c.id + ", " + c.delivery_option,
																	name: c.name + " - " + c.price.toFixed(2) + " " + warehouseCurrency,
																}))}
																required={true}
															/>
														</FormControl>
													)
													:
													<AlertBox
														severity="error"
														message="No available return courier services found. Please contact support@bezos.ai for a return label."
													/>
										}
									</Grid>
									<CourierSpecificInformation/>
									<Grid item xs={12} mt={1}>
										<Typography component="h6" sx={{ fontWeight: 900 }}>
											Returned Items:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<GenerateReturnLabelProductItems/>
									</Grid>
									{
										existingReturnLabels.results.length > 0 &&
										<Grid item xs={12} mt={1}>
											<ReturnLabelsTable/>
										</Grid>
									}
								</Grid>
							</Box>
						</Paper>
					</Form>
				</Formik>
			</Box>
		);
	};

	return (
		<>
			{state.isVisibleGenerateReturnLabelDiv && <GenerateAReturnLabelHiddenDiv/>}
			<GenerateAReturnLabelConfirmation/>
		</>
	);

}
