import React, { useState } from "react";
import {
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	IconButton,
	Paper,
} from "@mui/material";
import DetailIcon from "@mui/icons-material/AddBoxOutlined";
import { getDisplayTime } from "../../../utils/date.js";
import { LoadingStatusTable } from "../../Shared/LoadingStatus/LoadingStatus.jsx";
import ColumnVisibility from "../../Shared/ColumnVisibility/ColumnVisibility.jsx";
import Modal from "../../Shared/Modals/Modal";
import BasicTable from "../../Shared/Table/BasicTable.jsx";

export default function AuditLogsTable({ tableData, isLoading }) {

	const [state, setState] = useState({
		isModalOpen: false,
	});

	const [isModalVisible, setIsModalVisible] = useState(false);
	const localStorageName = "audit_logs_column_visibility";

	const getInitialColumnVisibility = () => {
		const savedState = localStorage.getItem(localStorageName);
		if (savedState) {
			return JSON.parse(savedState);
		}
		return {
			eventTime: true,
			userName: true,
			item: true,
			operation: true,
			detail: true,
		};
	}; 

	const [columnsVisibility, setColumnsVisibility] = useState(getInitialColumnVisibility());
	const handleVisibilityChange = (newColumnsVisibility) => {
		setColumnsVisibility(newColumnsVisibility);
		setIsModalVisible(true);
		localStorage.setItem(localStorageName, JSON.stringify(newColumnsVisibility));
	};

	const getDetailModal = (values) => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Changes",
				modalBodyMessage1Data: <BasicTable data={values} />,
				modalButtonVariant1: "outlined",
				modalButtonText1: "Close",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				isModalOpen: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	const TableData = () => {
		if (isLoading) {
			return (
				<LoadingStatusTable message="Loading ..." colSpan={10}/>
			);
		}

		if (typeof tableData === "object" && tableData.length > 0) {
			return (
				tableData.map((item, index) => {
					return (
						<TableRow key={index}>
							<TableCell>
								{getDisplayTime(item.event_time)}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.userName ? "" : "none" }}>
								{item.username || "-"}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.item ? "" : "none" }}>
								{item.item}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.operation ? "" : "none" }}>
								{item.operation}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.detail ? "" : "none" }}>
								<IconButton 
									aria-label="click for details"
									onClick={() => getDetailModal(item.values)}
								>
									<DetailIcon/>
								</IconButton>
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					);
				})
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan={10} align="center">
						<Typography>No data found</Typography>
					</TableCell>
				</TableRow>
			);
		}
	};

	return (
		<>
			<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								Time/Date
							</TableCell>
							<TableCell style={{ display: columnsVisibility.userName ? "" : "none" }}>
								User
							</TableCell>
							<TableCell style={{ display: columnsVisibility.item ? "" : "none" }}>
								Item
							</TableCell>
							<TableCell style={{ display: columnsVisibility.operation ? "" : "none" }}>
								Operation
							</TableCell>
							<TableCell style={{ display: columnsVisibility.detail ? "" : "none" }}>
								Detail
							</TableCell>
							<TableCell align="right">
								<ColumnVisibility
									columnsDisabled={["eventTime"]}
									columnsVisibility={columnsVisibility}
									onVisibilityChange={handleVisibilityChange}
									isModalVisible={isModalVisible}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableData/>
					</TableBody>
				</Table>
			</TableContainer>
			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
			/>
		</>
	);

}
