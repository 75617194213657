import React, {
	useState,
	useCallback,
	useEffect
} from "react";
import "./StatusBar.scss";
import { styled } from "@mui/material/styles";
import {
	Toolbar,
	Button,
	Link,
	Box,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Typography
} from "@mui/material";
import FeedBackIcon from "@mui/icons-material/RateReview";
import LogoutIcon from "@mui/icons-material/Logout";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import UseFetchDataDD from "./SellerDropDown";
import AlertBar from "../AlertBar/AlertBar";
import { useDispatch } from "react-redux";
import { selectedSellerRDX } from "../../../store/rootSlice";
import { DataContext } from "../../../App";
import { ThemeToggle } from "../StatusBar/ThemeToggle";
import { Logo } from "./logo";
import { useNavigate } from "react-router-dom";

export default function StatusBar({
	onHandleDrawer, isDrawerVisible, drawerWidth, onSignOut,
	marginTop, userdata, bannerdata, check, change, isLeftMenuVisible
}) {

	const handleDrawerOpen = () => {
		onHandleDrawer(true);
	};

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== "open",
	})(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const dispatch = useDispatch();
	const userIsSeller = userdata.roles !== undefined && userdata.roles.find(r => r === "seller");
	const context = React.useContext(DataContext);
	const sellers = UseFetchDataDD(useCallback(() => context.dataProvider.getSellers(), [context.dataProvider]));
	const navigate = useNavigate();
	const selectedSeller = JSON.parse(localStorage.getItem("SelectedSeller"));

	useEffect(() => {
		const checkSeller = () => {

			if (!selectedSeller && userIsSeller) {
				const seller = sellers.results[0];
				dispatch(selectedSellerRDX(seller));
				localStorage.setItem("SelectedSeller", JSON.stringify(seller));
				setState(previousState => {
					return {
						...previousState,
						seller: seller,
					};
				});
				return;
			}

			if (!selectedSeller && !userIsSeller) {
				dispatch(selectedSellerRDX({}));
				localStorage.removeItem("SelectedSeller");
				setState(previousState => {
					return {
						...previousState,
						seller: {},
					};
				});
				return navigate("/");
			}

			return;
		};
		checkSeller();
	}, [selectedSeller]);

	const [state, setState] = useState({
		seller: selectedSeller || {}, // added `|| {}`, else will give error that dropdown value is null, if seller has not been selected	
	});

	const handleChange = (e) => {
		const value = e.target.value;
		const selectedSeller = sellers.results.find(seller => seller.id === value);
		setState(previousState => {
			return {
				...previousState,
				seller: selectedSeller,
			};
		});
		dispatch(selectedSellerRDX(selectedSeller));
		localStorage.setItem("SelectedSeller", JSON.stringify(selectedSeller));
	};

	// Top Link Feedback Form Button
	const topLinks = {
		feedbackButton: {
			activeStatus: userIsSeller, // change to false to hide, true to show button
			url: process.env.REACT_APP_CANNY_FEEDBACK,
			text: "Give Feedback"
		}
	};

	const handleSignOut = () => {
		onSignOut();
		localStorage.removeItem("SelectedSeller");
	};

	return (
		<Box className="AppBar">

			<AppBar
				position="fixed"
				open={isDrawerVisible}
				sx={{ marginTop: marginTop }}
				color="appbar"
				enableColorOnDark
			>
				<AlertBar bannerdata={bannerdata} />

				<Toolbar>

					{isLeftMenuVisible &&
						<IconButton
							color="primary"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{
								marginRight: 2,
								...(isDrawerVisible && { display: "none" }),
							}}
							size="large">
							<MenuIcon />
						</IconButton>
					}

					<Box sx={{ width: "100%" }}>
						<Logo />
					</Box>

					<ThemeToggle
						check={check} 
						change={change} 
					/>

					{(sellers.results.length > 1) ?
						(<FormControl sx={{ minWidth: 120, maxWidth: 120 }} size="small">
							<InputLabel id="seller-sellect-dd">Seller</InputLabel>
							<Select
								labelid="seller-sellect-dd"
								id="seller-sellect"
								value={state.seller.id}
								onChange={handleChange}
							>
								{sellers.results.map((seller) =>
									<MenuItem
										key={seller.id}
										value={seller.id}
									>
										{seller.code}:{seller.id} - {seller.name}
									</MenuItem>
								)}
							</Select>
						</FormControl>)
						: null}

					{(topLinks.feedbackButton.activeStatus) &&
						<Box>
							<Link
								underline="none"
								color="inherit"
								href={topLinks.feedbackButton.url}
								target="_blank"
								rel="noopener"
								noWrap={true}
								sx={{ color: "#FFFFFF" }}
								title={topLinks.feedbackButton.text}
							>
								<FeedBackIcon className="display-min-width" fontSize="large" sx={{ marginTop: "0.5rem" }} />
								<Typography className="display-max-width">{topLinks.feedbackButton.text.toUpperCase()}</Typography>
							</Link>
						</Box>
					}

					<Box ml={3}>
						<Button
							color="primary"
							variant="outlined"
							onClick={handleSignOut}
							sx={{ whiteSpace: "nowrap", backgroundColor: "transparent"}}
							title="Sign Out"
						>
							<LogoutIcon className="display-min-width" fontSize="medium" />
							<Typography className="display-max-width">Sign Out</Typography>
						</Button>
					</Box>

				</Toolbar>

			</AppBar>
		</Box>
	);

}
