import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	FormControlLabel,
	FormGroup,
	Switch,
	Modal,
	Box,
	Typography,
	Button,
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import IconButton from "@mui/material/IconButton";
import LightTooltip from "../LightTooltip";

export default function ColumnVisibility({ 
	columnsDisabled,
	columnsVisibility, 
	onVisibilityChange,
	isModalVisible
}) {

	const [open, setOpen] = useState(isModalVisible || false);

	const handleClose = () => {
		setOpen(false);
	};

	const onChangeColumn = (event) => {
		const newColumnsVisibility = {
			...columnsVisibility,
			[event.target.name]: event.target.checked,
		};
		onVisibilityChange(newColumnsVisibility);
	};
	
	const showAllColumns = () => {
		const newColumnsVisibility = Object.fromEntries(
			Object.entries(columnsVisibility).map(([key]) => [key, true])
		);
		onVisibilityChange(newColumnsVisibility);
	};

	const ColumnSettingsModal = () => (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="column-settings-modal"
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "background.paper",
					boxShadow: 24,
					borderRadius: 2,
					p: 4,
					minWidth: 300,
				}}
			>
				<Typography id="column-settings-modal" variant="h6" component="h2">
                    Column Settings
				</Typography>
				<Typography id="column-settings-modal" variant="p" component="p" mt={1} mb={2}>
                    Click a column name to toggle its visibility
				</Typography>
				<FormGroup>
					{Object.entries(columnsVisibility).map(([key, visible]) => (
						key !== "button" &&
					<FormControlLabel 
						disabled={Array.isArray(columnsDisabled) && columnsDisabled.includes(key)}
						key={key}
						control={
							<Switch
								checked={visible}
								onChange={onChangeColumn}
								name={key}
								color="primary"
							/>
						} 
						label={key}
					/>
					))}
				</FormGroup>
				<Button
					variant="outlined"
					onClick={showAllColumns}
					sx={{ mt: 2 }}
				>
                    Show All
				</Button>
			</Box>
		</Modal>
	);

	const ColumnSettingsButton = () => (
		<LightTooltip
			title={"Click to toggle column visibility"}
			disableHoverListener={false}
			arrow
		>
			<IconButton
				color="inherit"
				aria-label="column settings"
				onClick={() => setOpen(true)}
				sx={{ p: 0, m: 0 }}
			>
				<ViewColumnIcon fontSize="large" sx={{mr: 2}} />
			</IconButton>
		</LightTooltip>
	);

	return (
		<>
			<ColumnSettingsButton />
			<ColumnSettingsModal />
		</>
	);
}

ColumnVisibility.propTypes = {
	columnsVisibility: PropTypes.object.isRequired,
	onVisibilityChange: PropTypes.func.isRequired,
};