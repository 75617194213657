import React, {
	useState,
	useEffect,
	useCallback
} from "react";
import {
	Link,
	useParams
} from "react-router-dom";
import UseFetchData from "../Shared/FetchData/FetchData";
import ShippingDetails from "./views/ShippingDetails";
import {
	Button,
	Grid,
	Typography,
	Box,
	Paper
} from "@mui/material";
import "./GoodsInCreate.scss";
import AddProducts from "../Shared/AddProducts";
import PackagingConfiguration from "./views/PackagingConfiguration";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import Modal from "../Shared/Modals/Modal";
import { DataContext } from "../../App";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";

export default function GoodsInCreate({ seller, userdata }) {
	const asn = useParams().id;

	const context = React.useContext(DataContext);

	const { status, results, isLoading } = UseFetchData(useCallback(() => context.dataProvider.getASN(asn), [context.dataProvider, asn]));
	const countries = UseFetchData(useCallback(() => context.dataProvider.getSellerCountries(seller), [context.dataProvider, seller]));

	const sellerID = seller.toString();

	function getDeliveryTypeLabel(deliveryType) {
		let deliveryTypeLabel = deliveryType;

		if (deliveryType === "TwentyFtContainer") {
			deliveryTypeLabel = "20ft Container";
		} else if (deliveryType === "FortyFtContainer") {
			deliveryTypeLabel = "40ft Container";
		} else if (deliveryType === "FortyFtContainerHC") {
			deliveryTypeLabel = "40ft HC Container";
		}

		return deliveryTypeLabel;
	}

	const page_data = {
		asn: {
			seller: results.seller_code,
			status: `${results.status}`,
			platform_id: results.id,
		},
		shipping: {
			warehouse: results.warehouse_code,
			estimated_delivery: results.estimated_delivery_date,
			delivery_type: getDeliveryTypeLabel(results.delivery_type),
			delivery_quantity: results.delivery_quantity,
			carrier: results.carrier,
			country_of_origin_code: results.country_of_origin,
			tracking_number: results.tracking_number,
			seller_po_reference: results.seller_po_reference,
		},
		product: {
			items: results.items
		},
		packaging: {
			asn_id: results.id,
			single_sku_cartons: results.single_sku_quantity,
			mixed_sku_cartons: results.mixed_sku_quantity,
			comments: results.user_comments,
		},
	};

	const [state, setState] = useState({
		warehouse_id: "",
		shippingDetailsForm: {},
		addProductsForm: {},
		isASNDraft: false,
		pageTitle: "Create ASN",
		pageSubTitle: "",
		initialCart: null,
		panelsVisible: {
			isShippingDetailsPanelVisible: true,
			isAddProductsPanelVisible: false,
			isAdditionalDetailsPanelVisible: false,
		},
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	useEffect(() => {
		const checkIsASNDraft = () => {
			if (asn !== undefined && status === true) {
				setState(previousState => {
					return {
						...previousState,
						isASNDraft: true,
						pageTitle: "Edit ASN",
						pageSubTitle: `: ${page_data.asn.seller}${page_data.packaging.asn_id}`,
					};
				});
			}
		};
		checkIsASNDraft();
	}, [asn, page_data.packaging.asn_id, page_data.asn.seller, status]);

	useEffect(() => {

		const updateItems = () => {

			if (page_data.product.items === undefined) {
				return "";
			}

			const items = page_data.product.items;
			const modifiedItems = items.map(function (obj) {

				obj["name"] = obj["sku_name"];
				delete obj["sku_name"];

				obj["quantity"] = obj["units_expected"];
				delete obj["units_expected"];

				delete obj["units_received"];

				return obj;
			});

			setState(previousState => {
				return {
					...previousState,
					initialCart: modifiedItems,
				};
			});

		};
		updateItems();

	}, [page_data.product.items]);

	const onPanelsVisibleChange = (props) => {
		setState(previousState => {
			return {
				...previousState,
				panelsVisible: props,
			};
		});
	};

	const onWarehouseChange = (id) => {
		setState(previousState => {
			return {
				...previousState,
				warehouse_id: id,
			};
		});
	};

	const onShippingDetailsFormSubmit = (props) => {
		setState(previousState => {
			return {
				...previousState,
				shippingDetailsForm: props,
			};
		});
	};

	const onAddProductsFormSubmit = (props) => {
		setState(previousState => {
			return {
				...previousState,
				addProductsForm: props,
			};
		});
	};

	const BackButton = () => {
		return (
			<Button variant="contained" component={Link} to="/asn-summary">
				Go Back
			</Button>
		);
	};

	const onClickDeleteASNDraft = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Delete ASN draft",
				modalBodyMessage1: "Are you sure you'd like to delete this ASN draft? This action cannot be undone.",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Delete ASN draft",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickDeleteASNDraftModalConfirmation,
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onClickDeleteASNDraftModalConfirmation = async () => {

		// close confirm modal
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "", // ensures 2nd button does not render in modal
			};
		});

		if (!seller) return;

		await context.dataProvider.deleteASN(page_data.asn.platform_id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN draft successfully deleted",
						modalBodyMessage1: "This ASN draft has been deleted",
						modalBodyMessage2: "Note: It can take a few minutes for these changes to update in your ASN summary.",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/asn-summary",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN draft deletion was unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try deleting the ASN draft again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Draft",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});

	};

	const DeleteASNDraftButton = () => {
		return (
			<>
				<Button
					variant="contained"
					onClick={onClickDeleteASNDraft}
					color="destructive"
				>
					Delete ASN Draft
				</Button>
			</>
		);
	};

	const TitleHeading = () => {
		return (
			<Box mb={0}>
				<Grid container spacing={1}>
					<Grid item xs={12} container spacing={2} mt={0} mb={2}>
						<Grid item xs="auto">
							<Typography component="h1" variant="h5">
								{state.pageTitle}{state.pageSubTitle}
							</Typography>
						</Grid>
						{state.isASNDraft && <>
							<Grid item xs="auto">
								<StatusBox status={page_data.asn.status} statusType="asn" />
							</Grid>
							<Grid item xs />
							<Grid item xs="auto">
								<DeleteASNDraftButton />
							</Grid>
						</>
						}
					</Grid>
				</Grid>
			</Box>
		);
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	return (
		<LoadableContent isLoading={isLoading}>
			<Box id="goods-in-create">
				<Box>
					{sellerID.length > 0 ?
						<>
							<BreadCrumb
								breadcrumbs={[
									{ label: "Goods In", url: "/asn-summary" },
									{ label: state?.pageTitle }
								]}
							/>
							<TitleHeading />
							<Box>
								<Grid container spacing={1}>

									<Box className={`panel${state.panelsVisible.isShippingDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
										<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
											<Typography component="h3" variant="h6">1. Add Shipping Details</Typography>
											<Box className={`panel${state.panelsVisible.isShippingDetailsPanelVisible === true ? "-show" : "-hide"}`}>
												<ShippingDetails
													sellerID={seller}
													onWarehouseChange={onWarehouseChange}
													onShippingDetailsFormSubmit={onShippingDetailsFormSubmit}
													shippingFormData={state.isASNDraft ? page_data.shipping : state.shippingDetailsForm}
													asnFormData={page_data.asn}
													countries={countries}
													onPanelsVisibleChange={onPanelsVisibleChange}
													isASNDraft={state.isASNDraft}
												/>
											</Box>
										</Paper>
									</Box>

									<Box className={`panel${state.panelsVisible.isAddProductsPanelVisible === true ? "-active" : "-disabled"}`}>
										<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
											<Typography component="h3" variant="h6">2. Add Products</Typography>
											<Box
												className={`panel${state.panelsVisible.isAddProductsPanelVisible === true ? "-show" : "-hide"}`}>
												{state.warehouse_id &&
													<AddProducts
														sellerID={seller}
														cartName="Goods In"
														isStockAvailableColumnVisible={false}
														warehouseID={state.warehouse_id}
														onAddProductsFormSubmit={onAddProductsFormSubmit}
														initialCart={state.initialCart}
														onPanelsVisibleChange={onPanelsVisibleChange}
													/>
												}
											</Box>
											<Modal
												onModalClose={onModalClose}
												isModalOpen={state.isModalOpen}
												modalBodyTitle={state.modalBodyTitle}
												modalBodyMessage1={state.modalBodyMessage1}
												modalBodyMessage1Data={state.modalBodyMessage1Data}
												modalBodyMessage2={state.modalBodyMessage2}
												modalBodyInputFields={state.modalBodyInputFields}
												modalButtonVariant1={state.modalButtonVariant1}
												modalButtonText1={state.modalButtonText1}
												modalButtonActionType1={state.modalButtonActionType1}
												modalButtonAction1={state.modalButtonAction1}
												modalButtonVariant2={state.modalButtonVariant2}
												modalButtonText2={state.modalButtonText2}
												modalButtonActionType2={state.modalButtonActionType2}
												modalButtonAction2={state.modalButtonAction2}
												modalButtonColor2={state.modalButtonColor2}
											/>
										</Paper>
									</Box>

									<Box className={`panel${state.panelsVisible.isAdditionalDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
										<Paper sx={{ p: 2, width: "100%", marginY: 1 }} elevation={2}>
											<Typography component="h3" variant="h6">3. Packaging Configuration</Typography>
											<Box className={`panel${state.panelsVisible.isAdditionalDetailsPanelVisible === true ? "-show" : "-hide"}`}>
												<PackagingConfiguration
													sellerID={seller}
													shippingDetailsForm={state.shippingDetailsForm}
													addProductsForm={state.addProductsForm}
													isASNDraft={state.isASNDraft}
													packagingFormData={page_data.packaging}
													countries={countries}
													onPanelsVisibleChange={onPanelsVisibleChange}
													userdata={userdata}
												/>
											</Box>
										</Paper>
									</Box>

								</Grid>
							</Box>
						</>
						:
						<>
							<Typography mb={2}>
								Invalid Seller ID
							</Typography>
							<BackButton />
						</>
					}
				</Box>
			</Box>
		</LoadableContent>
	);

}