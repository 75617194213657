import React, {
	useState,
	useEffect
} from "react";
import SellerDependentView from "../Shared/SellerDependentView";
import {
	Box,
	Grid,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Button,
	Paper
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../Shared/Modals/Modal";
import { DataContext } from "../../App";
import { createDownloadLink } from "../../utils/downloadFile";
import {
	DownloadButton,
	DeleteButton
} from "../Shared/Buttons/Buttons";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";

export default function InvoiceView({ seller, reload }) {

	const context = React.useContext(DataContext);
	const [state, setState] = useState({
		isLoaded: false,
		sellerFiles: [],
		reloadTable: false,
		isModalOpen: false,
	});

	useEffect(() => {

		if (!seller) return;


		const getSellerFileList = () => {

			setState(previousState => {
				return {
					...previousState,
					isLoaded: false
				};
			});

			context.dataProvider.getSellerInvoices(seller)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							isLoaded: true,
							sellerFiles: res.data,
						};
					});
				});
		};
		getSellerFileList();
	}, [seller, reload, state.reloadTable, context.dataProvider]);

	const refreshSellerFiles = () => {
		setState(previousState => {
			return {
				...previousState,
				reloadTable: !state.reloadTable,
			};
		});
	};

	const onClickDeleteFile = (id) => {

		if (id === "") return;

		context.dataProvider.deleteSellerInvoice(seller, id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: false,
						reloadTable: !state.reloadTable,
					};
				});
			}
			);
	};

	const onClickDeleteInvoiceFileModal = (id, filename) => {

		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Delete File?",
				modalBodyInputComponent: null,
				modalShowFileUpload: false,
				modalFileUploadType: "",
				modalBodyMessage1: "Are you sure you want to delete the file " + filename + "?",
				modalBodyMessage1Data: [],
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Delete File",
				modalButtonActionType2: "function",
				modalButtonAction2: () => onClickDeleteFile(id),
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	const TitleHeading = () => {
		return (
			<>
				<Typography component="h1" variant="h5" mb={2}>
					Invoices <RecordsTotal total={state.sellerFiles.length} />
				</Typography>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => refreshSellerFiles()}
					className="reload-button"
					title="Reload Table"
					sx={{ mb:2 }}
				>
					Reload <RefreshIcon />
				</Button>
			</>
		);
	};

	const TableData = () => {
		if (state.sellerFiles.length > 0) {
			return (
				state.sellerFiles.sort((a, b) => a.billing_period > b.billing_period ? -1 : 1)).map((f) =>
				<TableRow key={f.id}>
					<TableCell>
						{f.billing_period}
					</TableCell>
					<TableCell>
						{f.filename}
					</TableCell>
					<TableCell>
						<DownloadButton
							onClick={() => createDownloadLink(f.filename, context.dataProvider.getSellerInvoice(seller, f.id))}
							disabled={false}
							icon={true}
						/>
					</TableCell>
					<TableCell align="right">
						<DeleteButton
							onClick={() => onClickDeleteInvoiceFileModal(f.id, f.filename)}
							icon={true}
						/>
					</TableCell>
				</TableRow>
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan={4} align="center">
						No invoices found
					</TableCell>
				</TableRow>
			);
		}
	};

	const TableMain = () => {
		return (
			<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
				<Table
					aria-label="invoices table"
					id="invoices-table"
					stickyHeader
				>
					<TableHead>
						<TableRow>
							<TableCell align="left">Billing Period</TableCell>
							<TableCell align="left">Filename</TableCell>
							<TableCell align="right" colSpan={2}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(!state.isLoaded) ?
							<TableRow>
								<TableCell colSpan={4}>
									<Box sx={{ width: "100%", display: "grid", justifyContent: "center", mt: 3, mb: 5 }}>
										<Typography mb={4}>Loading ...</Typography>
										<CircularProgress size={50} />
									</Box>
								</TableCell>
							</TableRow>
							:
							<TableData />
						}
					</TableBody>

				</Table>
			</TableContainer>
		);
	};

	return (
		<Box id="invoice-view">
			<SellerDependentView seller={seller}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<TitleHeading />
							<TableMain />
						</Paper>
						<Modal
							onModalClose={onModalClose}
							isModalOpen={state.isModalOpen}
							modalBodyTitle={state.modalBodyTitle}
							modalShowFileUpload={state.modalShowFileUpload}
							modalFileUploadType={state.modalFileUploadType}
							modalBodyMessage1={state.modalBodyMessage1}
							modalBodyMessage1Data={state.modalBodyMessage1Data}
							modalBodyMessage2={state.modalBodyMessage2}
							modalBodyInputFields={state.modalBodyInputFields}
							modalBodyInputComponent={state.modalBodyInputComponent}
							modalButtonVariant1={state.modalButtonVariant1}
							modalButtonText1={state.modalButtonText1}
							modalButtonActionType1={state.modalButtonActionType1}
							modalButtonAction1={state.modalButtonAction1}
							modalButtonVariant2={state.modalButtonVariant2}
							modalButtonText2={state.modalButtonText2}
							modalButtonActionType2={state.modalButtonActionType2}
							modalButtonAction2={state.modalButtonAction2}
							modalButtonColor2={state.modalButtonColor2}
						/>
					</Grid>
				</Grid>
			</SellerDependentView>
		</Box>
	);

}