import React, { useState } from "react";
import {
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Paper,
} from "@mui/material";
import { getDisplayTime } from "../../../utils/date.js";
import { LoadingStatusTable } from "../../Shared/LoadingStatus/LoadingStatus";
import ColumnVisibility from "../../Shared/ColumnVisibility/ColumnVisibility";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";

export default function ClaimsTable({ tableData, isLoading }) {

	const [isModalVisible, setIsModalVisible] = useState(false);
	const localStorageName = "claims_column_visibility";

	const getInitialColumnVisibility = () => {
		const savedState = localStorage.getItem(localStorageName);
		if (savedState) {
			return JSON.parse(savedState);
		}
		return {
			orderNumber: true,
			createdDate: true,
			status: true,
			comment: true,
			amountCredited: true,
			lastUpdated: true,
		};
	}; 

	const [columnsVisibility, setColumnsVisibility] = useState(getInitialColumnVisibility());
	const handleVisibilityChange = (newColumnsVisibility) => {
		setColumnsVisibility(newColumnsVisibility);
		setIsModalVisible(true);
		localStorage.setItem(localStorageName, JSON.stringify(newColumnsVisibility));
	};

	const TableData = () => {
		if (isLoading) {
			return (
				<LoadingStatusTable message="Loading ..." colSpan={10}/>
			);
		}

		if (typeof tableData === "object" && tableData.length > 0) {
			return (
				tableData.map((item, index) => {
					return (
						<TableRow key={index}>
							<TableCell>{item.order_number}</TableCell>
							<TableCell style={{ display: columnsVisibility.createdDate ? "" : "none" }}>
								{getDisplayTime(item.created_at)}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.status ? "" : "none" }}>
								<StatusBox 
									status={item.status} 
									statusType="claims"
								/>
							</TableCell>
							<TableCell style={{ display: columnsVisibility.comment ? "" : "none" }}>
								{item.comment || "-"}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.amountCredited ? "" : "none" }}>
								£{item.amount_credited}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.lastUpdated ? "" : "none" }}>
								{getDisplayTime(item.last_updated_at)}
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					);
				})
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan={10} align="center">
						<Typography>No data found</Typography>
					</TableCell>
				</TableRow>
			);
		}
	};

	return (
		<>
			<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								Order Number
							</TableCell>
							<TableCell style={{ display: columnsVisibility.createdDate ? "" : "none" }}>
								Created Date
							</TableCell>
							<TableCell style={{ display: columnsVisibility.status ? "" : "none" }}>
								Status
							</TableCell>
							<TableCell style={{ display: columnsVisibility.comment ? "" : "none" }}>
								Comments
							</TableCell>
							<TableCell style={{ display: columnsVisibility.amountCredited ? "" : "none" }}>
								Amount Credited
							</TableCell>
							<TableCell style={{ display: columnsVisibility.lastUpdated ? "" : "none" }}>
								Last Updated
							</TableCell>
							<TableCell align="right">
								<ColumnVisibility
									columnsDisabled={["orderNumber"]}
									columnsVisibility={columnsVisibility}
									onVisibilityChange={handleVisibilityChange}
									isModalVisible={isModalVisible}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableData/>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);

}
