import React from "react";
import PropTypes from "prop-types";
import {
	useField,
	useFormikContext
} from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";

export const DatePickerField = ({ name, ...otherProps }) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);

	const configDatePickerField = {
		...field,
		...otherProps,
		inputFormat: "dd/MM/yyyy"
	};

	if (meta && meta.touched && meta.error) {
		configDatePickerField.error = true;
		configDatePickerField.helperText = meta.error;
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				{...configDatePickerField}
				onChange={val => {
					setFieldValue(field.name, val);
				}}
				renderInput={(params) =>
					<TextField
						fullWidth={true}
						variant="outlined"
						margin="normal"
						size="small"
						autoFocus={false}
						sx={otherProps.sx}
						{...params}
					/>
				}
			/>
		</LocalizationProvider>
	);
};

DatePickerField.propTypes = {
	name: PropTypes.string.isRequired
};

export default DatePickerField;
