import React from "react";
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	Box,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../../utils/text";

export const RadioGroupFormik = ({
	label,
	name,
	options,
	labelPlacement,
	defaultValue,
	onChange,
	props,
}) => {

	return (
		<FormControl component="fieldset">
			<FormLabel component="legend">{label}</FormLabel>

			<RadioGroup row aria-label="position"
				name={name}
				onChange={(event) => {onChange(event); props.handleChange(event);}}
				onBlur={props.handleBlur}
				defaultValue={defaultValue}
				required
			>
				{options.map((option, i) =>
					<FormControlLabel
						key={i}
						value={option}
						label={capitalizeFirstLetter(option)}
						control={<Radio color="primary" />}
						labelPlacement={labelPlacement}
					/>
				)}
			</RadioGroup>

			{props.touched[name] &&
				<Box component="span" className="error-alert">{props.errors[name]}</Box>
			}

		</FormControl>

	);
};
