import React from "react";
import {
	Box,
	Grid,
	Typography,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	ThemeProvider,
	StyledEngineProvider,	
} from "@mui/material";
import { 
	LightTheme, 
	DarkTheme 
} from "../../utils/baseTheme";
import CssBaseline from "@mui/material/CssBaseline";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import StatusBarBasic from "../Shared/StatusBar/StatusBarBasic";

export default function ErrorPage({ errorType }) {

	const isDarkMode = JSON.parse(localStorage.getItem("Theme"));

	const HomepageLink = () => {
		return (
			<>
				Click <Link href="/" target="_self" rel="noreferrer">here</Link> to return to the homepage.
			</>
		);
	};
	
	const TitleHeading = () => {
		return (
			<>
				<Typography component="h1" variant="h5" mb={2}>
					Error - Page not found
				</Typography>
				<Grid item xs />
				<Typography variant="body1">
					The page you are looking for does not exist. <HomepageLink/>
				</Typography>
			</>
		);
	};

	const TitleHeadingError = () => {
		return (
			<>
				<Typography component="h1" variant="h5" mb={2}>
					An Error Occurred!
				</Typography>
				<Grid item xs />
				<Typography variant="body1" mb={2}>
					An error occurred while trying to load the page.
				</Typography>
				<Typography variant="body1">
					<HomepageLink/>
				</Typography>				
			</>
		);
	};

	const UsefulLinks = () => {
		return (
			<>
				<Typography component="h2" variant="h6" mt={5}>
					Useful links
				</Typography>
				<List>
					<ListItem>
						<ListItemIcon>
							<KeyboardArrowRightRoundedIcon color="primary"/>
						</ListItemIcon>
						<ListItemButton component="a" href={process.env.REACT_APP_HELP_CENTRE} target="_blank" rel="noreferrer">
							<ListItemText
								primary="Help Centre" color="primary"
								secondary="Check out our Help Centre for useful articles."
							/>
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<KeyboardArrowRightRoundedIcon color="primary"/>
						</ListItemIcon>
						<ListItemButton component="a" href={process.env.REACT_APP_SUBMIT_TICKET} target="_blank" rel="noreferrer">
							<ListItemText
								primary="Submit a ticket"
								secondary="For further issues please submit a ticket."
							/>
						</ListItemButton>
					</ListItem>					
				</List>
			</>
		);
	};

	return (
		<Box id="error-page">

			<Grid container spacing={2}>
				
				<Grid item xs={12}>
					<Box sx={{ p: 2 }} elevation={2}>

						{errorType === "boundary" ?
							<>
								<StyledEngineProvider injectFirst>
									<ThemeProvider theme={isDarkMode ? DarkTheme : LightTheme }>
										<Box sx={{ mt: 10 }}>
											<CssBaseline/>					
											<StatusBarBasic/>
											<TitleHeadingError/>
										</Box>
									</ThemeProvider>
								</StyledEngineProvider>
							</>
							:
							<>
								<TitleHeading/>
								<UsefulLinks/>
							</>
						}

					</Box>
				</Grid>

			</Grid>
		</Box>
	);
}