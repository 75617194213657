import React, {
	useState,
	useEffect
} from "react";
import {
	Box,
	Button
} from "@mui/material";
import {
	Formik,
	Form
} from "formik";
import { TextfieldFormik } from "../../../Shared/TextField/TextField";
import * as Yup from "yup";
import { DataContext } from "../../../../App";
import Modal from "../../../Shared/Modals/Modal";
import { UploadFile } from "../../../Shared/UploadFiles/UploadFile";
import { capitalizeFirstLetter } from "../../../../utils/text";
import { 
	updateExistingObject,
	renameKey,
} from "../../../../utils/object";

export default function AdditionalDetails({
	sellerID,
	shippingDetailsForm,
	addProductsForm,
	existingOrder,
	existingOrderInvoiceDocuments,
	existingOrderShippingDocuments,
	isDomestic,
	onPanelsVisibleChange,
	onOrderError,
	orderOperation,
}) {

	const context = React.useContext(DataContext);
	const INITIAL_FORM_STATE = {
		packing_notes: existingOrder?.additional_information?.packing_notes || "",
		delivery_notes: existingOrder?.additional_information?.delivery_notes || "",
		gift_message: existingOrder?.additional_information?.gift_messages || "",
		shipping_total_ex_vat: existingOrder?.additional_information?.shipping_total || 0,
		shipping_total_vat: existingOrder?.additional_information?.shipping_total_vat || 0,
		total_vat: existingOrder?.additional_information?.total_vat || 0,
		discount_total_ex_vat: existingOrder?.additional_information?.discount_total || 0,
		discount_total_vat: existingOrder?.additional_information?.discount_total_vat || 0,
	};

	const FORM_VALIDATION = Yup.object().shape({
		// ready for order upload validation
	});

	const [state, setState] = useState({
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		modalIsLoading: false,
		results: {},
		isLoading: false,
		documentsShipping: [],
		documentsInvoice: [],
		documents: [],
		documentsStatus: null,
		orderStatus: null,
		submitButton: "Submit",
		isFormSubmitted: false,
		isPageInEditMode: true,
		orderError: {
			orderNumber: "",
			errorMessage: "",
		},
	});

	useEffect(() => {

		if (orderOperation) {

			const name = orderOperation === "edit" ? "Update" : "Copy";
			setState(previousState => {
				return {
					...previousState,
					submitButton: `${name} Order`,
				};
			});

			if (orderOperation === "edit") {
				setState(previousState => {
					return {
						...previousState,
						documentsShipping: existingOrderShippingDocuments,
						documentsInvoice: existingOrderInvoiceDocuments,
						documents: [...existingOrderShippingDocuments, ...existingOrderInvoiceDocuments].filter(item => typeof item === "object" && item !== null && !(item instanceof Array))
					};
				});
			}

		}
	}, [orderOperation]);

	const onShippingUpdate = (newDocument) => {
		setState(previousState => {
			return {
				...previousState,
				documentsShipping: [...previousState.documentsShipping, newDocument],
				documents: [...previousState.documents, newDocument]
			};
		});
	};

	const onInvoiceUpdate = (newDocument) => {
		setState(previousState => {
			return {
				...previousState,
				documentsInvoice: [...previousState.documentsInvoice, newDocument],
				documents: [...previousState.documents, newDocument]
			};
		});
	};

	const onDeleteDocument = (id, type_id) => {

		if (type_id === "" || id === "") {
			return "";
		}

		if (type_id === 2) {
			setState(previousState => {
				return {
					...previousState,
					documentsShipping: state.documentsShipping.filter(item => item.id !== id),
					documents: state.documents.filter(item => item.id !== id)
				};
			});
		}

		if (type_id === 1 || type_id === 3) {
			setState(previousState => {
				return {
					...previousState,
					documentsInvoice: state.documentsInvoice.filter(item => item.id !== id),
					documents: state.documents.filter(item => item.id !== id)
				};
			});
		}
	};

	const createOrder = async (order) => {

		if (!sellerID) return;

		try {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
					modalIsLoading: true,
					isFormSubmitted: true,
					orderStatus: null,
					orderError: {}
				};
			});

			const response = await context.dataProvider.createOrder(sellerID, order);

			setState(previousState => {
				return {
					...previousState,
					results: response.data.order_number,
					orderStatus: true,
				};
			});

			(state.documents.length > 0) && (await uploadDocuments(response.data.order_id));

		} catch (error) {

			if (error.response.status === 400 && 
				error.response.data.message && 
				order.order_number 
			) {
				setState(previousState => {
					return {
						...previousState,
						orderError: {
							orderNumber: order.order_number,
							errorMessage: error.response.data.message,
						},
					};
				});
			}

			setState(previousState => {
				return {
					...previousState,
					orderStatus: false,
				};
			});
		}

		setState(previousState => {
			return {
				...previousState,
				isLoading: false,
				modalIsLoading: false,
			};
		});
	};

	const uploadDocuments = async (orderID) => {

		try {

			await context.dataProvider.createOrderDocuments(sellerID, orderID, state.documents);

			setState(previousState => {
				return {
					...previousState,
					documentsStatus: true,
				};
			});
		} catch (error) {
			setState(previousState => {
				return {
					...previousState,
					documentsStatus: false,
				};
			});
		}
	};

	const updateOrder = async (order) => {
		if (!sellerID) return;
		if (existingOrder.courier_service === "None Selected" && existingOrder.status === "onbackorder") existingOrder.courier_service = "";

		try {
			setState(previousState => {
				return {
					...previousState,
					isFormSubmitted: true,
					modalIsLoading: true,
				};
			});

			await context.dataProvider.updateOrder(sellerID, order);

			setState(previousState => {
				return {
					...previousState,
					orderStatus: true,
				};
			});

			await updateDocuments();
		} catch (error) {
			setState(previousState => {
				return {
					...previousState,
					orderStatus: false,
				};
			});
		}

		setState(previousState => {
			return {
				...previousState,
				modalIsLoading: false,
			};
		});

	};

	const updateDocuments = async () => {
		try {
			await context.dataProvider.updateOrderDocuments(sellerID, existingOrder.id, state.documents);

			setState(previousState => {
				return {
					...previousState,
					documentsStatus: true,
				};
			});
		} catch (error) {
			setState(previousState => {
				return {
					...previousState,
					documentsStatus: false,
				};
			});
		}
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isShippingDetailsPanelVisible: false,
			isAddProductsPanelVisible: true,
			isAdditionalDetailsPanelVisible: false,
		});
	};

	const onSubmitForm = (values) => {
		values.order_items = addProductsForm;
		const order = Object.assign(shippingDetailsForm, values);
		order.is_copy_of = orderOperation === "copy" ? existingOrder?.order_number : "";

		if (orderOperation === "edit") {

			let updatedOrder = existingOrder;
			updatedOrder = renameKey(updatedOrder, "address1", "address_line_1");
			updatedOrder = renameKey(updatedOrder, "address2", "address_line_2");
			updatedOrder = renameKey(updatedOrder, "address3", "address_line_3");
			updatedOrder = renameKey(updatedOrder, "warehouse", "warehouse_name");
			updatedOrder = renameKey(updatedOrder, "items", "order_items");
			updatedOrder = renameKey(updatedOrder, "platform_order_id", "id");
			updatedOrder = updateExistingObject(updatedOrder, order);

			return updateOrder(updatedOrder);
		} else {
			return createOrder(order);
		}
	
	};

	const initialModalState = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "",
				modalBodyMessage1: "",
				modalBodyMessage2: "",				
				modalButtonVariant1: "outlined",
				modalButtonText1: "Close",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",
				modalButtonAction2: "",
				modalButtonColor2: state.modalButtonColor2,
			};
		});
	};

	useEffect(() => {

		const modalOrderCreate = () => {

			if (state.isFormSubmitted === false) return;

			initialModalState();

			if (state.orderStatus === true) {

				if (state.documentsStatus === false) {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order creation failed",
							modalBodyMessage1: `Order ${state.results} created, but document upload failed.`,
							modalBodyMessage2: "Please contact support@bezos.ai",
							isFormSubmitted: false,
						};
					});
				}

				if (state.documentsStatus === true || state.documentsStatus === null) {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order successfully created",
							modalBodyMessage1: `You have successfully created order number: ${state.results}`,
							modalBodyMessage2: "Note: it may take a few minutes for this order to appear in the order summary",
							modalButtonText2: "Return to Order Summary",
							modalButtonActionType2: "link",
							modalButtonAction2: "/order-status",
						};
					});
				}

			}

			if (state.orderStatus === false) {

				if (state.orderError.errorMessage !=="") {
					onOrderError(state.orderError);
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order creation failed - invalid order number",
							modalBodyMessage1: `${capitalizeFirstLetter(state.orderError.errorMessage)}, please amend and re-submit.`,
							modalBodyMessage2: " If the problem continues email support@bezos.ai",
						};
					});
				}

				if (state.orderError.errorMessage ==="") {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order creation failed",
							modalBodyMessage1: "Please try creating the order again.",
							modalBodyMessage2: "If the problem continues email support@bezos.ai",
						};
					});
				}

			}

			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
				};
			});

		};

		if (orderOperation !== "edit" || orderOperation !== "copy") return modalOrderCreate();

	}, [orderOperation, state.isFormSubmitted, state.orderStatus, state.documentsStatus, state.results, state.errorMessage]);

	useEffect(() => {

		const modalOrderUpdate = () => {

			if (state.isFormSubmitted === false) return;

			initialModalState();

			if (state.orderStatus === true) {

				if (state.documentsStatus === false) {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order update failed",
							modalBodyMessage1: `Order ${existingOrder.order_number} updated, but document(s) update failed.`,
							modalBodyMessage2: "Please contact support@bezos.ai",
							isFormSubmitted: false,
						};
					});
				}

				if (state.documentsStatus === true || state.documentsStatus === null) {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order updated successfully",
							modalBodyMessage1: `You have successfully updated order number: ${existingOrder.order_number}`,
							modalBodyMessage2: "Note: it may take a few minutes for this order to update.",
							modalButtonText2: "Return to Order Summary",
							modalButtonActionType2: "link",
							modalButtonAction2: "/order-status",
						};
					});
				}

			}

			if (state.orderStatus === false) {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order update failed",
						modalBodyMessage1: "Please try updating the order again.",
						modalBodyMessage2: "If the problem continues email support@bezos.ai",
					};
				});
			}

			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
				};
			});

		};

		if (orderOperation === "edit") return modalOrderUpdate();

	}, [orderOperation, state.isFormSubmitted, state.orderStatus, state.documentsStatus]);

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				<Form
					noValidate
					autoComplete="off"
				>

					{orderOperation !== "edit" &&
					<>
						<TextfieldFormik
							name="packing_notes"
							label="Packing notes"
							multiline
							rows={3}
						/>

						<TextfieldFormik
							name="delivery_notes"
							label="Delivery notes"
							multiline
							rows={3}
						/>

						<TextfieldFormik
							name="gift_message"
							label="Gift messages"
							multiline
							rows={3}
						/>

						{!isDomestic &&
						<>
							<TextfieldFormik
								name="shipping_total_ex_vat"
								label="Shipping Total (Ex VAT)"
								type="number"
							/>
								
							<TextfieldFormik
								name="shipping_total_vat"
								label="Shipping Total VAT"
								type="number"
							/>
								
							<TextfieldFormik
								name="total_vat"
								label="Total VAT"
								type="number"
							/>
								
							<TextfieldFormik
								name="discount_total_ex_vat"
								label="Discount Total (Ex VAT)"
								type="number"
							/>
								
							<TextfieldFormik
								name="discount_total_vat"
								label="Discount Total VAT"
								type="number"
							/>
						</>
						}

					</>
					}

					<Box mt={2} mb={2}>
						<UploadFile
							name="Shipping Label"
							type="shipping"
							fileTypes=".pdf"
							col2Title="Number of labels"
							tableData={state.documentsShipping}
							onShippingUpdate={onShippingUpdate}
							onDeleteDocument={onDeleteDocument}
							isPageInEditMode={state.isPageInEditMode}
							pageType={"edit"}
						/>
					</Box>

					<Box mt={2} mb={2}>
						<UploadFile
							name="Invoice"
							type="invoice"
							fileTypes=".pdf"
							col2Title="Commercial Invoice"
							tableData={state.documentsInvoice}
							onInvoiceUpdate={onInvoiceUpdate}
							onDeleteDocument={onDeleteDocument}
							isPageInEditMode={state.isPageInEditMode}
							pageType={"edit"}
						/>
					</Box>

					<Modal
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalBodyMessage1Data={state.modalBodyMessage1Data}
						modalBodyMessage2={state.modalBodyMessage2}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
						modalIsLoading={state.modalIsLoading}
					/>

					<Box className="align-content-right">
						<Button
							variant="outlined"
							color="secondary"
							className="back-button"
							onClick={() => handleBackButton()}
						>
							Back
						</Button>
						<Button
							type="submit"
							variant="contained"
							disabled={state.isFormSubmitted}
						>
							{state.isFormSubmitted ? "Submitted" : state.submitButton}
						</Button>
					</Box>

				</Form>

			</Formik>
		</>
	);
}