import React, {
	useState,
	useEffect
} from "react";
import "./DebugView.scss";
import { Auth } from "aws-amplify";
import SellerDependentView from "../Shared/SellerDependentView";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Tooltip
} from "@mui/material";

export default function Debug({ seller, userdata }) {

	const [state, setState] = useState({
		token: "",
		refreshToken: false,
		isLoading: false
	});

	useEffect(() => {
		Auth.currentSession()
			.then(data => {
				setState(previousState => {
					return {
						...previousState,
						token: data.accessToken.jwtToken,
						isLoading: false,
					};
				});
			});
	}, [state.refreshToken]);

	const token = state.token;

	const onClickCopyToken = async () => {
		setState(previousState => {
			return {
				...previousState,
				isLoading: true,
				refreshToken: !previousState.refreshToken
			};
		});
		await navigator.clipboard.writeText(token);
	};

	const PageHeading = () => {
		return (
			<Grid item xs={12}>
				<Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
					Access Token
				</Typography>
			</Grid>
		);
	};

	const PageBody = () => {
		return (
			<>
				<Grid item xs={12}>
					<LoadableContent isLoading={state.isLoading}>
						<Tooltip
							title="Click to copy"
							placement="top"
						>
							<Box
								className="debug-token"
								onClick={() => onClickCopyToken()}
							>
								{token}
							</Box>
						</Tooltip>
					</LoadableContent>
				</Grid>
			</>
		);
	};

	return (
		<Box id="debug">
			<PermissionDependentView userdata={userdata} permission="view_debug_page">
				<SellerDependentView seller={seller}>
					<Grid container>
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<Grid item xs={12}>
								<PageHeading />
								<PageBody />
							</Grid>
						</Paper>
					</Grid>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);
}