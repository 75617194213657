export const jsonToCSV = (data) => {
	let csv = "";
	let headers = Object.keys(data[0]);

	csv += headers.join(",") + "\n";

	data.forEach(function (row) {
		let data = headers.map(header => JSON.stringify(row[header])).join(",");
		csv += data + "\n";
	});

	return csv;
};

export const getCSVFileFromJSONArray = async (data, filename) => {
	const csv = jsonToCSV(data);

	let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	let url = URL.createObjectURL(blob);

	const link = document.createElement("a");
	link.href = url;
	link.download = filename;
	link.style.visibility = "hidden";

	document.body.appendChild(link);
	link.click();
	setTimeout(() => document.body.removeChild(link), 100);
};
