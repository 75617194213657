import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import React from "react";
import { ViewButtonSingle } from "../ViewButton/ViewButton";
import ProductModal from "../Modals/Product";
import { isPropertyEmpty } from "../../../utils/object";

export default function ItemDisplay({ item, itemType, permissions }) {

	const getAllocations = (allocations) => {

		if (!allocations || allocations.length === 0) return null;
		if (isPropertyEmpty(allocations, "batch_number")) return;

		return <TableRow>
			<TableCell colSpan="2"></TableCell>
			<TableCell colSpan="3">
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="left">Batch Number</TableCell>
							{(itemType === "asn") ?
								<TableCell align="left">Quantity Received</TableCell>
								:
								<TableCell align="left">Quantity Allocated</TableCell>
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{allocations.map(a =>
							<TableRow key={a.batch_number}>
								<TableCell align="left">{a.batch_number}</TableCell>
								<TableCell align="left">{a.quantity}</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableCell>
		</TableRow>;
	};

	if (itemType === "asn") {
		return <>
			<TableRow key={item.sku}>
				<TableCell align="left">{item.sku}</TableCell>
				<TableCell align="left">{item.sku_name}</TableCell>
				<TableCell align="left">{item.units_expected}</TableCell>
				<TableCell align="left">{item.units_received}</TableCell>
			</TableRow>
			{getAllocations(item.allocations)}
		</>;
	} else if (itemType === "order") {
		return <>
			<TableRow key={item.order_item_id}>
				<TableCell align="left">{item.sku}</TableCell>
				<TableCell align="left">{item.bundle_id}</TableCell>
				<TableCell align="left">{item.name}</TableCell>
				<TableCell align="left">{item.quantity}</TableCell>
				<TableCell align="left">{item.allocated}</TableCell>
				{item.product_id &&
					<TableCell align="right">
						<ProductModal 
							id={item.product_id}
						/>
					</TableCell>
				}
				{(!permissions || permissions.has("inventory-detail")) && 
					<TableCell align="right">
						<ViewButtonSingle
							buttonTitle={"View Product Details"}
							buttonLink={"/inventory-detail/"}
							buttonProps={item.product_id}
						/>
					</TableCell>
				}
			</TableRow>
			{getAllocations(item.allocations)}
		</>;
	} else {
		return null;
	}
}