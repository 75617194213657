import { createSlice } from "@reduxjs/toolkit";

// Slice
const SelectedSeller = JSON.parse(localStorage.getItem("SelectedSeller"));
const rootSlice = createSlice({

	name: "root",

	initialState: {
		SelectedSellerRDX: SelectedSeller || {},
		isAlertBarVisible: false
	},

	reducers: {
		selectedSellerRDX: (state, action) => {
			state.SelectedSellerRDX = action.payload;
		},
		setIsAlertBarVisible: (state, action) => {
			state.isAlertBarVisible = action.payload;
		}
	}

});

// Actions
export const {
	selectedSellerRDX,
	setIsAlertBarVisible
} = rootSlice.actions;

export const reducer = rootSlice.reducer;
