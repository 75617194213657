import React, {
	useEffect,
	useState
} from "react";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Button,
	Pagination,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getPageValue } from "../../../utils/pagination.js";
import * as tableConstants from "../../Shared/Config/TableConfig.js";
import { getReportTypeNameFromID } from "../../../utils/reports.js";
import { getDisplayTime } from "../../../utils/date.js";
import { DataContext } from "../../../App.js";
import PageRowsDropDown from "../../Shared/PageRows/PageRows.jsx";
import { RecordsTotal } from "../../Shared/ResultsFound/ResultsFound.jsx";
import { getValueByID } from "../../../utils/object.js";
import { LoadingStatusTable } from "../../Shared/LoadingStatus/LoadingStatus.jsx";
import Modal from "../../Shared/Modals/Modal";
import { DeleteButton } from "../../Shared/Buttons/Buttons";

export default function ScheduledReportsTable({ 
	seller, 
	updateTable,
	reportsFrequency,
	daysOfTheWeek,
	datesOfTheMonth
}) {

	const context = React.useContext(DataContext);
	const pageRowOptions = tableConstants.PAGINATION_OPTIONS;
	const [state, setState] = useState({
		isLoaded: false,
		resultsData: [],
		resultsTotalCount: 0,
		reloadTable: false,
		paginationCurrentPage: 1,
		paginationPostsPerPage: pageRowOptions[0],
		paginationPages: 0,
		isModalOpen: false
	});

	useEffect(() => {
		if (!seller) return;

		const getResultsData = async () => {
			await context.dataProvider.getScheduledReports(seller, state.paginationCurrentPage, state.paginationPostsPerPage)
				.then(response => {
					setState(previousState => {
						return {
							...previousState,
							resultsData: response.data.sort((a, b) => a.last_generated > b.last_generated ? -1 : 1),
							resultsTotalCount: response.data.length,
							isLoaded: true,
						};
					});
				});
		};
		getResultsData();

	}, [seller, state.paginationCurrentPage, state.paginationPostsPerPage, updateTable, state.reloadTable, context.dataProvider]);

	const onClickDeleteScheduledReport = (id) => {
		if (id === "" || id === 0) return;

		context.dataProvider.deleteScheduledReport(seller, id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Scheduled report removed successfully",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
						reloadTable: !state.reloadTable,
						modalBodyInputComponent: null,
						modalShowFileUpload: false,
						modalFileUploadType: "",
						modalBodyMessage1: "",
						modalBodyMessage1Data: [],
						modalBodyMessage2: "",
						modalButtonVariant2: "contained",
						modalButtonText2: "",
						modalButtonActionType2: "",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Error removing scheduled report",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
						modalBodyInputComponent: null,
						modalShowFileUpload: false,
						modalFileUploadType: "",
						modalBodyMessage1Data: [],
						modalButtonVariant2: "contained",
						modalButtonText2: "",
						modalButtonActionType2: "",
						modalButtonColor2: "",
					};
				});
			});
	};

	const onClickDeleteScheduledReportModal = (id) => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Remove scheduled report?",
				modalBodyInputComponent: null,
				modalShowFileUpload: false,
				modalFileUploadType: "",
				modalBodyMessage1: "Are you sure you want to remove this scheduled report?",
				modalBodyMessage1Data: [],
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes",
				modalButtonActionType2: "function",
				modalButtonAction2: () => onClickDeleteScheduledReport(id),
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	const TableRows = () => {
		return (
			<>
				{state.resultsData.map((report, index) => (
					<TableRow key={index}>
						<TableCell>{getReportTypeNameFromID(report.report_type_id)}</TableCell>
						<TableCell>{getValueByID(reportsFrequency, report.frequency_id, "name")}</TableCell>
						<TableCell>{report.recipient}</TableCell>
						<TableCell>Every {report.frequency_id === 2 ? getValueByID(daysOfTheWeek, report.requested_run_day, "name")
							: report.frequency_id === 3 ? getValueByID(datesOfTheMonth, report.requested_run_day, "name") + " of the Month"
								:"Day"}</TableCell>
						<TableCell>{getDisplayTime(report.last_generated, "", "/")}</TableCell>
						<TableCell align="right">
							<DeleteButton
								onClick={() => onClickDeleteScheduledReportModal(report.id)}
								icon={true}
							/>
						</TableCell>
					</TableRow>
				))}
			</>
		);
	};

	let paginationView;
	if (state.resultsTotalCount <= state.paginationPostsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={state.paginationPages}
				page={state.paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							paginationCurrentPage: getPageValue(input, state.paginationCurrentPage),
						};
					});
				}}
			/>
		);
	}

	const onClickReloadTable = () => {
		setState(previousState => {
			return {
				...previousState,
				reloadTable: !state.reloadTable,
				paginationCurrentPage: 1,
			};
		});
	};

	const onPageRowChange = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				paginationPostsPerPage: pageRows,
				paginationCurrentPage: 1,
			};
		});
	};

	return (
		<Box>
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>

				<Typography variant="h5">
					Scheduled Reports <RecordsTotal total={state.resultsTotalCount} />
				</Typography>

				<Button
					variant="outlined"
					color="secondary"
					onClick={() => onClickReloadTable()}
					className="reload-button"
					title="Refresh Table"
					sx={{ mb: 2 }}
				>
					Refresh <RefreshIcon />
				</Button>

				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
					<Table
						aria-label="scheduled reports table"
						id="scheduledReportsTable"
						stickyHeader
					>
						<TableHead>
							<TableRow>
								<TableCell align="left">Report Type</TableCell>
								<TableCell align="left">Frequency</TableCell>
								<TableCell align="left">Recipient</TableCell>
								<TableCell align="left">Requested Run Day</TableCell>
								<TableCell align="left">Last Generated</TableCell>
								<TableCell align="left"/>
							</TableRow>
						</TableHead>
						<TableBody>
							{(state.isLoaded === true) ?
								<TableRows/>
								:
								<LoadingStatusTable colSpan={7} message="Loading.." />
							}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid
					container
					spacing={0}
					padding={2}
					className="table-footer"
				>
					{paginationView}
					<PageRowsDropDown
						resultsTotalCount={state.resultsTotalCount}
						paginationPostsPerPage={state.paginationPostsPerPage}
						pageRowOptions={pageRowOptions}
						onPageRowChange={onPageRowChange}
					/>
				</Grid>
			</Paper>
			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalShowFileUpload={state.modalShowFileUpload}
				modalFileUploadType={state.modalFileUploadType}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalBodyInputFields={state.modalBodyInputFields}
				modalBodyInputComponent={state.modalBodyInputComponent}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
			/>
		</Box>
	);
}
