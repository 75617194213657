import React from "react";
import {
	Box,
	Fab,
	Fade
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { scrollToTop } from "../../../utils/scrollToTop";

export const BackToTop = () => {

	const trigger = useScrollTrigger({
		target: window,
		disableHysteresis: true,
		threshold: 100,
	});

	return (
		<Fade in={trigger}>
			<Box
				role="presentation"
				sx={{
					position: "fixed",
					bottom: 15,
					right: 15,
					zIndex: 100,
				}}
			>
				<Fab
					onClick={() => scrollToTop()}
					color="primary"
					size="small"
					aria-label="scroll back to top"
					title="Scroll back to top"
				>
					<KeyboardArrowUp />
				</Fab>
			</Box>
		</Fade>
	);
};