export const getPageValue = (input, currentPage) => {
	let paginationNextPage;

	// if page number (page numbers will have length greater than 0)
	if (input.currentTarget.textContent.length > 0) {
		paginationNextPage = Number(input.currentTarget.innerText);
		// else if forward / back arrows clicked
	} else if (input.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateNextIcon") {
		paginationNextPage = Number(currentPage) + 1;
	} else if (input.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateBeforeIcon") {
		paginationNextPage = Number(currentPage) - 1;
	} else {
		paginationNextPage = Number(currentPage);
	}

	return paginationNextPage;
};
