import React from "react";
import {
	Select,
	MenuItem,
	InputLabel,
	FormControl
} from "@mui/material";

export default function PageRowsDropDown({
	resultsTotalCount,
	paginationPostsPerPage,
	pageRowOptions,
	onPageRowChange
}) {

	const PageRows = () => {

		if (resultsTotalCount <= paginationPostsPerPage) return;

		return (
			<>
				<FormControl sx={{ ml: 4, maxWidth: 100 }}>
					<InputLabel id="pageRows-select">Rows</InputLabel>
					<Select
						labelid="pageRows-select"
						id="pageRows"
						label="Rows"
						size="small"
						fullWidth
						value={paginationPostsPerPage}
						onChange={(e) => { onPageRowChange(e.target.value); }}
					>
						{pageRowOptions.map((item, i) =>
							item <= resultsTotalCount ?
								<MenuItem
									key={i}
									value={item}
								>
									{item}
								</MenuItem>
								: null
						)}
					</Select>
				</FormControl>
			</>
		);
	};

	return (
		<PageRows />
	);
}
