import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const CustomTabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</Box>
	);
};

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export const a11yProps = (index) => {
	return {
		"id": `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
};
