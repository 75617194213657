import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useField } from "formik";

const TextFieldWrapper = ({ name, ...otherProps }) => {
	const [field, meta] = useField(name);

	const configTextField = {
		...field,
		...otherProps,
		fullWidth: true,
		variant: "outlined",
		margin: "normal",
		size: "small",
		autoFocus: false
	};

	if (meta && meta.touched && meta.error) {
		configTextField.error = true;
		configTextField.helperText = meta.error;
	}

	return (
		<TextField
			{...configTextField}
		/>
	);
};

TextFieldWrapper.propTypes = {
	name: PropTypes.string.isRequired
};

export default TextFieldWrapper;
