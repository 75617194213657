import React from "react";
import {
	Button,
	Box,
	Typography,
	Grid,
	FormControl,
	Paper
} from "@mui/material";
import {
	Form,
	Formik
} from "formik";
import * as Yup from "yup";
import {
	getScheduledReportsFrequency,
	getDaysOfTheWeek,
	getDatesOfTheMonth,
} from "../../../utils/reports.js";
import DropDown from "../../Shared/DropDown";
import { TextfieldFormik } from "../../Shared/TextField/TextField";
import { getReportTypeSelectLabels } from "../../../utils/reports.js";
import ScheduledReportsTable from "./ScheduledReportsTable.jsx";

export const ScheduledReports = ({ 
	reportTypes, 
	seller, 
	updateTable,
	getScheduledReport,
	helpCentre,
}) => {

	const INITIAL_FORM_STATE = {
		report_type: "",
		report_frequency: "",
		report_day_of_week: "",
		report_date_of_month: "",
		report_email: "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		report_type: Yup.number()
			.required("Please select report type."),
		report_frequency: Yup.number()
			.required("Please confirm frequency."),
		report_day_of_week: Yup.number()
			.when("report_frequency", (reportFrequency, schema) => {
				return reportFrequency === 2 ? schema.required("Please confirm day of the week") : schema;
			}),
		report_date_of_month: Yup.number()
			.when("report_frequency", (reportFrequency, schema) => {
				return reportFrequency === 3 ? schema.required("Please confirm date of the month") : schema;
			}),			
		report_email: Yup.string()
			.email("Please enter a valid email address.")
			.required("Please confirm email."),
	});

	const ReportType = ({ props }) => {
		return (
			<>
				<Box>
					<Typography component="p" sx={{ fontWeight: "bold" }}>
                        Which report would you like to see?
					</Typography>
				</Box>
				<Box>
					<FormControl sx={{ minWidth: 250 }}>
						<DropDown
							id="report_type"
							name="report_type"
							label="Select Report Type"
							select={true}
							value={props.values.report_type}
							data={reportTypes.map((d) => ({
								key: d.id,
								value: d.id,
								name: getReportTypeSelectLabels(d.name),
							}))}
							required={true}
						/>
					</FormControl>
				</Box>
			</>
		);
	};

	const ScheduledReportFrequency = ({ props }) => {
		return (
			<>
				<Box mt={2}>
					<Typography component="p" sx={{ fontWeight: "bold" }}>
                        How often would you like to receive the report?
					</Typography>
				</Box>
				<Box>
					<FormControl sx={{ minWidth: 250 }}>
						<DropDown
							id="report_frequency"
							name="report_frequency"
							label="Select Frequency"
							select={true}
							value={props.values.report_frequency}
							data={getScheduledReportsFrequency().map((d) => ({
								key: d.id,
								value: d.id,
								name: d.name,
							}))}
							required={true}
						/>
					</FormControl>
				</Box>
			</>
		);
	};

	const ScheduledReportDayOfWeek = ({ props }) => {
		return (
			<>
				<Box mt={2}>
					<Typography component="p" sx={{ fontWeight: "bold" }}>
                        What day of the week would you like to receive the report?
					</Typography>
				</Box>
				<Box>
					<FormControl sx={{ minWidth: 250 }}>
						<DropDown
							id="report_day_of_week"
							name="report_day_of_week"
							label="Select Day of the Week"
							select={true}
							value={props.values.report_day_of_week}
							data={getDaysOfTheWeek().map((d) => ({
								key: d.id,
								value: d.id,
								name: d.name,
							}))}
							required={true}
						/>
					</FormControl>
				</Box>
			</>
		);
	};

	const ScheduledReportDateOfMonth = ({ props }) => {
		return (
			<>
				<Box mt={2}>
					<Typography component="p" sx={{ fontWeight: "bold" }}>
                        What date of the month would you like to receive the report?
					</Typography>
				</Box>
				<Box>
					<FormControl sx={{ minWidth: 250 }}>
						<DropDown
							id="report_date_of_month"
							name="report_date_of_month"
							label="Select Date of the Month"
							select={true}
							value={props.values.report_date_of_month}
							data={getDatesOfTheMonth().map((d) => ({
								key: d.id,
								value: d.id,
								name: d.name,
							}))}
							required={true}
						/>
					</FormControl>
				</Box>
			</>
		);
	};

	const ReportEmailContact = ({ props }) => {
		return (
			<>
				<Box mt={2}>
					<Typography component="p" sx={{ fontWeight: "bold" }}>
                        Email address to send the report to?
					</Typography>
				</Box>
				<Box>
					<TextfieldFormik
						name="report_email"
						label="Email Recipient"
						value={props.values.report_email}
						required
						sx={{ width: 250 }}
					/>
				</Box>
			</>
		);
	};

	const onSubmitForm = (values) => {
		getScheduledReport(values);
	};

	const ScheduledReportsForm = () => {
		return (
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE,
				}}
				enableReinitialize
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ values, touched, handleBlur, handleChange }) => (
					<Form noValidate autoComplete="off">
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<Box>
								<Grid container display="grid" justifyContent="left">
									
									<ReportType
										props={{ values, touched, handleBlur, handleChange }}
									/>

									{values.report_type !=="" &&
										<ScheduledReportFrequency
											props={{ values, touched, handleBlur, handleChange }}
										/>
									}

									{(values.report_frequency !=="" && parseInt(values.report_frequency) === 2) &&
										<ScheduledReportDayOfWeek
											props={{ values, touched, handleBlur, handleChange }}
										/>
									}
									
									{(values.report_frequency !=="" && parseInt(values.report_frequency) === 3) && 
										<ScheduledReportDateOfMonth
											props={{ values, touched, handleBlur, handleChange }}
										/>
									}

									{(values.report_day_of_week !=="" || values.report_date_of_month !=="" || parseInt(values.report_frequency) === 1) && 
										<ReportEmailContact
											props={{ values, touched, handleBlur, handleChange }}
										/>
									}

									<Grid item mt={2}>
										<Button 
											variant="contained" 
											type="submit"
											disabled={!values.report_email}
										>
											Create Scheduled Report
										</Button>
									</Grid>
									
									{helpCentre}

								</Grid>
							</Box>
						</Paper>
					</Form>
				)}
			</Formik>
		);
	};

	return (
		<>
			<ScheduledReportsForm />
			<ScheduledReportsTable 
				seller={seller} 
				updateTable={updateTable} 
				reportsFrequency={getScheduledReportsFrequency()}
				daysOfTheWeek={getDaysOfTheWeek()}
				datesOfTheMonth={getDatesOfTheMonth()}
			/>
		</>
	);
};
