import React from "react";
import { 
	Link,
	Typography
} from "@mui/material";

export const ExternalLink = ({ href, children }) => {
	
	const isEmail = href && href.includes("@");

	if (isEmail) {
		return (
			<Typography 
				variant="span"
				style={{ textDecoration: "underline" }}
				color="primary"
			>
				{children}
			</Typography>
		);
	}

	return (
		<Link 
			href={href} 
			target="_blank" 
			rel="noreferrer"
		>
			{children}
		</Link>
	);
	
};
