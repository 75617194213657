import {
	useEffect,
	useState
} from "react";
import { useDispatch } from "react-redux";
import { selectedSellerRDX } from "../../../store/rootSlice";

const useFetchDataDD = (getter) => {

	const dispatch = useDispatch();

	const [results, setResults] = useState([]);
	const [status, setStatus] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);

				const response = await getter();

				setResults(response.data);

				if (response.data.length === 1) {
					dispatch(selectedSellerRDX(response.data[0]));
					localStorage.setItem("SelectedSeller", JSON.stringify(response.data[0]));
				}

				setStatus(true);
			} catch (error) {
				setStatus(false);
			}

			setIsLoading(false);
		};

		fetchData();
	}, [getter, dispatch]);

	return {
		status, results, isLoading
	};
};

export default useFetchDataDD;
