import React, {
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	Link as RouterLink,
	useSearchParams
} from "react-router-dom";
import OrderStatusButton from "../OrderStatusButton";
import {
	getCurrentDateTruncated,
	getDisplayTime
} from "../../utils/date.js";
import {
	Box,
	Button,
	Grid,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	MenuItem,
	Checkbox,
	Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SellerDependentView from "../Shared/SellerDependentView";
import ClockedRequest from "../../utils/clockedRequest.js";
import { LoadingStatusTable } from "../Shared/LoadingStatus/LoadingStatus";
import * as tableConstants from "../Shared/Config/TableConfig";
import "./OrderStatus.scss";
import { DataContext } from "../../App";
import ModalShared from "../Shared/Modals/Modal";
import {
	capitalizeFirstLetter,
	capitalizeFirstLetterOfEachWord
} from "../../utils/text";
import PageRowsDropDown from "../Shared/PageRows/PageRows";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { AlertBox } from "../Shared/Alert/Alert";
import ColumnVisibility from "../Shared/ColumnVisibility/ColumnVisibility";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { 
	getCourierStatusLabel,
	getOrderStatusLabel,
} from "../../utils/statusColors";
import SearchFilter from "../Shared/SearchFilter/SearchFilter";
import TuneIcon from "@mui/icons-material/Tune";
import { FilterPanel } from "./components/FilterPanel";
import { LightTooltip } from "../Shared/LightTooltip/LightTooltip";
import CourierTracking from "../Shared/CourierTracking/CourierTracking";

export default function OrderStatus({ seller, userdata }) {

	const context = React.useContext(DataContext);
	const pageRowOptions = tableConstants.PAGINATION_OPTIONS;
	const [searchParams] = useSearchParams();
	const warehouseName = searchParams.get("warehouse_name");
	const warehouseStatus = searchParams.get("warehouse_status");
	const courierStatus = searchParams.get("courier_status");
	const dateRange = searchParams.get("date_range");
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	const initDateRange = 365;
	initStartDate.setDate(initStartDate.getDate() - (dateRange ? dateRange : initDateRange));

	const [state, setState] = useState({
		isLoading: false,
		filter: "",
		startDate: initStartDate,
		endDate: initEndDate,
		resultsTotalCount: 0,
		resultsData: [],
		paginationCurrentPage: 1,
		paginationPostsPerPage: pageRowOptions[0],
		paginationPages: 0,
		allWarehouseNames: [],
		allWarehouseStatuses: [],
		allCourierStatuses: [],
		selectedWarehouseNames: warehouseName ? [warehouseName] : [],
		selectedWarehouseStatuses: warehouseStatus ? [warehouseStatus] : [],
		selectedCourierStatuses: courierStatus ? [courierStatus] : [],
		selectedBulkAction: "",
		selectedBulkActionAPIResponse: {},
		selectedCheckBoxes: [],
		isFilterDisabled: false,
		filterCount: 0,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage1List: "",
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const dataRequest = useMemo(() => new ClockedRequest(), []);
	const totalRequest = useMemo(() => new ClockedRequest(), []);

	const getPageValue = (e) => {
		let paginationNextPage;

		if (e.currentTarget.textContent.length > 0) {
			paginationNextPage = Number(e.currentTarget.innerText);
		} else if (e.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateNextIcon") {
			paginationNextPage = Number(state.paginationCurrentPage) + 1;
		} else if (e.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateBeforeIcon") {
			paginationNextPage = Number(state.paginationCurrentPage) - 1;

		} else {
			paginationNextPage = Number(state.paginationCurrentPage);
		}

		return paginationNextPage;
	};

	useEffect(() => {

		const getWarehouseNames = () => {
			return context.dataProvider.getSellerWarehouses(seller)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							allWarehouseNames: res.data,
						};
					});
				});
		};
		getWarehouseNames();

		const getOrderStatuses = () => {
			return context.dataProvider.getOrderStatuses()
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							allWarehouseStatuses: res.data,
						};
					});
				});
		};
		getOrderStatuses();

		const getCourierStatuses = () => {
			return context.dataProvider.getCourierStatuses()
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							allCourierStatuses: res.data,
						};
					});
				});
		};
		getCourierStatuses();
	}, []);

	useEffect(() => {
		const getSummaryDataTotalOnly = (requestEndDate) => {

			if (!seller) return;

			const params = new URLSearchParams({
				filter: state.filter,
				sellers: seller,
			});

			for (const id of state.selectedWarehouseNames) {
				params.append("warehouse_ids", id);
			}

			for (const status of state.selectedWarehouseStatuses) {
				params.append("status", status);
			}

			for (const status of state.selectedCourierStatuses) {
				params.append("courier_status", status);
			}

			totalRequest.setFetchFn(context.dataProvider.getWIMOSummaryTotal);
			totalRequest.setPathParams({
				startDate: state.startDate.toISOString(),
				endDate: requestEndDate.toISOString(),
			});
			totalRequest.setQueryParams(params);
			totalRequest.fetch()
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							resultsTotalCount: res,
							paginationPages: Math.ceil(Number(res) / Number(state.paginationPostsPerPage)),
						};
					});
				});
		};

		const getSummaryData = () => {

			if (!seller) return;

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			let requestEndDate = new Date(state.endDate);
			requestEndDate.setDate(requestEndDate.getDate() + 1);
			requestEndDate = new Date(requestEndDate - 1);

			getSummaryDataTotalOnly(requestEndDate);

			const params = new URLSearchParams({
				filter: state.filter,
				sellers: seller,
				page: state.paginationCurrentPage,
				results: state.paginationPostsPerPage,
			});

			for (const id of state.selectedWarehouseNames) {
				params.append("warehouse_ids", id);
			}

			for (const status of state.selectedWarehouseStatuses) {
				params.append("status", status);
			}

			for (const status of state.selectedCourierStatuses) {
				params.append("courier_status", status);
			}

			dataRequest.setFetchFn(context.dataProvider.getWIMOSummary);
			dataRequest.setPathParams({
				startDate: state.startDate.toISOString(),
				endDate: requestEndDate.toISOString(),
			});
			dataRequest.setQueryParams(params);

			dataRequest.fetch()
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							resultsData: res,
							isLoading: false,
						};
					});
				});
		};
		getSummaryData();

	}, [seller, dataRequest, totalRequest, state.filter, state.selectedWarehouseNames, state.selectedWarehouseStatuses,
		state.selectedCourierStatuses, state.startDate, state.endDate, state.paginationCurrentPage,
		state.paginationPostsPerPage, context.dataProvider]);
	
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	useEffect(() => {
		setState(previousState => {
			return {
				...previousState,
				filterCount: state.selectedWarehouseStatuses.length + state.selectedCourierStatuses.length,
			};
		});
	}, [state.selectedWarehouseStatuses, state.selectedCourierStatuses]);

	const onChangeCheckBox = (e) => {

		const orderID = Number(e.target.name);
		const isChecked = e.target.checked;
		const isInArray = state.selectedCheckBoxes.includes(orderID);


		if (isChecked === true && isInArray === false) {
			setState(previousState => {
				return {
					...previousState,
					selectedCheckBoxes: [...state.selectedCheckBoxes, orderID],
				};
			});
		}

		if (isChecked === false && isInArray === true) {
			const filterOrders = state.selectedCheckBoxes.filter(orders => {
				return orders !== orderID;
			});

			setState(previousState => {
				return {
					...previousState,
					selectedCheckBoxes: filterOrders,
				};
			});
		}

	};

	const onClickGetWarehouseStatusDetails = (status, comment) => {
		
		if (!status && !comment) return;

		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: `Status: ${capitalizeFirstLetterOfEachWord(getOrderStatusLabel(status))}`,
				modalBodyMessage1: capitalizeFirstLetter(comment) + ".",
				modalBodyMessage2: "For more information click on the help center article button below.",
				modalButtonAction1: process.env.REACT_APP_HELP_CENTRE_WAREHOUSE_STATUSES,
				modalButtonText1: "Help Center Article",
				modalButtonActionType1: "externalLink",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "outlined",
				modalButtonText2: "Close",
				modalButtonActionType2: "function",
				modalButtonAction2: onModalClose,
				modalButtonColor2: "primary",
				isModalOpen: true,
			};
		});

	};

	const onClickGetCourierStatusDetails = (status, comment) => {
		
		if (!status && !comment) return;

		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: `Status: ${capitalizeFirstLetterOfEachWord(getCourierStatusLabel(status))}`,
				modalBodyMessage1: capitalizeFirstLetter(comment) + ".",
				modalBodyMessage2: "For more information click on the help center article button below.",
				modalButtonAction1: process.env.REACT_APP_HELP_CENTRE_COURIER_STATUSES,
				modalButtonText1: "Help Center Article",
				modalButtonActionType1: "externalLink",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "outlined",
				modalButtonText2: "Close",
				modalButtonActionType2: "function",
				modalButtonAction2: onModalClose,
				modalButtonColor2: "primary",
				isModalOpen: true,
			};
		});

		if (status === "exception") {
			setState(previousState => {
				return {
					...previousState,
					modalBodyMessage2: "To identify the delivery issue, check the carrier's tracking information. For further details, please click on the help center article button below."
				};
			});
		}

		if (status === "awaitingscan" || status === "lostnotscanned") {
			setState(previousState => {
				return {
					...previousState,
					modalButtonAction1: process.env.REACT_APP_UNSCANNED_ORDERS,
				};
			});
		}

		if (status === "availableforcustomercollection" || status === "returningtosender") {
			setState(previousState => {
				return {
					...previousState,
					modalBodyMessage2: "",
					modalButtonAction1: "",
					modalButtonText1: "",
					modalButtonActionType1: "",
				};
			});
		}

	};

	const [isModalVisible, setIsModalVisible] = useState(false);
	const localStorageName = "orderstatus_column_visibility";

	const getInitialColumnVisibility = () => {
		const savedState = localStorage.getItem(localStorageName);
		if (savedState) {
			return JSON.parse(savedState);
		}
		return {
			orderNumber: true,
			sellerReference: true,
			orderDate: true,
			customerName: true,
			warehouseName: true,
			warehouseStatus: true,
			courierService: true,
			trackingNumber: true,
			courierStatus: true,
		};
	}; 

	const [columnsVisibility, setColumnsVisibility] = useState(getInitialColumnVisibility());
	const handleVisibilityChange = (newColumnsVisibility) => {
		setColumnsVisibility(newColumnsVisibility);
		setIsModalVisible(true);
		localStorage.setItem(localStorageName, JSON.stringify(newColumnsVisibility));
	};

	const SummaryTableRows = () => {

		if (state.isLoading) {
			return (
				<LoadingStatusTable message="Loading ..." colSpan={12} />
			);
		}

		if (typeof state.resultsData === "object" && state.resultsData.length > 0) {
			return (
				state.resultsData.sort((b, a) => a.bezos_created_time < b.bezos_created_time ? -1 : 1).map((item, index) =>
					<TableRow key={index}>
						{state.selectedBulkAction &&
							<TableCell>
								<Checkbox
									checked={state.selectedCheckBoxes.includes(item.order_id) ? true : false}
									onChange={(e) => onChangeCheckBox(e)}
									inputProps={{ "aria-label": "controlled" }}
									name={item.order_id.toString()}
								/>
							</TableCell>}
						<TableCell className="tc-wrap">{item.order_number}</TableCell>
						{(userdata.permissions && userdata.permissions.has("view_extra_order_details")) &&
							<TableCell className="tc-wrap" >{item.warehouse_reference}</TableCell>
						}
						<TableCell className="tc-wrap" style={{ display: columnsVisibility.sellerReference ? "" : "none" }}>
							{item.external_reference}
						</TableCell>
						<TableCell sx={{ maxWidth: 130 }} style={{ display: columnsVisibility.orderDate ? "" : "none" }}>
							{getDisplayTime(item.bezos_created_time)}
						</TableCell>
						<TableCell sx={{ maxWidth: 100 }} style={{ display: columnsVisibility.customerName ? "" : "none" }}>
							{item.customer_name}
						</TableCell>
						<TableCell sx={{ maxWidth: 100 }} style={{ display: columnsVisibility.warehouseName ? "" : "none" }}>
							{item.warehouse_name}
						</TableCell>
						<TableCell style={{ display: columnsVisibility.warehouseStatus ? "" : "none" }}>
							<Box component="span" sx={{ maxWidth: "130px", display: "flex", alignItems: "center" }}>
								{item.status && 
									<StatusBox 
										status={item.status}
										statusType="order"
									/>
								}
								{item.status_comment && item.status &&
									<InfoTwoToneIcon 
										onClick={() => onClickGetWarehouseStatusDetails(item.status, item.status_comment)} 
										fontSize="small" 
										sx={{ ml: "0.5rem", cursor: "pointer" }}
									/>
								}
							</Box>
						</TableCell>
						<TableCell sx={{ maxWidth: 200, marginRight: 0.25 }} style={{ display: columnsVisibility.courierService ? "" : "none" }}>
							{item.courier_service}
						</TableCell>
						<TableCell className="tc-wrap" style={{ display: columnsVisibility.trackingNumber ? "" : "none" }}>
							<CourierTracking
								onModalOpen={onModalOpen}
								isModalOpen={state.isModalOpen}
								urls={item.tracking_url}
								numbers={item.tracking_number}
								order={item.order_number}
							/>
						</TableCell>
						<TableCell style={{ display: columnsVisibility.courierStatus ? "" : "none" }}>
							<Box component="span" sx={{ maxWidth: "130px", display: "flex", alignItems: "center" }}>
								{item.courier_status && 
									<StatusBox 
										status={item.courier_status} 
										statusType="courier" 
									/>
								}
								{item.courier_comment && item.courier_status &&
									<InfoTwoToneIcon 
										onClick={() => onClickGetCourierStatusDetails(item.courier_status, item.courier_comment)} 
										fontSize="small" 
										sx={{ ml: "0.5rem", cursor: "pointer" }}
									/>
								}
							</Box>
						</TableCell>
						<TableCell align="right">
							<OrderStatusButton
								order={item.order_number}
								orderID={item.order_id} 
								sellerID={seller}
								trackingNumber={item.tracking_number}
								courierService={item.courier_service}
								status={item.courier_status}
								orderTrackingLink={item.tracking_url}
								manual_pod_request={item.manual_pod_request}
								podRequestSent={item.pod_request_sent}
							/>
						</TableCell>
					</TableRow>)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan={12} align="center">
						<Typography>No data found</Typography>
					</TableCell>
				</TableRow>
			);
		}

	};

	let paginationView;
	if (state.resultsTotalCount <= state.paginationPostsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={state.paginationPages}
				page={state.paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							paginationCurrentPage: getPageValue(input, state.paginationCurrentPage),
						};
					});
				}}
			/>
		);
	}

	const onChangeSelectedBulkAction = (e) => {

		const bulkAction = e.target.value;

		if (bulkAction === "cancel-all-unprocessed") {

			const unprocessedStatuses = [
				"new",
				"onbackorder",
				"fraudrisk",
				"awaitingconfirmation",
				"held"
			];

			setState(previousState => {
				return {
					...previousState,
					selectedBulkAction: e.target.value,
					isLoading: true,
					paginationCurrentPage: 1,
					selectedWarehouseStatuses: unprocessedStatuses,
					selectedCourierStatuses: [],
					isFilterDisabled: true,
				};
			});

		}

	};

	const areAllOrdersSelected = () => state.selectedCheckBoxes.length === state.resultsTotalCount;

	const onChangeToggleAllCheckBoxes = (e) => {
		const isChecked = e.target.checked;
		const selectedOrderIDs = isChecked ? state.resultsData.map(o => o.order_id) : [];
		setState(previousState => {
			return {
				...previousState,
				selectedCheckBoxes: selectedOrderIDs
			};
		});

	};

	const onModalClose = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};

	const onModalOpen = (modalData) => {
		setState(previousState => {
			return {
				...previousState,
				...modalData,
				isModalOpen: true,
			};
		});
	};

	const handleStartDate = (date) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: date,
				paginationCurrentPage: 1
			};
		});
	};

	const handleEndDate = (date) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: date,
				paginationCurrentPage: 1,
			};
		});
	};
	
	const handleWarehouseName = (id) => {
		setState((previousState) => {
			return {
				...previousState,
				selectedWarehouseNames: id,
				paginationCurrentPage: 1,
			};
		});
	};

	const handleWarehouse = (status) => {
		setState((previousState) => {
			return {
				...previousState,
				selectedWarehouseStatuses: status,
				paginationCurrentPage: 1,
			};
		});
	};
	
	const handleCourier = (status) => {
		setState((previousState) => {
			return {
				...previousState,
				selectedCourierStatuses: status,
				paginationCurrentPage: 1,
			};
		});
	};

	useEffect(() => {

		const modalConfirmationBulkCancel = () => {

			if (!!Object.values(state.selectedBulkActionAPIResponse).length === false) {
				return;
			}

			const successesCount = Object.values(state.selectedBulkActionAPIResponse.successes).length;
			const errorsCount = Object.values(state.selectedBulkActionAPIResponse.errors).length;

			if (errorsCount > 0) {

				const failedList = Object.values(state.selectedBulkActionAPIResponse.errors).map((s) => capitalizeFirstLetter(s));

				setState(previousState => {
					return {
						...previousState,
						modalBodyMessage1: `Unable to cancel All unprocessed orders: Cancelled: ${successesCount}, Failed: ${errorsCount}.`,
						modalBodyMessage1List: failedList,
					};
				});
			}


			if (successesCount > 0 && errorsCount === 0) {
				setState(previousState => {
					return {
						...previousState,
						modalBodyMessage1: "All unprocessed orders successfully cancelled.",
					};
				});
			}


			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: "Bulk Action: Cancel unprocessed orders",
					modalBodyMessage2: "",
					modalButtonVariant1: "",
					modalButtonText1: "",
					modalButtonActionType1: "",
					modalButtonAction1: "",
					modalBodyMessage1Data: [],
					modalButtonVariant2: "contained",
					modalButtonText2: "Close",
					modalButtonActionType2: "function",
					modalButtonAction2: onClickCancelBulkAction,
					modalButtonColor2: "primary",
					isModalOpen: true,
				};
			});

		};
		modalConfirmationBulkCancel();

	}, [state.selectedBulkActionAPIResponse]);


	const onClickBulkCancelOrder = () => {

		if (!seller || state.selectedCheckBoxes.length === 0) return;
		context.dataProvider.getBulkCancelUnprocessedOrders(seller, state.selectedCheckBoxes)

			.then(response => {
				setState(previousState => {
					return {
						...previousState,
						selectedBulkActionAPIResponse: response.data,
					};
				});
			})
			.catch(error => {
				if (error.response) {
					setState(previousState => {
						return {
							...previousState,
							selectedBulkActionAPIResponse: error.response.data,
						};
					});
				} else {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Bulk Action: Cancel unprocessed orders",
							modalBodyMessage1: "Request failed: Please contact support@bezos.ai",
							modalBodyMessage2: "",
							modalButtonVariant1: "contained",
							modalButtonText1: "Close",
							modalButtonActionType1: "close",
							modalButtonAction1: "",
							modalBodyMessage1Data: [],
							modalButtonVariant2: "",
							modalButtonText2: "",
							modalButtonActionType2: "",
							modalButtonAction2: "",
							modalButtonColor2: "",
							isModalOpen: true,
						};
					});
				}
			});
	};

	const onClickCallModalBulkCancelOrder = () => {
		setState(previousState => {
			return {
				...previousState,
				selectedBulkActionAPIResponse: {},
				modalBodyTitle: "Bulk Action: Cancel unprocessed orders",
				modalBodyMessage1: "Please confirm you would like to cancel the selected orders.",
				modalBodyMessage2: "Note: This action cannot be undone once made.",
				modalButtonVariant1: "contained",
				modalButtonText1: "No",
				modalButtonActionType1: "function",
				modalButtonAction1: onClickCancelBulkAction,
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Cancel unprocessed orders",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickBulkCancelOrder,
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onClickCancelBulkAction = () => {

		setState(previousState => {
			return {
				...previousState,
				isLoading: true,
				paginationCurrentPage: 1,
				selectedBulkAction: "",
				selectedWarehouseStatuses: [],
				selectedCourierStatuses: [],
				isFilterDisabled: false,
				selectedCheckBoxes: [],
				isModalOpen: false,
				modalBodyTitle: "",
				modalBodyMessage1: "",
				modalBodyMessage1Data: [],
				modalBodyMessage1List: "",
				modalBodyMessage2: "",
				modalButtonVariant1: "",
				modalButtonText1: "",
				modalButtonActionType1: "",
				modalButtonAction1: "",
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",
				modalButtonAction2: "",
				modalButtonColor2: "primary",
			};
		});
	};

	const onPageRowChange = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				paginationPostsPerPage: pageRows,
				paginationCurrentPage: 1,
			};
		});
	};

	const handleFilterChange = (value) => {
		setState(previousState => {
			return {
				...previousState,
				filter: value,
				paginationCurrentPage: 1,
			};
		});
	};

	return (
		<Box id="order-status">
			<SellerDependentView seller={seller}>
				<Box>
					{
						<Grid container spacing={1}>

							<Grid item xs={12} container mb={2}>

								{(userdata.permissions && userdata.permissions.has("create_order")) &&
								<Grid item xs="auto" sx={{ mr: 1 }}>
									<Button variant="contained" component={RouterLink} to={"/order-create"}>
										Create an order<AddIcon />
									</Button>
								</Grid>
								}

								{(userdata.permissions && userdata.permissions.has("import_orders")) &&
								<Grid item xs="auto" sx={{ mb: 1 }}>
									<Button variant="outlined" color="secondary" component={RouterLink}
										to={"/order-upload"}>
										Import Orders<AddIcon />
									</Button>
								</Grid>
								}

								<Grid item xs />

							</Grid>
						</Grid>
					}
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>

						<Typography component="h1" variant="h5">
							Orders <RecordsTotal total={state.resultsTotalCount} />
						</Typography>

						<Grid item container className="form-inputs">

							<LightTooltip
								title="Click to filter results"
								arrow
							>
								<Box 
									className="filter-icon" 
									onClick={() => setIsDrawerOpen(!isDrawerOpen)}
									sx={{ cursor: "pointer", mr: 2, mb: 2 }}
								>

									<TuneIcon
										fontSize="medium" 
										sx={{ ml: 1, mr: 0.75 }}
									/>
								
									<Typography
										variant="h6"
										sx={{ mr: 1, fontSize: "1rem" }}
									>
										Filter
									</Typography>
									
									{state.filterCount > 0 &&
										<Typography
											color="primary"
											sx={{ backgroundColor: theme => theme.palette.background.main, p: 0.5, mr: 1 }}
										>
											{state.filterCount}
										</Typography>
									}

								</Box>
							</LightTooltip>

							{(userdata.permissions && userdata.permissions.has("bulk_operation_cancel_orders")) &&
								<Grid item>
									<TextField
										select
										fullWidth
										size="small"
										sx={{ minWidth: "260px", mb: 2 }}
										id="bulk-actions-select"
										inputProps={{
											id: "bulk-actions-select",
											"data-testid": "bulk-actions-select"
										}}
										label="Bulk Actions"
										value={state.selectedBulkAction}
										onChange={(e) => onChangeSelectedBulkAction(e)}
									>
										<MenuItem
											value={"cancel-all-unprocessed"}
										>
											Cancel Unprocessed Orders
										</MenuItem>
									</TextField>
								</Grid>
							}

							<Grid item xs />

							<SearchFilter
								filter={state.filter} 
								onFilterChange={handleFilterChange}
								label={"Search"}
							/>

						</Grid>

						{state.selectedBulkAction &&
							<Grid item container className="form-inputs">

								<Grid item xs={8} xl={12}>
									{state.selectedBulkAction === "cancel-all-unprocessed" &&
										<AlertBox
											severity="info"
											message="Please select the unprocessed orders you would like to cancel below,
											then click the Confirm button to cancel."
											sx={{ mr: 0.5 }}
										/>
									}
								</Grid>

								<Grid item xs={4} xl={12} p={1}>
									<Button
										variant="contained"
										sx={{ mr: 1, mb: 1 }}
										disabled={state.selectedCheckBoxes.length > 0 ? false : true}
										className='button-confirm'
										onClick={onClickCallModalBulkCancelOrder}
									>
										Confirm
									</Button>
									<Button
										variant="contained"
										sx={{ mb: 1 }}
										color="destructive"
										className='button-cancel'
										onClick={onClickCancelBulkAction}
									>
										Cancel
									</Button>
								</Grid>

							</Grid>
						}

						<Grid 
							container 
							style={{ display: "flex", flexDirection: "row" }}
						>
							
							<Box 
								sx={{
									width: isDrawerOpen ? "250px" : "0px",
									overflowX: "hidden",
									transition: "0.1s",
								}}
							>
								<FilterPanel 
									warehouseNameData={state.allWarehouseNames}
									warehouseData={state.allWarehouseStatuses}
									courierData={state.allCourierStatuses}
									handleWarehouseName={handleWarehouseName}
									handleWarehouse={handleWarehouse}
									handleCourier={handleCourier}
									selectedWarehouseNames={state.selectedWarehouseNames}
									selectedWarehouseStatuses={state.selectedWarehouseStatuses}
									selectedCourierStatuses={state.selectedCourierStatuses}
									startDate={state.startDate}
									endDate={state.endDate}
									handleStartDate={handleStartDate}
									handleEndDate={handleEndDate}
									disabled={state.isFilterDisabled}
								/>

							</Box>

							<TableContainer 
								sx={{ 
									maxHeight: 600, 
									overflow: "auto", 
									flex: 1,
								}} 
								component={Paper}
							>								
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											{state.selectedBulkAction &&
												<TableCell>
													<Switch
														checked={areAllOrdersSelected()}
														onChange={(e) => onChangeToggleAllCheckBoxes(e)}
														inputProps={{ "aria-label": "controlled" }}
													/>
												</TableCell>
											}
											<TableCell>Order Number</TableCell>
											{(userdata.permissions && userdata.permissions.has("view_extra_order_details")) &&
												<TableCell>Warehouse Reference</TableCell>
											}
											<TableCell style={{ display: columnsVisibility.sellerReference ? "" : "none" }}>
												Seller Reference
											</TableCell>
											<TableCell style={{ display: columnsVisibility.orderDate ? "" : "none" }}>
												Order Date
											</TableCell>
											<TableCell style={{ display: columnsVisibility.customerName ? "" : "none" }}>
												Customer Name
											</TableCell>
											<TableCell style={{ display: columnsVisibility.warehouseName ? "" : "none" }}>
												Warehouse Name
											</TableCell>
											<TableCell style={{ display: columnsVisibility.warehouseStatus ? "" : "none" }}>
												Warehouse Status
											</TableCell>
											<TableCell style={{ display: columnsVisibility.courierService ? "" : "none" }}>
												Courier Service
											</TableCell>
											<TableCell style={{ display: columnsVisibility.trackingNumber ? "" : "none" }}>
												Tracking Number
											</TableCell>
											<TableCell style={{ display: columnsVisibility.courierStatus ? "" : "none" }}>
												Courier Status
											</TableCell>
											<TableCell align="right">
												<ColumnVisibility
													columnsDisabled={["orderNumber"]}
													columnsVisibility={columnsVisibility}
													onVisibilityChange={handleVisibilityChange}
													isModalVisible={isModalVisible}
												/>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<SummaryTableRows />
									</TableBody>
								</Table>
							</TableContainer>


						</Grid>

						<Grid
							container
							padding={2}
							className="table-footer"
						>
							{paginationView}
							<PageRowsDropDown
								resultsTotalCount={state.resultsTotalCount}
								paginationPostsPerPage={state.paginationPostsPerPage}
								pageRowOptions={pageRowOptions}
								onPageRowChange={onPageRowChange}
							/>
						</Grid>

					</Paper>
				</Box>
				<ModalShared
					onModalClose={onModalClose}
					isModalOpen={state.isModalOpen}
					modalBodyTitle={state.modalBodyTitle}
					modalBodyMessage1={state.modalBodyMessage1}
					modalBodyMessage1Data={state.modalBodyMessage1Data}
					modalBodyMessage1List={state.modalBodyMessage1List}
					modalBodyMessage2={state.modalBodyMessage2}
					modalButtonVariant1={state.modalButtonVariant1}
					modalButtonText1={state.modalButtonText1}
					modalButtonActionType1={state.modalButtonActionType1}
					modalButtonAction1={state.modalButtonAction1}
					modalButtonVariant2={state.modalButtonVariant2}
					modalButtonText2={state.modalButtonText2}
					modalButtonActionType2={state.modalButtonActionType2}
					modalButtonAction2={state.modalButtonAction2}
					modalButtonColor2={state.modalButtonColor2}
				/>
			</SellerDependentView>
		</Box>
	);

}
