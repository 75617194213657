import React from "react";
import "./Hub.scss";
import SellerDependentView from "../Shared/SellerDependentView";
import MediaCard from "./components/Card";
import {
	Box,
	Grid,
	Typography,
} from "@mui/material";
import { ExternalLink } from "../../utils/link";

export default function Hub({ seller }) {

	const calltoBookLink = process.env.REACT_APP_CALL_TO_BOOK;
	const emailLink = process.env.REACT_APP_ONBOARDING_EMAIL;

	const WarehouseLocations = () => {

		return(
			<Box className="section-wrapper">

				<Typography component="h1" variant="h5" mb={1}>
					Warehouse Locations
				</Typography>
				
				<Typography component="div" variant="p" mb={2}>
					We have a number of warehouses in the UK & EU, to fulfill from new countries, please email <ExternalLink href={emailLink}>{emailLink}</ExternalLink> or book a call <ExternalLink href={calltoBookLink}>here</ExternalLink>.
				</Typography>

				<Grid container spacing={3} mb={1}>

					<Grid item xs={12} sm={6} md={3} lg={3}>
						<MediaCard
							titleText={"United Kingdom"}
							bodyText={"Our UK warehouses offer domestic standard & next day shipping as well as international options."}
							icon={"/flags/uk"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3} lg={3}>
						<MediaCard
							titleText={"Germany"}
							bodyText={"Our German warehouse can ship to the whole of the EU."}
							icon={"/flags/gr"}
						/>
					</Grid>						

					<Grid item xs={12} sm={6} md={3} lg={3}>
						<MediaCard
							titleText={"Czech Republic"}
							bodyText={"Our Czech Republic warehouse can ship to the whole of the EU."}
							icon={"/flags/cr"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3} lg={3}>
						<MediaCard
							titleText={"US (coming soon)"}
							bodyText={(
								<>
									We are looking to open a US warehouse soon, email <ExternalLink href={emailLink}>{emailLink}</ExternalLink> to join the waitlist & get notified when it&apos;s live.
								</>
							)}
							icon={"/flags/us"}
						/>
					</Grid>

				</Grid>

			</Box>
		);

	};

	const SalesChannels = () => {

		return(
			<Box className="section-wrapper">

				<Typography component="h1" variant="h5" mb={1}>
					Sales Channels
				</Typography>
				
				<Typography component="div" variant="p" mb={2}>
					To learn more about our sales channel integrations, please email <ExternalLink href={emailLink}>{emailLink}</ExternalLink> or book a call <ExternalLink href={calltoBookLink}>here</ExternalLink>.
				</Typography>

				<Grid container spacing={3} mb={1}>

					<Grid item xs={12} sm={6} md={4} lg={4}>
						<MediaCard
							titleText={"Shopify"}
							bodyText={""}
							icon={"/channels/shopify"}
							type={"icon"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={4}>
						<MediaCard
							titleText={"WooCommerce"}
							bodyText={""}
							icon={"/channels/woocommerce"}
							type={"icon"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={4}>
						<MediaCard
							titleText={"Amazon"}
							bodyText={""}
							icon={"/channels/amazon"}
							type={"icon"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={4}>
						<MediaCard
							titleText={"Ebay"}
							bodyText={""}
							icon={"/channels/ebay"}
							type={"icon"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={4}>
						<MediaCard
							titleText={"OnBuy"}
							bodyText={""}
							icon={"/channels/onbuy"}
							type={"icon"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={4}>
						<MediaCard
							titleText={"Plus others"}
							bodyText={"Contact onboarding for more details"}
							icon={"/icons/settings"}
							type={"icon"}
						/>
					</Grid>

				</Grid>

			</Box>
		);

	};

	const SellerTools = () => {

		return(
			<Box className="section-wrapper">

				<Typography component="h1" variant="h5" mb={1}>
					Seller Tools
				</Typography>
				
				<Typography component="div" variant="p" mb={2}>
					To learn more about the tools we have to help automate your fulfilment, please email <ExternalLink href={emailLink}>{emailLink}</ExternalLink> or book a call <ExternalLink href={calltoBookLink}>here</ExternalLink>.
				</Typography>

				<Grid container spacing={3} mb={1}>

					<Grid item xs={12} sm={6} md={6} lg={6}>
						<MediaCard
							titleText={"Branded Returns Portal"}
							bodyText={"Let consumers get their return labels themselves in the branded return portal. Define your criteria, keep track of data, and reduce your return rate."}
							icon={"/icons/returns"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={6}>
						<MediaCard
							titleText={"Freight"}
							bodyText={"Handling your own freight forwarding is time consuming and stressful. Let Bezos connect you to experts to get the best possible deal tailored to your brand."}
							icon={"/icons/freight"}
						/>
					</Grid>						

				</Grid>

			</Box>
		);

	};


	const PageHeading = () => {
		return (
			<Grid container mb={2}>
				<Grid item xs="auto">
					<Typography component="h1" variant="h5">
						Hub
					</Typography>
				</Grid>
				<Grid item xs />
			</Grid>
		);
	};

	return (
		<Box id="hub">
			<SellerDependentView seller={seller}>
				<Box>
					<PageHeading />

					<Grid container mb={2}>
						<Grid item xs={12} mb={4}>
							<WarehouseLocations />
						</Grid>

						<Grid item xs={12} mb={4}>
							<SalesChannels />
						</Grid>
						
						<Grid item xs={12} mb={4}>
							<SellerTools />
						</Grid>						
					</Grid>

				</Box>
			</SellerDependentView>
		</Box>
	);

}
