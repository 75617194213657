export const containsItemWithKeyValue = (o, k, v) => Array.isArray(o) && o.some(x => x[k] === v);

export const getValueByID = (obj, id, key) => {
	const filteredObj = obj.find((item) => item.id === id);
	if (!filteredObj) return;
	return filteredObj[key];
};

export const isPropertyEmpty = (objArray, propertyName) => {
	if (objArray && Array.isArray(objArray) && objArray.length > 0) {
		const item = objArray[0];
		if (item && typeof item === "object" && propertyName in item) {
			return item[propertyName] === "";
		}
	}
	return false;
};

export const getKeyValue = (obj, searchKey, keyValue, targetKey) => {
	if (!obj || !Array.isArray(obj) || obj.length === 0 || !searchKey || !keyValue || !targetKey) return;
	const item = obj.find(item => item[searchKey] === keyValue);
	return item ? item[targetKey] : "";
};

export const addId = (objArray) => {
	if (objArray && Array.isArray(objArray) && objArray.length > 0) {
		return objArray.map(function (obj, index) {
			return Object.assign({}, obj, { id: index });
		});
	}
};

export const getPropertyById = (obj, id) => {
	if (!obj || Object.keys(obj).length === 0) return "";
	if (id === "" || id === undefined) return "";
	return obj[id];
};

export const getIdByValue = (obj, value) => {
	const foundEntry = Object.entries(obj).find(([, val]) => val === value);
	return foundEntry ? foundEntry[0] : 0;
};

export const updateExistingObject = (existingObject, updatedObject) => {
	const safeExisting = Object.assign(Object.create(null), existingObject);
	const safeUpdated = Object.assign(Object.create(null), updatedObject);

	for (let key in safeExisting) {
		if (safeUpdated[key] !== undefined) {
			existingObject[key] = safeUpdated[key];
		}
	}

	return existingObject;
};

export const renameKey = (obj, oldKey, newKey) => {
	if (oldKey === newKey || !(oldKey in obj)) return;

	obj[newKey] = obj[oldKey];
	delete obj[oldKey];

	return obj;
};
