import React from "react";
import "./ResultsFound.scss";
import { Box } from "@mui/material";

export const RecordsTotal = ({ total, sx }) => {

	if (total === 0) {
		return (
			<Box className="results-found-text-alert" sx={{ ...sx }}>
				0 Results Found
			</Box>
		);
	} else {
		return (
			<Box className="results-found-text" sx={{ ...sx }}>
				{total} Results Found
			</Box>
		);
	}

};