import React from "react";
import { Link } from "react-router-dom";
import {
	Button,
	MenuItem,
	Menu,
	ListItemText,
	ListItemIcon,
	Fade,
	Box
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";
import SearchDetail from "@mui/icons-material/ContentPasteSearchTwoTone";

export default function FadeMenu({ return_id }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const returnID = return_id;

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (

		<Box>
			{returnID != null && returnID !== 0 &&
			<>
				<Button
					variant="outlined"
					id="fade-button"
					aria-controls="fade-menu"
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					disableElevation
				>
					<MoreHorizIcon fontSize="small" />
				</Button>
				<Menu
					id="demo-positioned-menu"
					aria-labelledby="demo-positioned-button"
					MenuListProps={{
						"aria-labelledby": "fade-button",
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
					elevation={5}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>

					<MenuItem component={Link} to={"/return-detail/" + returnID}>
						<ListItemIcon>
							<SearchDetail />
						</ListItemIcon>
						<ListItemText>
							View return details
						</ListItemText>
						<ArrowRight />
					</MenuItem>
				</Menu>
			</>}
		</Box>
	);

}
