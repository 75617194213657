import React, {
	useCallback,
	useRef,
	useState
} from "react";
import * as Yup from "yup";
import {
	Form,
	Formik
} from "formik";
import TextFieldComp from "../../GoodsInCreate/components/TextField";
import {
	Box,
	Button,
	LinearProgress
} from "@mui/material";
import { DataContext } from "../../../App";
import UseFetchData from "../../Shared/FetchData/FetchData";
import DropDown from "../../Shared/DropDown";
import Modal from "../../Shared/Modals/Modal";
import { useNavigate } from "react-router-dom";
import { isURL } from "../../../utils/string";

export function ExtraDetails({
	seller,
	productDetailsForm,
	productDimensionsForm,
	onPanelsVisibleChange,
	existingProduct
}) {
	const context = React.useContext(DataContext);
	const formikRef = useRef(null);
	const navigate = useNavigate();

	const countries = UseFetchData(useCallback(() => context.dataProvider.getSellerCountries(seller), [context.dataProvider, seller]));

	const [loading, setLoading] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const getCountryIDByName = (name) => {
		let countryID = 0;

		for (let country of countries.results) {
			if (country.name === name) {
				countryID = country.platform_id;
				break;
			}
		}

		return countryID;
	};

	const [state, setState] = useState({
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const INITIAL_FORM_STATE = {
		retail_price: existingProduct.retail_price || "",
		cost_price: existingProduct.cost_price || "",
		country_of_manufacture_id: existingProduct.country_of_manufacture ? getCountryIDByName(existingProduct.country_of_manufacture) : 0,
		commodity_code: existingProduct.commodity_code || "",
		image_url: isURL(existingProduct.image_url) ? existingProduct.image_url : "",
	};

	let returnPath = "/inventory";
	if (existingProduct.product_id) {
		returnPath = "/inventory-detail/" + existingProduct.product_id;
	}

	const FORM_VALIDATION = Yup.object().shape({
		retail_price: Yup.number()
			.required("Enter the product's retail price in GBP (this can be approximate).")
			.moreThan(0.0, "Retail price in GBP must be greater than zero."),
		cost_price: Yup.number()
			.positive("Cost price in GBP must be greater than zero if specified (this has to be exact)."),
		country_of_manufacture_id: Yup.number()
			.required("Please select a country of manufacture from the list."),
		commodity_code: Yup.string()
			.when("country_of_manufacture_id", {
				is: (country_of_manufacture_id) => country_of_manufacture_id !== 1,
				then: () => Yup.string()
					.required("If your products are imported into the UK, please enter their commodity code or HS code.")
					.matches("^[0-9]{8,10}$", "This should be 8-10 digits.")
			}),
		image_url: Yup.string()
			.url("Enter the product image's URL. You can do this by selecting the product image on your website, press the right-click, and select Copy Image Address."),
		alt_code_name: Yup.string(),
		alt_code_type: Yup.string(),
	});

	const page_data = {
		countries: {
			resultsData: countries.results,
			status: countries.status,
			isLoading: countries.isLoading,
		},
		alt_code_types: [
			"Shopify",
			"Ebay",
			"Amazon",
			"WooCommerce",
			"Etsy",
		]
	};

	const successPageRedirect = () => {
		const timer = setTimeout(() => {
			navigate(returnPath);
		}, 5000);
		return () => clearTimeout(timer);
	};

	const createOrUpdateProduct = async (product) => {
		try {
			setLoading(true);
			setFormSubmitted(true);

			if (existingProduct.sku) {
				await context.dataProvider.updateProduct(seller, product);
			} else {
				await context.dataProvider.createProduct(seller, product);
			}

			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: existingProduct.sku ? "Product Updated" : "Product Created",
					modalBodyMessage1: existingProduct.sku ? "You have successfully updated product " + product.sku :
						"You have successfully created product " + product.sku,
				};
			});

			setState(previousState => {
				return {
					...previousState,
					modalButtonVariant1: "contained",
					modalButtonText1: existingProduct.sku ? "Return to " + existingProduct.sku : "Return to Inventory",
					modalButtonActionType1: "link",
					modalButtonAction1: returnPath,
					isModalOpen: true
				};
			});

			successPageRedirect();
		} catch (error) {
			let headerMessage = existingProduct.sku ? "Product update was unsuccessful" : "Product creation unsuccessful";
			let bodyMessage1 = "Please try again, and if the problem continues email support@bezos.ai";

			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: headerMessage,
					modalWarningMessage: null,
					modalErrorMessage: error.response && error.response.data !== "" ? error.response.data : "Something went wrong.",
					modalBodyMessage2: bodyMessage1,
					modalButtonVariant1: "contained",
					modalButtonText1: "Return to Product",
					modalButtonActionType1: "close",
					modalButtonAction1: "", // empty if action type = close
					isModalOpen: true
				};
			});

			setFormSubmitted(false);
		}
		setLoading(false);
	};

	const onSubmitForm = (values) => {
		values.retail_price = parseFloat(values.retail_price);
		if (isNaN(values.retail_price)) {
			values.retail_price = 0.0;
		}

		values.cost_price = parseFloat(values.cost_price);
		if (isNaN(values.cost_price)) {
			values.cost_price = 0.0;
		}

		let product = Object.assign(productDetailsForm, productDimensionsForm);
		product = Object.assign(product, values);

		if (values.alt_code_type && values.alt_code_name) {
			product.alt_codes = [
				{
					sku: values.alt_code_name,
					type: values.alt_code_type
				}
			];
		}

		createOrUpdateProduct(product);
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isProductDetailsPanelVisible: false,
			isProductDimensionsPanelVisible: true,
			isExtraDetailsPanelVisible: false,
		});
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				innerRef={formikRef}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ values }) => (
					<Form
						noValidate
						autoComplete="off"
					>
						<TextFieldComp
							name="retail_price"
							label="Retail Price (£)"
							type="number"
							required
							InputProps={{
								inputProps: {
									min: 0,
									step: 0.01,
								}
							}}/>

						<TextFieldComp
							name="cost_price"
							label="Cost Price (£)"
							type="number"
							InputProps={{
								inputProps: {
									min: 0,
									step: 0.01,
								}
							}}
						/>

						<DropDown
							id="country_of_manufacture_id"
							name="country_of_manufacture_id"
							label="Country of Manufacture"
							value={values.country_of_manufacture_id}
							data={page_data.countries.resultsData.sort((b, a) => a.name > b.name ? -1 : 1).map((c) => ({
								key: c.platform_id,
								value: c.platform_id,
								name: c.name
							}))}
							required={true}
						/>

						<TextFieldComp
							name="commodity_code"
							label="Commodity Code"
							helperText="If your products are imported into the UK, please enter their commodity code or HS code. These are 8-12 digit numbers."
							type="string"
						/>

						<TextFieldComp
							name="image_url"
							label="Image URL"
							type="string"
						/>

						{!existingProduct.sku ?
							(
								<>
									<TextFieldComp
										name="alt_code_name"
										label="Alt Code Name"
										helperText="Enter the product's internal SKU code used in your marketplace integration."
										type="string"
									/>

									<DropDown
										id="alt_code_type"
										name="alt_code_type"
										label="Alt Code Type"
										value={values.alt_code_type ?? ""}
										helperText="Specify the primary integration you are using for you product"
										data={page_data.alt_code_types.sort((b, a) => a > b ? -1 : 1).map((t) => ({
											key: t,
											value: t,
											name: t
										}))}
										required={false}
									/>
								</>
							)
							: null
						}

						{loading ?
							(
								<Box mt={5} mb={5} sx={{ width: "100%" }}>
									<LinearProgress/>
								</Box>
							)
							: null
						}

						<Box className="align-content-right">
							<Button
								variant="outlined"
								color="secondary"
								className="back-button"
								onClick={() => handleBackButton()}
								disabled={formSubmitted}
							>
								Back
							</Button>

							<Button
								type="submit"
								variant="contained"
								disabled={formSubmitted}
							>
								{
									existingProduct.sku ? "Update Product" : "Create Product"
								}
							</Button>
						</Box>

						<Modal
							onModalClose={onModalClose}
							isModalOpen={state.isModalOpen}
							modalBodyTitle={state.modalBodyTitle}
							modalBodyMessage1={state.modalBodyMessage1}
							modalBodyMessage1Data={state.modalBodyMessage1Data}
							modalBodyMessage2={state.modalBodyMessage2}
							modalBodyInputFields={state.modalBodyInputFields}
							modalButtonVariant1={state.modalButtonVariant1}
							modalButtonText1={state.modalButtonText1}
							modalButtonActionType1={state.modalButtonActionType1}
							modalButtonAction1={state.modalButtonAction1}
							modalButtonVariant2={state.modalButtonVariant2}
							modalButtonText2={state.modalButtonText2}
							modalButtonActionType2={state.modalButtonActionType2}
							modalButtonAction2={state.modalButtonAction2}
							modalButtonColor2={state.modalButtonColor2}
							warningMessage={state.modalWarningMessage}
							errorMessage={state.modalErrorMessage}
						/>
					</Form>
				)}
			</Formik>
		</>
	);
}