import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { LoadableContent } from "../../Shared/LoadableContent/LoadableContent";
import {
	Card,
	CardContent,
	Typography,
	Box
} from "@mui/material";
import { LightTooltip } from "../../Shared/LightTooltip/LightTooltip";
import { formatNumber } from "../../../utils/number";

export default function MediaCard({
	data, titleText, bodyText,
	hasImage, url, toolTip,
	dateRange,
}) {

	const imageName = bodyText > 0 ? "icon-error" : "icon-checked";

	const CardWrapper = () => (
		<Card
			sx={{ width: "100%", height: "100%" }}
			className="align-items-vertical"
		>
			<LoadableContent marginBottom={8} isLoading={data.isLoading} sx={{ marginTop: "2rem" }}>
				<CardContent>

					<Typography
						variant="h6"
						component="div"
						gutterBottom
						sx={{ fontWeight: 500, textAlign: "center" }}
						className="card-title"
					>
						{titleText}
					</Typography>
					
					<LoadableContent marginBottom={8} isLoading={data.results.length > 0}>
						<Box className="card-body">
							<Typography
								className="card-text"
								variant="body2"
								sx={{
									fontSize: {
										xs: "3rem",
										sm: "2.5rem",
										md: "2.5rem",
										lg: "3rem",
									}
								}}
							>
								{formatNumber(bodyText)}
							</Typography>

							{hasImage && <img
								src={`/cards/${imageName}.svg`}
								className='card-image'
								height="50"
								alt={titleText}
								title={titleText}
								loading="lazy"
							/>
							}
						</Box>
					</LoadableContent>

				</CardContent>
			</LoadableContent>
		</Card>
	);

	return (
		<>
			<LightTooltip
				arrow
				title={url && bodyText > 0 ? 
					`Click to view ${titleText} for ${dateRange}` 
					: toolTip
				}
			>
				{url && bodyText > 0 ?
					<Box
						sx={{ textDecoration: "none" }}
						component={RouterLink}
						to={url}
					>
						<CardWrapper />
					</Box>
					:
					<Box>
						<CardWrapper />
					</Box>
				}
			</LightTooltip>
		</>
	);

}