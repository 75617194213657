import React, {
	useRef,
	useState,
	useCallback,
	useEffect
} from "react";
import {
	Grid,
	Typography,
	Button,
	Box
} from "@mui/material";
import SelectWarehouseAndCourier from "../../components/SelectWarehouseAndCourier";
import {
	Formik,
	Form
} from "formik";
import { TextfieldFormik } from "../../../Shared/TextField/TextField";
import * as Yup from "yup";
import useFetchData from "../../../Shared/FetchData/FetchData";
import { FindAddress } from "../../components/FindAddress";
import Portal from "@mui/base/Portal";
import {
	registerValidateRequiredContactMethod,
	requiresContactDetails,
	registerValidateRequiredForNonUK
} from "../../../../utils/contactValidationUtils.js";
import { DataContext } from "../../../../App";
import DropDown from "../../../Shared/DropDown";
import { RadioGroupFormik } from "../../../Shared/RadioGroup/RadioGroup";
import { capitalizeFirstLetter } from "../../../../utils/text";
import { getKeyValue } from "../../../../utils/object.js";

export default function ShippingDetails({
	sellerID,
	existingOrder,
	onWarehouseChange,
	onShippingDetailsFormSubmit,
	onIsDomesticChange,
	onPanelsVisibleChange,
	orderError,
	orderOperation,
}) {
	const [state, setState] = useState({
		autoAddress: {},
		areAddressFieldsHidden: existingOrder === null,
		isDomestic: true,
		isDisabled: false,
	});

	const context = React.useContext(DataContext);
	const warehouse = useFetchData(useCallback(() => context.dataProvider.getSellerWarehouses(sellerID), [context.dataProvider, sellerID]));
	const country = useFetchData(useCallback(() => context.dataProvider.getSellerCountries(sellerID), [context.dataProvider, sellerID]));
	const [selectedWarehouse, setSelectedWarehouse] = useState(existingOrder?.warehouse_details || "");
	const [selectedCourier, setSelectedCourier] = useState(existingOrder?.courier_service || "");
	const [selectedCountry, setSelectedCountry] = useState(existingOrder?.country || "");
	const [selectedCountryID, setSelectedCountryID] = useState();
	const [selectedSalesChannelReference, setSelectedSalesChannelReference] = useState(existingOrder?.sales_channel_reference || "N/A");
	const [autogenerateOrderNumber, setAutogenerateOrderNumber] = useState(true);

	const page_data = {
		warehouse: {
			resultsData: warehouse.results,
			status: warehouse.status,
			isLoading: warehouse.isLoading,
		},
		country: {
			resultsData: country.results,
			status: country.status,
			isLoading: country.isLoading,
		},
		sales_channels: [
			"Shopify",
			"Woocommerce",
			"Amazon",
			"Ebay",
			"Onbuy",
			"Influencer",
			"B2B",
			"PR",
			"Direct Order",
			"Other",
			"N/A",
		],
	};

	const INITIAL_FORM_STATE = {
		order_number: existingOrder?.order_number || "",
		autogenerate_order_number: "Yes",
		warehouse_id: existingOrder?.warehouse_id || "",
		courier_service: existingOrder?.courier_service || "",
		external_order_reference: existingOrder?.external_reference || "",
		sales_channel_reference: existingOrder?.sales_channel_reference || "N/A",
		title: existingOrder?.title || "",
		first_name: existingOrder?.first_name || "",
		last_name: existingOrder?.last_name || "",
		company_name: existingOrder?.company_name || "",
		email: existingOrder?.email || "",
		phone: existingOrder?.phone || "",
		address_line_1: existingOrder?.address1 || "",
		address_line_2: existingOrder?.address2 || "",
		address_line_3: existingOrder?.address3 || "",
		town: existingOrder?.town || "",
		county: existingOrder?.county || "",
		postcode: existingOrder?.postcode || "",
		country: existingOrder?.country || "",
	};

	const phoneRegExp = /^((\\+[0-9+()]{1,4}[ \\-]*)|(\\([0-9+()]{2,3}\\)[ \\-]*)|([0-9+()]{2,4})[ \\-]*)*?[0-9+()]{3,4}?[ \\-]*[0-9+()]{3,4}?$/;
	const orderNumberRegExp = /^[a-zA-Z0-9_-]*$/;
	const invalidOrderNumber = orderError.orderNumber;
	
	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.validateForm();
		}
	}, [invalidOrderNumber]);

	useEffect(() => {
		if (orderOperation === "edit") {
			setState(previousState => {
				return {
					...previousState,
					isDisabled: true
				};
			});
		}
	}, [orderOperation]);

	registerValidateRequiredContactMethod(() => selectedWarehouse, () => selectedCountry);
	registerValidateRequiredForNonUK(() => selectedCountry);

	const FORM_VALIDATION = Yup.object().shape({
		first_name: Yup.string()
			.required("First name required"),
		last_name: Yup.string()
			.required("Last name required"),
		email: Yup.string()
			.validateRequiredContact("Email is required for orders to this destination from this warehouse")
			.email("Invalid email"),
		phone: Yup.string()
			.validateRequiredContact("Phone is required for orders to this destination from this warehouse")
			.matches(phoneRegExp, "Phone number is not valid"),
		address_line_1: Yup.string()
			.required("Address 1 is required"),
		town: Yup.string()
			.required("Town is required"),
		postcode: Yup.string()
			.required("Postcode is required"),
		county: Yup.string()
			.validateRequiredForNonUK("County is required for orders to this destination"),
		country: Yup.string()
			.required("Please select a Country from the list"),
		warehouse_id: Yup.number()
			.test(
				"country-warehouse-dependency",
				function(value) {
					const country = this.parent.country;
					if (!country) {
						return this.createError({ path: "warehouse_id", message: "Please select a Country first" });
					}
					if (!value && value !== 0) {
						return this.createError({ path: "warehouse_id", message: "Please select a Warehouse from the list" });
					}
					return true;
				}
			),
		courier_service: Yup.string()
			.required("Please select a Courier from the list"),
		order_number: Yup.string()
			.test("conditional-validation", function(value) {
				const autogenerateOrderNumber = this.resolve(Yup.ref("autogenerate_order_number"));
				if (autogenerateOrderNumber === "No") {
					if (!value) {
						return this.createError({ message: "Order number field empty" });
					}
					if (value.length < 3) {
						return this.createError({ message: "Minimum 3 characters required" });
					}
					if (!orderNumberRegExp.test(value)) {
						return this.createError({ message: "Order number can only contain upper/lower case letters, numbers or hyphens with no spaces ie XXX-xxx-123" });
					}
					if (value === invalidOrderNumber) {
						return this.createError({ message: capitalizeFirstLetter(orderError.errorMessage) });
					}
				}
				return true;
			})
			.nullable(),
	});

	useEffect(() => {
		if (!selectedWarehouse || !selectedCountry) return;

		if (selectedWarehouse.country === selectedCountry) {
			setState(previousState => {
				return {
					...previousState,
					isDomestic: true
				};
			});
		} else {
			setState(previousState => {
				return {
					...previousState,
					isDomestic: false
				};
			});
		}
	}, [selectedCountry, selectedWarehouse]);

	const onSubmitForm = (values) => {
		onIsDomesticChange(state.isDomestic);
		onShippingDetailsFormSubmit(values);
		onWarehouseChange(values.warehouse_id);
		onPanelsVisibleChange({
			isShippingDetailsPanelVisible: false,
			isAddProductsPanelVisible: true,
			isAdditionalDetailsPanelVisible: false,
		});
	};

	const onChangeWarehouse = (warehouseId) => {
		for (const warehouse of page_data.warehouse.resultsData) {
			if (warehouse.id === warehouseId) {
				setSelectedWarehouse(warehouse);
			}
		}
	};

	const onChangeCourier = (courier) => {
		setSelectedCourier(courier);
	};

	const onChangeAutogenerateOrderNumber = (e) => {
		if (e.target.value === "Yes") {
			formikRef.current?.setFieldValue("order_number", "");
			setAutogenerateOrderNumber(true);
		} else {
			setAutogenerateOrderNumber(false);
		}
	};

	const onSelectAddress = (address) => {
		if (formikRef.current) {
			formikRef.current.setFieldValue("address_line_1", address.line_1);
			formikRef.current.setFieldValue("address_line_2", address.line_2);
			formikRef.current.setFieldValue("address_line_3", address.line_3);
			formikRef.current.setFieldValue("town", address.town_or_city);
			formikRef.current.setFieldValue("county", address.county);
			formikRef.current.setFieldValue("postcode", address.postcode);

			if (address.line_1) {
				formikRef.current.setFieldValue("country", "UNITED KINGDOM");
				setSelectedCountry("UNITED KINGDOM");
			}

			setState(previousState => {
				return {
					...previousState,
					areAddressFieldsHidden: false,
				};
			});
		}
	};

	const onClickManualAddress = () => {
		setState(previousState => {
			return {
				...previousState,
				areAddressFieldsHidden: !previousState.areAddressFieldsHidden
			};
		});
	};

	const handleCountryChange = (e) => {
		formikRef.current.setFieldValue("country", e);
		setSelectedCountry(e);
	};

	useEffect(() => {
		if (page_data.country.resultsData && selectedCountry) {
			setSelectedCountryID(getKeyValue(page_data.country.resultsData, "name", selectedCountry, "platform_id"));
		}
	}, [selectedCountry, existingOrder, page_data.country.resultsData]);

	const handleSalesChannelReferenceChange = (e) => {
		formikRef.current.setFieldValue("sales_channel_reference", e);
		setSelectedSalesChannelReference(e);
	};

	const formikRef = useRef(null);
	const findAddressPortalRef = useRef(null);

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				innerRef={formikRef}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ errors, values, touched, handleBlur, handleChange }) => (

					<Form
						noValidate
						autoComplete="off"
					>

						<Grid item xs="auto" mt={2}>
							<Typography component="h4" variant="h6">
								Recipient
							</Typography>
						</Grid>

						<TextfieldFormik
							name="title"
							label="Title"
						/>

						<TextfieldFormik
							name="first_name"
							label="First Name"
							required
						/>

						<TextfieldFormik
							name="last_name"
							label="Last Name"
							required
						/>

						
						<TextfieldFormik
							name="company_name"
							label="Company Name"
							disabled={state.isDisabled}
						/>

						<TextfieldFormik
							name="email"
							label="Email"
							type="email"
							required={requiresContactDetails(selectedWarehouse, selectedCountry)}
						/>

						<TextfieldFormik
							name="phone"
							label="Phone"
							required={requiresContactDetails(selectedWarehouse, selectedCountry)}
						/>

						<Grid item xs="auto">
							<Typography component="h4" variant="h6" mt={2}>
								Delivery Address
							</Typography>
						</Grid>

						{orderOperation !=="edit" &&
							<Box ref={findAddressPortalRef}/>
						}
						
						<Box hidden={state.areAddressFieldsHidden}>
							<TextfieldFormik
								name="address_line_1"
								label="Address 1"
								required
							/>

							<TextfieldFormik
								name="address_line_2"
								label="Address 2"
							/>

							<TextfieldFormik
								name="address_line_3"
								label="Address 3"
							/>

							<TextfieldFormik
								name="town"
								label="Town"
								required
							/>

							<TextfieldFormik
								name="county"
								label="County"
								required
							/>

							<TextfieldFormik
								name="postcode"
								label="Postcode"
								required
							/>

							<DropDown
								id="country"
								name="country"
								label="Country"
								value={selectedCountry}
								onChange={handleCountryChange}
								data={page_data.country.resultsData.sort((b, a) => a.name > b.name ? -1 : 1).map((c) => ({
									key: c.code,
									value: c.name,
									name: c.name
								}))}
								required={true}
								isLoading={page_data.country.isLoading}
							/>
						</Box>

						<Grid item xs="auto">
							<Typography component="h4" variant="h6" mt={2}>
								Order Details
							</Typography>
						</Grid>

						<SelectWarehouseAndCourier
							seller={sellerID}
							selectedWarehouseID={selectedWarehouse?.id || ""}
							selectedCourier={selectedCourier || ""}
							warehouses={page_data.warehouse}
							countryID={selectedCountryID}
							onChangeWarehouse={onChangeWarehouse}
							onChangeCourier={onChangeCourier}
							disabled={state.isDisabled}
						/>

						{orderOperation !=="edit" &&
							<Grid item xs="auto" ml={1} mt={2}>
								<RadioGroupFormik
									label="Autogenerate order number?"
									name="autogenerate_order_number"
									defaultValue="Yes"
									options={["Yes", "No"]}
									labelPlacement="end"
									props={{ errors, values, touched, handleBlur, handleChange }}
									onChange={onChangeAutogenerateOrderNumber}
									required								
								/>
							</Grid>
						}

						{!autogenerateOrderNumber &&
							<TextfieldFormik
								name="order_number"
								label="Order Number"
								id="orderNumber"
								required
							/>
						}

						<TextfieldFormik
							name="external_order_reference"
							label="External Order Reference"
							disabled={state.isDisabled}
						/>

						<DropDown
							id="sales_channel_reference"
							name="sales_channel_reference"
							label="Sales Channel Reference"
							value={selectedSalesChannelReference}
							onChange={handleSalesChannelReferenceChange}
							data={page_data.sales_channels.sort((b, a) => a > b ? -1 : 1).map((sc) => ({
								key: sc,
								value: sc,
								name: sc,
							}))}
						/>

						<Box className="align-content-right">
							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
			
			{orderOperation !=="edit" &&
				<Portal container={findAddressPortalRef.current}>
					<FindAddress onSelectAddress={onSelectAddress} onClickManualAddress={onClickManualAddress}/>
				</Portal>
			}

		</>
	);

}