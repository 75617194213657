import React from "react";
import {
	Box,
	Alert,
	Link
} from "@mui/material";
import { LoadingStatusBox } from "../LoadingStatus/LoadingStatus";

const PermissionAlert = () => {
	return (
		<Alert severity="error">
			<Box className="alert-message">This page is blocked, please contact <Link href="mailto:support@bezos.ai">support@bezos.ai</Link> if you require access to this page.</Box>
		</Alert>
	);
};

export default function PermissionDependentView(props) {

	if (props.userdata.permissions.size === 0) {
		return <LoadingStatusBox message={"Loading..."} marginBottom={5} />;
	}

	return (props.userdata.permissions.has(props.permission)) ? <>{props.children}</> : <PermissionAlert />;

}
