import React, {
	useEffect,
	useState
} from "react";
import { DataContext } from "../../../App";
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	Typography,
} from "@mui/material";
import { getDisplayTime } from "../../../utils/date";
import { ViewButtonSingle } from "../../Shared/ViewButton/ViewButton";
import Modal from "../../Shared/Modals/Modal";

export default function UserManagement({ seller }) {
	const context = React.useContext(DataContext);

	const [state, setState] = useState({
		users: [],
		reloadTable: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		modalWarningMessage: "",
	});

	useEffect(() => {
		const getUsers = async () => {
			context.dataProvider.getAllUsers(seller)
				.then(response => {
					setState(previousState => {
						return {
							...previousState,
							users: response.data,
						};
					});
				});
		};
		getUsers();
	}, [context.dataProvider, seller, state.reloadTable]);

	const resetModal = () => {
		return (
			setState(previousState => {
				return {
					...previousState,
					isModalOpen: false,
					modalBodyTitle: "",
					modalBodyMessage1: "",
					modalBodyMessage2: "",
					modalBodyInputFields: {},
					modalButtonText1: "",
					modalButtonText2: "",
					modalWarningMessage: "",
				};
			})
		);
	};

	const onClickFetchEmailModal = async (username) => {

		resetModal();

		await context.dataProvider.getUserEmail(seller, username)
			.then(response => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: `Email address found for ${username}`,
						modalBodyMessage1: response.data?.email,
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: `Email address not found for ${username}`,
						modalBodyMessage1: "Something went wrong, please contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const modalErrorMessageAddUser = () => {
		return (
			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: "User creation was unsuccessful",
					modalBodyMessage1: "Something went wrong.",
					modalBodyMessage2: "Please try again, if this problem continues then contact support@bezos.ai",
					modalButtonVariant1: "contained",
					modalButtonText1: "Close",
					modalButtonActionType1: "close",
					modalButtonAction1: "",
				};
			})
		);
	};

	const onClickAddUserDetails = async (values) => {

		if (!seller) return;
		if (!values.user || !values.email) {
			setState(previousState => {
				return {
					...previousState,
					modalWarningMessage: "Please complete both fields",
					isModalOpen: true,
				};
			});
			return;
		}

		resetModal();
		
		await context.dataProvider.createUser(seller, values.user, values.email)
			.then(response => {
				if (response) {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: `${values.user} successfully created`,
							modalBodyMessage1: `An email with subject "Your temporary password" has been sent to ${values.email}`,
							modalBodyMessage2: "Please login into the Seller Portal using this temporary password within 24 hours to activate the new user account.",
							modalButtonVariant1: "contained",
							modalButtonText1: "Done",
							modalButtonActionType1: "close",
							modalButtonAction1: "",
							reloadTable: true,							
						};
					});
				} else {
					modalErrorMessageAddUser();
				}
			})
			.catch(() => {
				modalErrorMessageAddUser();
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
				modalBodyInputFields: {},
			};
		});

	};

	const onClickFetchAddUserModal = async () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Add a New User",
				modalBodyMessage1: "",
				modalBodyMessage2: "",
				modalBodyInputFields: {
					user: {
						label: "Username",
						type: "text",
					},
					email: {
						label: "Email",
						type: "text",
					},
				},
				modalButtonVariant1: "outlined",
				modalButtonText1: "Cancel",
				modalButtonActionType1: "close",
				modalButtonAction1: onModalClose,
				modalButtonVariant2: "contained",
				modalButtonText2: "Save",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickAddUserDetails,
				modalButtonColor2: "primary",
				isModalOpen: true,
			};
		});
	};

	const AddUserButton = () => {
		return (
			<Button
				variant="outlined"
				color="secondary"
				className="reload-button"
				onClick={() => onClickFetchAddUserModal()}
				sx={{ mb:2 }}
			>
				Add User
			</Button>
		);
	};

	const PageHeading = () => {
		return (
			<Grid item xs={12}>
				<Typography component="h1" variant="h5" sx={{ mb:2 }}>
					Users
					<AddUserButton />
				</Typography>
			</Grid>
		);
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const rows = () => {
		if (!state.users) {
			return [];
		}

		return state.users.sort((a, b) => a.username > b.username ? 1 : -1);
	};

	const UsersTable = () => {
		return (
			<TableContainer component={Paper}>
				<Table
					aria-label="user table"
					id="user-table"
					stickyHeader
					size={"small"}
					sx={{ mb:2 }}
				>
					<TableHead>
						<TableRow>
							<TableCell>Username</TableCell>
							<TableCell>Roles</TableCell>
							<TableCell>Created At</TableCell>
							<TableCell>Created By</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							(state.users) ?
								rows().map((u, index) =>
									<TableRow key={index}>
										<TableCell>
											{u.username}
										</TableCell>
										<TableCell>
											{u.roles.toString()}
										</TableCell>
										<TableCell>
											{getDisplayTime(u.created_at)}
										</TableCell>
										<TableCell>
											{u.created_by}
										</TableCell>
										<TableCell title="More actions" align="right">
											<ViewButtonSingle
												buttonTitle="Get User Email"
												buttonOnClick={() => onClickFetchEmailModal(u.username)}
											/>
										</TableCell>
									</TableRow>
								) :
								<TableRow>
									<TableCell colSpan={2} align="center">
										No users found
									</TableCell>
								</TableRow>
						}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	return (
		<Grid container sx={{ mb: 1 }}>
			
			<Grid item xs={12}>
				<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
					<PageHeading />
					<UsersTable />
				</Paper>
			</Grid>

			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalBodyInputFields={state.modalBodyInputFields}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
				warningMessage={state.modalWarningMessage}
			/>
		
		</Grid>

	);
}
