import React, {
	useState,
	useEffect,
	useCallback
} from "react";
import {
	Link as RouterLink,
	useParams
} from "react-router-dom";
import "./OrderView.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import SellerDependentView from "../Shared/SellerDependentView";
import { checkIfOrderSentToWarehouse } from "../../utils/orderStatus";
import GenerateAReturnLabel from "./components/GenerateAReturnLabel";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import Modal from "../Shared/Modals/Modal";
import { getDisplayTime } from "../../utils/date";
import { UploadFile } from "../Shared/UploadFiles/UploadFile";
import {
	Button,
	Box,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Grid,
	Link,
} from "@mui/material";
import { DataContext } from "../../App";
import ItemDisplay from "../Shared/ViewItems/ItemDisplay";
import RequestProofofDeliveryButton from "../Shared/RequestProofofDelivery/RequestProofofDelivery";
import LightTooltip from "../Shared/LightTooltip";
import { LoadingStatusTable } from "../Shared/LoadingStatus/LoadingStatus";
import { setDocumentTitle } from "../../utils/page";
import OrderTimeline from "../Shared/OrderTimeline/OrderTimeline";
import DownloadDocument from "../Shared/DownloadDocument/DownloadDocument";
import CourierTracking from "../Shared/CourierTracking/CourierTracking";
import { roundToPrecision } from "../../utils/number";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function OrderView({ seller, userdata }) {

	const order = useParams().id;
	const [state, setState] = useState({
		orderCancelledInWarehouseMessage: "",
		orderCancellationRequestEmailSent: false,
		orderCancelledInWarehouseDate: "",
		isVisibleGenerateReturnLabelDiv: false,
		isVisibleMoreActionsDiv: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		documentsShipping: [],
		documentsInvoice: [],
	});

	const context = React.useContext(DataContext);

	const {
		status,
		results,
		isLoading
	} = useFetchData(useCallback(() => context.dataProvider.getOrderDetail(seller, order), [context.dataProvider, seller, order]));

	const page_data = {
		order: {
			order_number: results.order_number,
			external_reference: results.external_reference,
			sales_channel_reference: results.sales_channel_reference,
			courier_status: results.courier_status,
			status: `${results.status}`,
			bezos_created_date: results.bezos_created_date,
			warehouse_creation_date: results.warehouse_creation_date,
			source: results.source,
			warehouse: results.warehouse_details || {},
			warehouse_name: results.warehouse_details ? results.warehouse_details.name : "",
			warehouse_id: results.warehouse_id,
			warehouse_supports_cancellation: results.warehouse_supports_cancellation,
			platform_order_id: results.platform_order_id,
			order_id: results.order_id,
			created_by: results.created_by,
		},
		product: {
			items: results.items || [],
		},
		shipping: {
			courier_service_type: results.courier_service_type,
			courier_service: results.courier_service,
			tracking_number: results.tracking_number,
			tracking_url: results.tracking_url,
			number_of_parcels: results.number_of_parcels,
			total_items: results.total_items,
			weight: results.weight,
		},
		deliveryProof: {
			manual_pod_request: results.manual_pod_request,
			pod_request_sent: results.pod_request_sent
		},
		recipient: {
			person: {
				title: results.title,
				first_name: results.first_name,
				last_name: results.last_name,
				email: results.email,
				phone: results.phone,
			},
			address: {
				address1: results.address1,
				address2: results.address2,
				address3: results.address3,
				town: results.town,
				county: results.county,
				postcode: results.postcode,
				country: results.country,
			}
		},
	};

	useEffect(() => {
		const onLoadCheckIforderCancelledInWarehouseMessage = () => {

			if (!checkIfOrderSentToWarehouse(page_data.order.warehouse_creation_date)) {
				return "";
			}

			if (page_data.order.status === "processed" && page_data.order.warehouse_supports_cancellation === true) {

				if (!seller || !page_data.order.order_id) return;

				context.dataProvider.getOrderCancellationRequest(seller, page_data.order.order_id)
					.then(response => {
						setState(previousState => {
							return {
								...previousState,
								orderCancelledInWarehouseMessage: response.data.message,
								orderCancelledInWarehouseDate: response.data.created_at,
								orderCancellationRequestEmailSent: response.data.email_sent,
							};
						});
					});
			}

		};
		onLoadCheckIforderCancelledInWarehouseMessage();
	}, [page_data.order.warehouse_creation_date, page_data.order.order_id, page_data.order.status, page_data.order.warehouse_supports_cancellation, state.orderCancelledInWarehouseMessage, seller, context.dataProvider]);

	const splitOrCancelOrderAllowedStatuses = ["onbackorder", "fraudrisk", "awaitingconfirmation", "new", "held", "pending", "courier dimensions exceeded"];
	const enableCancelOrderButton = splitOrCancelOrderAllowedStatuses.includes(page_data.order.status) ||
		(page_data.order.status === "sent to warehouse" && page_data.order.warehouse_supports_cancellation);

	const showGenerateReturnLabelButton = (page_data.order.status === "despatched" || page_data.order.status === "delivered") &&
		page_data.order.warehouse.return_label_available && userdata.permissions && userdata.permissions.has("create_return_label");
	const isDisabledGenerateReturnLabelButton = (page_data.recipient.address.county && page_data.recipient.address.county.toUpperCase() === "NORTHERN IRELAND");

	const getGenerateReturnLabelButtonDisabledReason = (address) => {
		if (address.country === "UK OFFSHORE" || address.country === "OFFSHORE & HIGHLANDS"
			|| (address.county && address.county.toUpperCase() === "NORTHERN IRELAND")) {
			return (
				<>
					{"You cannot generate a return label from this address via the seller portal. Please raise a ticket "}
					<a href={process.env.REACT_APP_SUBMIT_TICKET} target="_blank" rel="noopener noreferrer">here</a>
					{" and a member of the team can arrange a label."}
				</>
			);
		} else {
			return "Unfortunately you cannot generate international returns labels via Bezos and must arrange a label yourself.";
		}
	};

	let permissions = new Set();
	if (userdata.permissions && userdata.permissions.has("view_inventory_detail")) {
		permissions.add("inventory-detail");
	}

	const pageName = `Order Number: ${page_data.order.order_number}`;
	setDocumentTitle(pageName);

	const onClickGenerateReturnLabelButton = () => {
		setState(previousState => {
			return {
				...previousState,
				isVisibleGenerateReturnLabelDiv: !previousState.isVisibleGenerateReturnLabelDiv
			};
		});
	};

	const GenerateAReturnLabelHiddenDiv = () => {
		return (
			<Box className={`panel${state.isVisibleGenerateReturnLabelDiv === true ? "-show" : "-hide"}`}>
				<GenerateAReturnLabel
					orderID={page_data.order.order_id}
					seller={seller}
					orderNumber={order}
					warehouseCurrency={page_data.order.warehouse.currency}
					postcode={page_data.recipient.address.postcode}
					productsList={page_data.product.items}
					isVisible={state.isVisibleGenerateReturnLabelDiv}
					requireCourier={true}
				/>
			</Box>
		);
	};

	const onClickMoreActionsButton = () => {
		setState(previousState => {
			return {
				...previousState,
				isVisibleMoreActionsDiv: !previousState.isVisibleMoreActionsDiv
			};
		});
	};

	const RaiseAClaimButton = () => {
		return (
			<Grid item xs="auto">
				<Button
					variant="outlined"
					component={RouterLink}
					to="/claim"
					state={{
						orderNumber: page_data.order.order_number,
						courierService: page_data.shipping.courier_service
					}}
				>
					Raise a Claim
				</Button>
			</Grid>
		);
	};

	const getOrderTimelineModal = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: `${page_data.order.order_number} Order Timeline`,
				modalBodyMessage1Data: <OrderTimeline orderID={page_data.order.order_id} seller={seller}/>,
				modalButtonVariant1: "outlined",
				modalButtonText1: "Close",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				isModalOpen: true,
			};
		});
	};

	const ViewOrderTimelineButton = () => {
		return (
			<Grid item xs="auto">
				<Button
					variant="outlined"
					onClick={getOrderTimelineModal}
				>
					View Order timeline
				</Button>
			</Grid>
		);
	};

	const MoreActionsDiv = () => {
		return (
			<Box
				className={`panel${state.isVisibleMoreActionsDiv === true ? "-show" : "-hide"}`}
				sx={{ mb: 1 }}
			>
				<Grid container spacing={1}>
					<Grid item xs={6} md={10} lg={12} xl={12} container spacing={2} mb={1}>

						{(userdata.permissions && userdata.permissions.has("edit_order_copy_split_cancel")) &&
							<>
								<Grid item xs="auto">
									<Button
										variant="outlined"
										component={RouterLink}
										to="/order-copy"
										state={{
											existingOrder: results,
											orderOperation: "copy"
										}}
									>
										Copy Order
									</Button>
								</Grid>
							</>
						}

						{page_data.order.status !== "cancelled" &&
						<>
						
							{(userdata.permissions && userdata.permissions.has("release_pack_and_hold_order")) &&
								page_data.order.status === "held" &&
								<>
									<Grid item xs="auto">
										<Button
											variant="outlined"
											onClick={onReleaseOrderOnHold}
										>
											Release Order On Hold
										</Button>
									</Grid>
								</>
							}

							{(page_data.order.status === "despatched" || page_data.order.status === "delivered") &&
								<Grid item xs="auto">
									<Button variant="outlined" color="secondary" component={Link} href={process.env.REACT_APP_REPORT_MISPICK} target="_blank" rel="noopener noreferrer">
										Report Mispick
									</Button>
								</Grid>
							}

							{(userdata.permissions && userdata.permissions.has("edit_order_copy_split_cancel")) &&
								<>
									<SplitOrderButton/>
								</>
							}

							{showGenerateReturnLabelButton &&
								<LightTooltip
									title={getGenerateReturnLabelButtonDisabledReason(page_data.recipient.address)}
									disableHoverListener={!isDisabledGenerateReturnLabelButton}
									arrow
								>
									<Grid item xs="auto">
										<Button
											variant="outlined"
											color="secondary"
											onClick={onClickGenerateReturnLabelButton}
											disabled={isDisabledGenerateReturnLabelButton}
										>
											Generate A Return Label
										</Button>
									</Grid>
								</LightTooltip>
							}

							{page_data.order.courier_status === "delivered" &&
							page_data.deliveryProof.manual_pod_request === true &&
							userdata.permissions && userdata.permissions.has("view_proof_of_delivery") ?
								<Grid item xs="auto">
									<RequestProofofDeliveryButton
										buttonName={"Request Proof of Delivery"}
										buttonType={"button"}
										orderNumber={page_data.order.order_number}
										sellerID={seller}
										trackingNumber={page_data.shipping.tracking_number}
										courierService={page_data.shipping.courier_service}
										podRequestSent={page_data.deliveryProof.pod_request_sent}
									/>
								</Grid>
								:
								page_data.order.courier_status === "delivered" && page_data.shipping.tracking_url &&
								<Grid item xs="auto">
									<Button
										href={page_data.shipping.tracking_url}
										target="_blank"
										variant="outlined"
										color="secondary"
									>
										View Proof Of Delivery
									</Button>
								</Grid>
							}

							{page_data.shipping.tracking_number && page_data.shipping.tracking_url &&
								(userdata.permissions && userdata.permissions.has("create_order_claim")) &&
								<RaiseAClaimButton/>
							}

							<DownloadDocument
								name="Commercial Invoice"
								pageType="detail"
								id={page_data.order.order_id}
							/>

							<ViewOrderTimelineButton/>

						</>
						}

					</Grid>
				</Grid>
			</Box>
		);
	};

	const CancelOrderButton = () => {

		if (page_data.order.status === "cancelled") return;

		return (
			<LightTooltip
				title={"The order can't be cancelled anymore as it is already picked, packed, and ready for collection." +
					" Please advise your customer to refuse the parcel upon delivery and it will be returned to us free of charge."}
				disableHoverListener={enableCancelOrderButton}
				arrow
			>
				<Grid item xs="auto">
					<Button
						variant="contained"
						onClick={onClickCancelOrder}
						disabled={!enableCancelOrderButton}
						color="destructive"
						className="cancel-order-button"
					>
						Cancel Order
					</Button>
				</Grid>
			</LightTooltip>
		);
	};

	const SplitOrderButton = () => {

		let orderItemsAllocated = false;
		for (let item of page_data.product.items) {
			if (item.allocated > 0) {
				orderItemsAllocated = true;
				break;
			}
		}

		return (
			<>
				{page_data.order.status === "onbackorder" &&
					orderItemsAllocated &&
					<Grid item xs="auto">
						<Button
							variant="outlined"
							onClick={onClickSplitOrder}
							color="secondary"
						>
							Split Order
						</Button>
					</Grid>
				}
			</>
		);
	};

	const onClickSplitOrder = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Split Order",
				modalBodyMessage1: "Are you sure you'd like to split this order? This action cannot be undone.",
				modalBodyMessage1Data: [],
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Split Order ",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickSplitOrderModal,
				modalButtonColor2: state.modalButtonColor2,
				isModalOpen: true,
			};
		});
	};

	const listSplitOrderNumbers = (array) => {
		return (
			(typeof array === "object" && array.length > 0) ?

				(<ul>
					{array.map(orders => (
						<li key={orders} value={orders}>
							{orders}
						</li>
					))}
				</ul>)
				:
				(<p>No order numbers found</p>)
		);
	};

	const onClickSplitOrderModal = async () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "", // ensures 2nd button dosen't render in modal
			};
		});

		if (!seller) return;

		await context.dataProvider.splitOrder(seller, page_data.order.order_id)
			.then(response => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order successfully split",
						modalBodyMessage1: "This order has been split. The new order numbers are:",
						modalBodyMessage1Data: listSplitOrderNumbers(response.data["order_numbers"]),
						modalBodyMessage2: "Note: It can take a few minutes for these orders to appear in your order summary",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Order Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/order-status",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Split order unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try splitting the order again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Order Details",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const onClickCancelOrder = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Cancel Order",
				modalBodyMessage1: "Are you sure you'd like to cancel this order? This action cannot be undone.",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Cancel Order",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickCancelOrderModal,
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onClickCancelOrderModal = async () => {

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "", // ensures 2nd button dosen't render in modal
			};
		});

		if (!seller) return;

		await context.dataProvider.cancelOrder(seller, page_data.order.order_id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order successfully cancelled",
						modalBodyMessage1: "This order has been cancelled",
						modalBodyMessage2: "Note: It can take a few minutes for these changes to update in your order summary",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Order Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/order-status",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order cancellation unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try cancelling the order again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Order Details",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});

	};

	const onReleaseOrderOnHold = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Release Order On Pack & Hold",
				modalBodyMessage1: "Are you sure you'd like to release this order on pack and hold? This action cannot be undone.",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, release order",
				modalButtonActionType2: "function",
				modalButtonAction2: onReleaseOrderOnHoldModal,
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onReleaseOrderOnHoldModal = async () => {

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "",
			};
		});

		if (!seller || !page_data.order.order_id) return;

		await context.dataProvider.releaseOrder(seller, page_data.order.order_id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order successfully released",
						modalBodyMessage1: "This order has been released from pack and hold",
						modalBodyMessage2: "Note: It can take a few minutes for these changes to update in your order summary",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Order Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/order-status",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order release unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try cancelling the order again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Order Details",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});

	};

	const EditOrderButton = () => {

		if (page_data.order.status === "cancelled" || page_data.order.status === "uploadpending") {
			return "";
		}

		if (checkIfOrderSentToWarehouse(page_data.order.warehouse_creation_date) && page_data.order.status !== "held") {
			return (
				<Box>
					<LightTooltip
						title="You are unable to edit order as it is being processed by the warehouse. Please email support@bezos.ai in order to request changes to be made to this order."
						placement="left"
						arrow
					>
						<Grid item xs="auto">
							<Button
								variant="outlined"
								color="primary"
								disabled={true}
							>
								Edit Order
							</Button>
						</Grid>
					</LightTooltip>
				</Box>
			);
		}

		return (
			<Box>
				<Button
					variant="outlined"
					color="primary"
					component={RouterLink}
					to="/order-update"
					state={{
						existingOrder: results,
						existingOrderInvoiceDocuments: state.documentsInvoice,
						existingOrderShippingDocuments: state.documentsShipping,
						orderOperation: "edit"
					}}
				>
					Edit Order
				</Button>
			</Box>
		);

	};

	useEffect(() => {
		const getOrderDocumentsList = () => {

			if (!seller || !page_data.order.platform_order_id) return;

			setState(previousState => {
				return {
					...previousState,
					isLoaded: false
				};
			});

			context.dataProvider.getOrderDocuments(seller, page_data.order.platform_order_id)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							isLoaded: true,
							documents: res.data,
						};
					});

					const filterShippingDocuments = res.data.filter(documents => {
						return documents.type_id === 2;
					});
					setState(previousState => {
						return {
							...previousState,
							documentsShipping: filterShippingDocuments,
						};
					});

					const filterInvoiceDocuments = res.data.filter(documents => {
						return documents.type_id === 1 || documents.type_id === 3;
					});

					setState(previousState => {
						return {
							...previousState,
							documentsInvoice: filterInvoiceDocuments,
						};
					});

				});
		};
		getOrderDocumentsList();

	}, [context.dataProvider, page_data.order.platform_order_id, seller]);

	const BackButton = () => {
		return (
			<Button variant="contained" component={RouterLink} to="/order-status">
				Go Back
			</Button>
		);
	};

	const TitleHeading = () => {
		return (
			<Box>
				<Grid container spacing={1} mt={1}>
					<Grid item xs={12} container spacing={3} mb={2}>
						<Grid item xs="auto">
							<Typography component="h1" variant="h5">
								{pageName}
							</Typography>
						</Grid>
						<Grid item xs/>

						<Grid item xs="auto">

							<Grid item xs={6} md={12} lg={12} xl={12} container spacing={2}>

								{(userdata.permissions && userdata.permissions.has("edit_order")) &&
									<Grid item xs="auto">
										<EditOrderButton/>
									</Grid>
								}
								
								<Grid item xs="auto">
									<Button
										variant="outlined"
										color="primary"
										onClick={() => onClickMoreActionsButton()}
									>
										More Actions
									</Button>
								</Grid>

								{(userdata.permissions && userdata.permissions.has("edit_order_copy_split_cancel")) &&
									<Grid item xs="auto">
										<CancelOrderButton/>
									</Grid>
								}

							</Grid>

						</Grid>

					</Grid>
				</Grid>
			</Box>
		);
	};

	const Summary = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Summary
				</Typography>

				<Grid container spacing={1}>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold" }}>Order Status:</Box>
							<Box component="span">
								<StatusBox
									sx={{ ml: 1 }}
									status={page_data.order.status} statusType="order"
								/>
							</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold" }}>Courier Status:</Box>
							{page_data.order.courier_status &&
								<Box component="span">
									<StatusBox
										sx={{ ml: 1.5 }}
										status={page_data.order.courier_status} statusType="order"
									/>
								</Box>
							}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Order Date:</Box>
							<Box component="span">{getDisplayTime(page_data.order.bezos_created_date)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Courier Service:</Box>
							<Box component="span">{page_data.shipping.courier_service}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Source:</Box>
							<Box component="span">{page_data.order.source} {page_data.order.sales_channel_reference ?
								`(${page_data.order.sales_channel_reference})` : null}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Weight:</Box>
							<Box component="span">{roundToPrecision(page_data.shipping.weight)}KG</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Warehouse:</Box>
							<Box component="span">{page_data.order.warehouse_name}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Tracking Information:</Box>
							<CourierTracking
								onModalOpen={onModalOpen}
								isModalOpen={state.isModalOpen}
								urls={page_data.shipping.tracking_url}
								numbers={page_data.shipping.tracking_number}
								order={page_data.order.order_number}
							/>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Number Of Items:</Box>
							<Box component="span">{page_data.shipping.total_items}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Number Of Parcels:</Box>
							<Box component="span">{page_data.shipping.number_of_parcels}</Box>
						</Typography>
					</Grid>

					{page_data.order.created_by && <Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Created By:</Box>
							<Box component="span">{page_data.order.created_by}</Box>
						</Typography>
					</Grid>
					}

				</Grid>
			</Paper>
		);
	};

	const ProductsTableData = () => {
		if (typeof page_data.product.items === "object" && page_data.product.items !== null) {
			return (
				page_data.product.items.sort((b, a) => a.sku > b.sku ? -1 : 1).map((s) => <ItemDisplay
					key={s.order_item_id} item={s}
					itemType='order'
					permissions={permissions}/>)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="7" align="center">No items found</TableCell>
				</TableRow>
			);
		}
	};

	const ProductsTable = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>

				<Typography variant="h5" mt={2} mb={2} component="div">
					Products
				</Typography>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell align="left">SKU</TableCell>
								<TableCell align="left">Bundle ID</TableCell>
								<TableCell align="left">Name</TableCell>
								<TableCell align="left">Quantity</TableCell>
								<TableCell align="left">Allocated</TableCell>
								<TableCell align="left"></TableCell>
								{permissions.has("inventory-detail") &&
									<TableCell align="left"></TableCell>
								}
							</TableRow>
						</TableHead>
						<TableBody>
							{
								isLoading ?
									<LoadingStatusTable colSpan={7} message={"Loading..."}/>
									:
									<ProductsTableData/>
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};

	const Recipient = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 0 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Recipient
				</Typography>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>First Name:</Box>
						<Box component="span">{page_data.recipient.person.first_name}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Last Name:</Box>
						<Box component="span">{page_data.recipient.person.last_name}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Email:</Box>
						<Box component="span">{page_data.recipient.person.email}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto">
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Phone:</Box>
						<Box component="span">{page_data.recipient.person.phone}</Box>
					</Typography>
				</Grid>

			</Paper>
		);
	};

	const DeliveryAddress = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 0 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Delivery Address
				</Typography>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Address Line 1:</Box>
						<Box component="span">{page_data.recipient.address.address1}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Address Line 2:</Box>
						<Box component="span">{page_data.recipient.address.address2}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Address Line 3:</Box>
						<Box component="span">{page_data.recipient.address.address3}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Town:</Box>
						<Box component="span">{page_data.recipient.address.town}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>County:</Box>
						<Box component="span">{page_data.recipient.address.county}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Post Code:</Box>
						<Box component="span">{page_data.recipient.address.postcode}</Box>
					</Typography>
				</Grid>

				<Grid item xs="auto">
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Country:</Box>
						<Box component="span">{page_data.recipient.address.country}</Box>
					</Typography>
				</Grid>

			</Paper>
		);
	};

	const ShippingLabels = () => {

		if (!state.documentsShipping || !Array.isArray(state.documentsShipping) || state.documentsShipping.length === 0) return;

		return (
			<Paper sx={{ p: 2, width: "100%", mt: 2 }} elevation={2}>
				<UploadFile
					name="Shipping Label"
					type="shipping"
					fileTypes=".pdf"
					col1Title="Shipping Label"
					col2Title="Number of labels"
					tableData={state.documentsShipping}
					isLoaded={state.isLoaded}
					seller={seller}
					platformOrderID={page_data.order.platform_order_id}
					isPageInEditMode={false}
					pageType={"view"}
				/>
			</Paper>
		);
	};

	const Invoices = () => {

		if (!state.documentsInvoice || !Array.isArray(state.documentsInvoice) || state.documentsInvoice.length === 0) return;

		return (
			<Paper sx={{ p: 2, width: "100%", mt: 2 }} elevation={2}>
				<UploadFile
					name="Invoice"
					type="invoice"
					fileTypes=".pdf"
					col1Title="Invoice"
					col2Title="Commercial Invoice"
					tableData={state.documentsInvoice}
					isLoaded={state.isLoaded}
					seller={seller}
					platformOrderID={page_data.order.platform_order_id}
					isPageInEditMode={false}
					pageType={"view"}
				/>
			</Paper>
		);
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const onModalOpen = (modalData) => {
		setState(previousState => {
			return {
				...previousState,
				...modalData,
				isModalOpen: true,
			};
		});
	};

	return (
		<Box id="order-view">

			{isLoading &&
				(
					<Typography>
						Loading order status...
					</Typography>
				)
			}

			<SellerDependentView seller={seller}>
				<Box>
					{status === true ?
						<>
							<BreadCrumb 
								breadcrumbs={[
									{ label: "Orders", url: "/order-status" },
									{ label: page_data?.order?.order_number }
								]}
							/>
							<TitleHeading/>
							<MoreActionsDiv/>
							<GenerateAReturnLabelHiddenDiv/>
							<Summary/>
							<ProductsTable/>

							<Grid container spacing={2} sx={{ display: "flex" }}>
								<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
									<Recipient/>
								</Grid>
								<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
									<Box sx={{ flex: 1 }}>
										<DeliveryAddress/>
									</Box>
								</Grid>
							</Grid>

							<Grid container spacing={2} sx={{ display: "flex" }}>
								{(!state.documentsShipping || !Array.isArray(state.documentsShipping) || state.documentsShipping.length === 0) ?
									<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
										<Invoices/>
									</Grid>
									:
									<>
										<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
											<ShippingLabels/>
										</Grid>
										<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
											<Box sx={{ flex: 1 }}>
												<Invoices/>
											</Box>
										</Grid>
									</>
								}
							</Grid>
							<Modal
								onModalClose={onModalClose}
								isModalOpen={state.isModalOpen}
								modalBodyTitle={state.modalBodyTitle}
								modalBodyMessage1={state.modalBodyMessage1}
								modalBodyMessage1Data={state.modalBodyMessage1Data}
								modalBodyMessage2={state.modalBodyMessage2}
								modalButtonVariant1={state.modalButtonVariant1}
								modalButtonText1={state.modalButtonText1}
								modalButtonActionType1={state.modalButtonActionType1}
								modalButtonAction1={state.modalButtonAction1}
								modalButtonVariant2={state.modalButtonVariant2}
								modalButtonText2={state.modalButtonText2}
								modalButtonActionType2={state.modalButtonActionType2}
								modalButtonAction2={state.modalButtonAction2}
								modalButtonColor2={state.modalButtonColor2}
							/>
						</>
						:
						<>
							<Typography mb={2}>
								Invalid Order Number
							</Typography>
							<BackButton/>
						</>
					}
				</Box>
			</SellerDependentView>
		</Box>
	);
}
