import React, { useEffect } from "react";
import {
	Link as RouterLink,
	useLocation
} from "react-router-dom";
import "./LeftHandMenu.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Home from "@mui/icons-material/Home";
import Orders from "@mui/icons-material/ShoppingCart";
import Inventory from "@mui/icons-material/Warehouse";
import ASN from "@mui/icons-material/LocalShipping";
import Returns from "@mui/icons-material/ReplayCircleFilled";
import LateDeliveries from "@mui/icons-material/WatchLater";
import Claims from "@mui/icons-material/RuleFolder";
import Invoices from "@mui/icons-material/InsertDriveFile";
import Reports from "@mui/icons-material/AssignmentReturned";
import UploadInvoices from "@mui/icons-material/UploadFileRounded";
import Audit from "@mui/icons-material/FactCheck";
import Hub from "@mui/icons-material/DeviceHub";
import Debug from "@mui/icons-material/Adb";
import Settings from "@mui/icons-material/Settings";
import Help from "@mui/icons-material/Help";
import Ticket from "@mui/icons-material/SupportAgentSharp";
import MuiDrawer from "@mui/material/Drawer";
import {
	styled,
	useTheme
} from "@mui/material/styles";
import {
	IconButton,
	Divider,
	ListItem,
	ListItemButton,
	List,
	ListItemIcon,
	ListItemText,
	Typography,
	Link,
	Box
} from "@mui/material";
import LightTooltip from "../LightTooltip";
import { 
	getKeyValue,
	containsItemWithKeyValue,
	addId,
} from "../../../utils/object";

export default function LeftHandMenu({ onHandleDrawer, isDrawerVisible, drawerWidth, marginTop, userdata, isLeftMenuVisible }) {
	const to = useLocation().pathname;
	const theme = useTheme();

	const openedMixin = (theme) => ({
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: "hidden",
	});

	const closedMixin = (theme) => ({
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up("sm")]: {
			width: `calc(${theme.spacing(8)} + 1px)`,
		},
	});

	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	}));

	const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
		({ theme, open }) => ({
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
			boxSizing: "border-box",
			...(open && {
				...openedMixin(theme),
				"& .MuiDrawer-paper": openedMixin(theme),
			}),
			...(!open && {
				...closedMixin(theme),
				"& .MuiDrawer-paper": closedMixin(theme),
			}),
		}),
	);

	const handleDrawerClose = () => {
		onHandleDrawer(false);
	};

	const mainMenu = [
		{
			"title": "home",
			"label": "Home",
			"link": "/",
			"icon": <Home />,
			"target": "_self",
		},
		{
			"title": "order-status",
			"label": "Orders",
			"link": "/order-status",
			"icon": <Orders />,
			"target": "_self",
		},
		{
			"title": "order-create",
			"label": "Create Order",
			"link": "/order-create",
			"icon": <Orders />,
			"target": "_self",
			"type": "deepLink",
		},		
		{
			"title": "inventory",
			"label": "Inventory",
			"link": "/inventory",
			"icon": <Inventory />,
			"target": "_self",
		},
		{
			"title": "product-create",
			"label": "Create Product",
			"link": "/product-create",
			"icon": <Inventory />,
			"target": "_self",
			"type": "deepLink",
		},
		{
			"title": "product-update",
			"label": "Edit Product",
			"link": "/product-update",
			"icon": <Inventory />,
			"target": "_self",
			"type": "deepLink",
		},
		{
			"title": "inventory-upload",
			"label": "Product Upload",
			"link": "/inventory-upload",
			"icon": <Inventory />,
			"target": "_self",
			"type": "deepLink",
		},			
		{
			"title": "asn-summary",
			"label": "Goods In",
			"link": "/asn-summary",
			"icon": <ASN />,
			"target": "_self",
		},
		{
			"title": "asn-create",
			"label": "Create ASN",
			"link": "/asn-create",
			"icon": <ASN />,
			"target": "_self",
			"type": "deepLink",
		},		
		{
			"title": "returns",
			"label": "Returns",
			"link": "/returns",
			"icon": <Returns />,
			"target": "_self",
		},
		{
			"title": "late-deliveries",
			"label": "Late Deliveries",
			"link": "/late-deliveries",
			"icon": <LateDeliveries />,
			"target": "_self",
		},
		{
			"title": "claims",
			"label": "Claims",
			"link": "/claims",
			"icon": <Claims />,
			"target": "_self",
		},
		{
			"title": "invoice-view",
			"label": "Invoices",
			"link": "/invoice-view",
			"icon": <Invoices />,
			"target": "_self",
		},
		{
			"title": "reports",
			"label": "Reports",
			"link": "/reports",
			"icon": <Reports />,
			"target": "_self",
		},
		{
			"title": "invoice-upload",
			"label": "Upload Invoices",
			"link": "/invoice-upload",
			"icon": <UploadInvoices />,
			"target": "_self",
		},
		{
			"title": "audit-logs",
			"label": "Audit Logs",
			"link": "/audit-logs",
			"icon": <Audit />,
			"target": "_self",
		},
		{
			"title": "hub",
			"label": "Hub",
			"link": "/hub",
			"icon": <Hub color="error" />,
			"target": "_self",
		},			
		{
			"title": "debug",
			"label": "Debug",
			"link": "/debug",
			"icon": <Debug />,
			"target": "_self",
		},
		{
			"title": "settings",
			"label": "Settings",
			"link": "/settings",
			"icon": <Settings />,
			"target": "_self",
			"type": "support",
		},
		{
			"title": "help centre",
			"label": "Help centre",
			"link": process.env.REACT_APP_HELP_CENTRE,
			"icon": <Help />,
			"target": "_blank",
			"type": "support",
		},
		{
			"title": "raise a ticket",
			"label": "Raise a ticket",
			"link": process.env.REACT_APP_SUBMIT_TICKET,
			"icon": <Ticket />,
			"target": "_blank",
			"type": "support",
		},
	];

	const userIsAdmin = userdata.roles !== undefined && userdata.roles.find(r => r === "admin");
	const userIsSeller = userdata.roles !== undefined && userdata.roles.find(r => r === "seller");

	const mainMenuWithID = addId(mainMenu);
	const adminMenu = mainMenuWithID.filter(obj => obj.type !== "support" && obj.type !== "deepLink");
	const supportMenu = mainMenuWithID.filter(obj => obj.type === "support");
	const userMenu = new Set(userdata.menu_items);
	const sellerMenu = adminMenu.filter(obj => userMenu.has(obj.title));

	useEffect(() => {
		if (containsItemWithKeyValue(mainMenuWithID, "link", to)) {
			const pageTitle = getKeyValue(mainMenuWithID, "link", to, "label");
			document.title = pageTitle ? `${pageTitle} - Bezos Seller Portal` : "Bezos Seller Portal";
		}
	}, [to]);

	const ListBox = ({ list }) => {
		return (
			<List>
				{list.map((item) => {
					let listItemProps = {
						key: item.id,
						sx: { display: "block" },
						target: item.target,
					};

					if (item.link.includes("https")) {
						listItemProps.component = Link;
						listItemProps.href = `${item.link}`;
					} else {
						listItemProps.component = RouterLink;
						listItemProps.to = `${item.link}`;
					}

					return (
						<ListItem
							key="99"
							disablePadding
							{...listItemProps}
						>

							<ListItemButton
								sx={{
									justifyContent: isDrawerVisible ? "initial" : "center",
									px: 2.5,
									height: 30,
									boxSizing: "border-box"
								}}
								className={to === `${item.link}` ? "page-match" : null}
							>

								<LightTooltip
									title={item.label}
									placement="right-start"
									disableHoverListener={isDrawerVisible ? true : false}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: isDrawerVisible ? 3 : "auto",
											justifyContent: "center",
											color: to === `${item.link}` ? "#FFF" : "#666",
										}}
									>
										{item.icon}
									</ListItemIcon>
								</LightTooltip>

								<ListItemText
									primary={item.label}
									sx={{
										opacity: isDrawerVisible ? 1 : 0,
										color: to === `${item.link}` ? "#FFF" : "#666",
									}}
								/>

							</ListItemButton>

						</ListItem>

					);
				})}
			</List>
		);
	};

	const MainMenu = () => {
		let list;
		if (userIsAdmin) {
			list = adminMenu;
		} else {
			list = sellerMenu;
		}

		return (
			<>
				<ListBox list={list} />
			</>
		);
	};

	const SupportMenu = () => {
		if (userIsAdmin || userIsSeller) {
			return (
				<>
					<Divider sx={{ mt: 1, mb: 1 }} />
					<ListBox list={supportMenu} />
				</>
			);
		}
	};

	return (
		<Box id="side-nav">
			{isLeftMenuVisible &&
				<Drawer variant="permanent" open={isDrawerVisible}>
					<DrawerHeader className="side-nav-header" sx={{ marginTop: marginTop }}>
						<Typography sx={{ ml: 2 }}>Navigation</Typography>
						<IconButton onClick={handleDrawerClose} size="large">
							{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>
					</DrawerHeader>
					<MainMenu />
					<SupportMenu />
				</Drawer>
			}
		</Box>

	);

}