import React, {
	useRef,
	useState
} from "react";
import { createDownloadLink } from "../../../utils/downloadFile";
import {
	Box,
	Button,
	Grid,
	Paper,
	Typography
} from "@mui/material";
import { DataContext } from "../../../App";

export default function ImportItems({ seller, inventory, onASNImportData, onASNImportStatus }) {

	const context = React.useContext(DataContext);

	const uploadInputRef = useRef();
	const fileReader = new FileReader();

	const [state, setState] = useState({
		isUploadFileAdded: false,
		isUploadButtonEnabled: false,
		uploadFile: "",
	});

	const onClickDownloadTemplate = async () => {
		const filename = "import_template.csv";
		await createDownloadLink(filename, context.dataProvider.getSellerASNTemplate(seller));
	};

	const onChangeGetUploadFile = (e) => {
		e.persist();

		setState(previousState => {
			return {
				...previousState,
				uploadFile: e.target.files[0],
				isUploadFileAdded: true,
				isUploadButtonEnabled: true,
				parsedFile: [],
			};
		});
	};

	const onClickUploadFile = () => {

		if (state.uploadFile) {

			fileReader.onload = function (e) {
				const text = e.target.result;
				parseCsvFile(text);
			};

			fileReader.readAsText(state.uploadFile);
		}

	};

	const parseCsvFile = string => {

		const csvHeader = ["sku", "name", "quantity"];
		const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
		const array = csvRows.map(i => {
			const values = i.split(",");
			const obj = csvHeader.reduce((object, header, index) => {
				let value = values[index];

				if (values.length !== 3) {
					return object;
				}
				
				switch(header) {
				case "sku":
				case "name":
					value = value.replace(/^"|"$/g, "");
					break;
				case "quantity":
					value = value.replace(/\D/g, "");
					break;
				}

				object[header] = value;
				return object;
			}, {});
			
			return obj;
		});

		const getItemsWithNumericIds = array.map(item => (
			{
				...item,
				quantity: Number(item.quantity),
			}
		));

		const validateCsvFile = (items, invalidItems) => {
			if (invalidItems.size > 0) {
				const skuString = Array.from(invalidItems.keys()).toString();
				onASNImportStatus(false, "The following product SKUs could not be found: " + skuString +
					". Please check your file and try again.");
				onASNImportData(items);
			} else if (items.length > 0) {
				onASNImportStatus(true);
				onASNImportData(items);
			} else {
				onASNImportStatus(false, "Import file error, please check your file and try again.");
				onASNImportData(items);
			}
		};

		const cleanseCsvFile = (items) => {
			const itemMap = new Map();
			const invalidItems = new Map();

			items.forEach(item => {
				if (Number.isNaN(item.quantity) === true ||
					item.quantity === 0 ||
					item.sku === "") {
					return;
				}

				const product = inventory.resultsData.find(obj => obj.sku === item.sku);
				if (!product) {
					invalidItems.set(item.sku, {});
					return;
				}

				if (itemMap.has(item.sku)) {
					item.quantity = itemMap.get(item.sku).quantity + item.quantity;
				}

				itemMap.set(item.sku, item);
			});
			items = Array.from(itemMap.values());

			return (
				validateCsvFile(items, invalidItems)
			);
		};
		cleanseCsvFile(getItemsWithNumericIds);

		setState(previousState => {
			return {
				...previousState,
				uploadFile: "",
				isUploadFileAdded: false,
				isUploadButtonEnabled: false
			};
		});
		uploadInputRef.current.value = "";

	};

	return (
		<>
			<Grid item>
				<Box mt={0} mb={3}>

					<Grid container spacing={1}>

						<Grid item xs={12} md={6} lg={6}>
							<Paper sx={{ p: 2, width: "100%", minHeight: 240 }} elevation={2} className="resize-box-asn">
								<Box mb={2}>
									<Typography component="h3" variant="h6" mb={2}>
										Download an ASN template
									</Typography>
								</Box>
								<Box>
									<Button
										variant="contained"
										onClick={() => onClickDownloadTemplate()}
									>
										Download
									</Button>
								</Box>
							</Paper>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<Paper sx={{ p: 2, width: "100%", minHeight: 240 }} elevation={2} className="resize-box-asn">
								<Box mb={2}>
									<Typography component="h3" variant="h6" mb={2}>
										Upload an ASN template
									</Typography>
								</Box>
								<Box mb={3}>
									<input
										type="file"
										name="File Upload"
										accept='text/csv'
										ref={uploadInputRef}
										onChange={(e) => onChangeGetUploadFile(e)}
									/>
								</Box>

								<Box>
									<Button
										variant="contained"
										component="span"
										onClick={(e) => onClickUploadFile(e)}
										disabled={!state.isUploadButtonEnabled}
									>
										Upload
									</Button>
									<Typography mt={2} sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>
										Only upload CSV files
									</Typography>
								</Box>

							</Paper>
						</Grid>

					</Grid>

				</Box>
			</Grid>

		</>
	);
}
