import React from "react";
import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@mui/material";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/text";

export default function MultiSelect({
	select_label,
	options,
	selected,
	onChange,
	disabled,
	sx,
	valueKey,
}) {

	const handleChange = (e) => {
		const value = e.target.value;
		onChange(value);
	};

	const menuProps = {
		PaperProps: {
			style: {
				maxHeight: 300,
				width: 250
			}
		},
		variant: "menu",
	};

	return (
		<FormControl sx={{ ...sx, mb: "1rem", backgroundColor: theme => theme.palette.statusbox.main }} className="status-select">
			<InputLabel>{select_label}</InputLabel>
			<Select
				label={select_label}
				multiple
				disabled={disabled}
				onChange={handleChange}
				renderValue={(selected) => selected.length + " selected"}
				value={selected}
				MenuProps={menuProps}
				size="small"
			>
				{options.map(option => (
					<MenuItem key={option[valueKey]} value={option[valueKey]} sx={{ whiteSpace: "normal" }}>
						<Checkbox checked={selected.indexOf(option[valueKey]) > -1} />
						<ListItemText primary={capitalizeFirstLetterOfEachWord(option.label ?? option.name)} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
