import { capitalizeFirstLetter } from "./text";

export const GetModalValuesFromBulkResponse = (res, options) => {
	let newModalMessage = "";
	let newMessage1List = [];

	if (!res.data.errors && res.data.message) {
		// a status message returned in a single 'message' field
		newModalMessage = capitalizeFirstLetter(res.data.message);
	} else if (res.data.errors && res.data.successes) {
		const successCount = res.data.successes.length;
		const errorCount = res.data.errors.length;

		if (errorCount > 0) {
			const failedList = res.data.errors.map((s) => capitalizeFirstLetter(s));

			newModalMessage = `Unable to ${options.operationName}: ${options.successfulOperation}: ${successCount}, Failed: ${errorCount}.`;
			newMessage1List = failedList;
		} else if (successCount > 0) {
			newModalMessage = options.success;
		} else {
			newModalMessage = "Nothing was updated";
		}
	} else {
		// this is the remaining case where an error is returned directly in the body, not
		// wrapped via JSON
		newModalMessage = capitalizeFirstLetter(res.data);
	}

	return {
		modalBodyMessage1: newModalMessage,
		modalBodyMessage1List: newMessage1List,
		modalBodyTitle: `Bulk Action: ${capitalizeFirstLetter(options.operationName)}`,
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalBodyMessage1Data: [],
		modalBodyInputFields: {},
		modalButtonVariant2: "contained",
		modalButtonText2: "Close",
		modalButtonActionType2: "function",
		modalButtonAction2: options.onClickCloseButton,
		modalButtonColor2: "primary",
		isModalOpen: true,
	};
};
