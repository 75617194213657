const DEFAULT_PRECISION = 2;

export const formatNumber = (input) => {
	if (typeof input !== "number") return input;

	return input.toLocaleString();
};

export const roundToPrecision = (input, precision) => {
	if (input === 0) return 0;
	if (typeof input !== "number" || isNaN(input)) return input;
	if (typeof precision !== "number" || isNaN(precision) || precision < 0) {
		precision = DEFAULT_PRECISION;
	}

	if (Number.isInteger(input)) {
		return input;
	}

	const roundedValue = Number(input.toFixed(precision));
	return roundedValue;
};
