import React from "react";
import {
	Box,
	Typography,
	Paper,
	Link,
} from "@mui/material";
import { DownloadButton } from "../../Shared/Buttons/Buttons";
import { DataContext } from "../../../App";
import { getBlobFile } from "../../../utils/downloadFile";

export default function DownloadATemplatePanel() {

	const context = React.useContext(DataContext);

	const onClickDownloadStockUploadTemplate = () => {
		getBlobFile(context.dataProvider.getStockUploadTemplate());
	};

	return (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>

			<Box>
				<Typography variant="h5" component="h2" mb={2}>
					Download Product Template
				</Typography>
			</Box>

			<Box mb={0}>
				<Typography component="p">
					Please view the help centre article <Link href={process.env.REACT_APP_HELP_CENTRE_PRODUCT_IMPORT} target="_blank" rel="noreferrer">here</Link> first, download the product template, and once filled out it can be uploaded as a CSV to import the products.
				</Typography>
			</Box>
			<Box mt={2} mb={2}>
				<DownloadButton
					onClick={() => onClickDownloadStockUploadTemplate()}
					icon={false}
					text={"Download Template"}
				/>
			</Box>

		</Paper>
	);

}
