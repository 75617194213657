import React, {
	useState,
	useEffect,
} from "react";
import "./ReportsCreate.scss";
import {
	Box,
	Button,
	Grid,
	Snackbar,
	Typography,
	Paper,
	FormControl,
	Stack,
	Link,
	Tab,
} from "@mui/material";
import { 
	TabContext,
	TabList,
	TabPanel
} from "@mui/lab";
import SellerDependentView from "../Shared/SellerDependentView";
import { getReportTypeSelectLabels } from "../../utils/reports.js";
import {
	StartDatePicker,
	EndDatePicker
} from "../Shared/DatePicker";
import {
	getCurrentDateTruncated,
	getUTCDatetime
} from "../../utils/date.js";
import ReportsTable from "./components/ReportsTable";
import { DataContext } from "../../App";
import { AlertBox } from "../Shared/Alert/Alert";
import { ScheduledReports } from "./components/ScheduledReports";
import { capitalizeFirstLetter } from "../../utils/text";
import {
	Form,
	Formik
} from "formik";
import * as Yup from "yup";
import DropDown from "../Shared/DropDown";

export default function ReportsCreate({ seller }) {

	const helpCentreLink = process.env.REACT_APP_REPORTS;
	const context = React.useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 365);
	const [state, setState] = useState({
		activeTab: "tab_1",
		reportTypes: [],
		updateTable: false,
		showAlert: false,
		alertMessage: "",
		alertSeverity: "",
	});

	useEffect(() => {
		const getReportTypes = async () => {
			try {
				const response = await context.dataProvider.getReportTypes();
				setState(previousState => {
					return {
						...previousState,
						reportTypes: response.data,
						showAlert: false,
					};
				});
			} catch (err) {
				setState(previousState => {
					return {
						...previousState,
						reportTypes: [],
						showAlert: true,
						alertSeverity: "error",
						alertMessage: capitalizeFirstLetter(err),
					};
				});
			}
		};
		getReportTypes();
	}, [seller]);

	const INITIAL_FORM_STATE = {
		report_type: "",
		start_date: initStartDate,
		end_date: initEndDate,
	};

	const FORM_VALIDATION = Yup.object().shape({
		report_type: Yup.number()
			.required("Please select report type."),
		start_date: Yup.date()
			.required("Please select a start date"),
		end_date: Yup.date()
			.required("Please select an end date"),
	});

	const HelpCentre = () => (
		<Grid item mt={3} mb={1}>
			<Box>
				<Typography variant="p">
					Visit the <Link href={helpCentreLink} target="_blank" rel="noreferrer">Help Centre</Link> to get a description of each of the reports and the column details.
				</Typography>
			</Box>
		</Grid>
	);

	const ReportType = ({ props }) => {
		return (
			<>
				<Box>
					<Typography component="p" sx={{ fontWeight: "bold" }}>
                        Which report would you like to see?
					</Typography>
				</Box>
				<Box>
					<FormControl sx={{ minWidth: 250 }}>
						<DropDown
							id="report_type"
							name="report_type"
							label="Select Report Type"
							select={true}
							value={props.values.report_type}
							data={state.reportTypes.map((d) => ({
								key: d.id,
								value: d.id,
								name: getReportTypeSelectLabels(d.name),
							}))}
							required={true}
						/>
					</FormControl>
				</Box>
			</>
		);
	};

	const DateRange = ({ values, setFieldValue }) => {
		return (
			<Stack direction={{ xs: "column", sm: "row" }} sx={{ mt: 2 }}>
				<StartDatePicker 
					onChange={(date) => setFieldValue("start_date", date)} 
					date={values.start_date} 
					size="small" 
				/>
				<Typography sx={{ mr: 2, mb: 2 }}>to</Typography>
				<EndDatePicker 
					onChange={(date) => setFieldValue("end_date", date)} 
					date={values.end_date} 
					size="small" 
				/>
			</Stack>
		);
	};
	
	const createReport = async (type, form) => {
		if (!seller || !type) return;
		if (!form && Object.keys(form).length === 0) return;

		let errorMessage = "";

		if (type === "OneTime") {
			// Handle date picker inclusivity to end of day by adding a whole
			// day and subtracting one time unit
			let requestStartDate = getUTCDatetime(form.start_date);
			let requestEndDate = getUTCDatetime(form.end_date);

			requestEndDate.setDate(requestEndDate.getDate() + 1);
			requestEndDate = new Date(requestEndDate - 1);

			try {
				await context.dataProvider.createReport(seller, form.report_type, requestStartDate, requestEndDate);
			} catch(err) {
				errorMessage = err.response.data || err.message;
			}
		}

		if (type === "Scheduled") {
			const { report_type, report_frequency, report_day_of_week, report_date_of_month, report_email } = form;
			let day = report_day_of_week;
			if (report_frequency === 3) day = report_date_of_month;
			
			try {
				await context.dataProvider.createScheduledReport(seller, report_type, report_frequency, day, report_email);
			} catch(err) {
				errorMessage = err.message;
			}
		}
		
		if (errorMessage === "") {
			setState(previousState => ({
				...previousState,
				alertSeverity: "success",
				alertMessage: `${type} Report creation request has been queued successfully`,
			}));
		} else {
			setState(previousState => ({
				...previousState,
				alertSeverity: "error",
				alertMessage: capitalizeFirstLetter(errorMessage),
			}));
		}

		setState(previousState => ({
			...previousState,
			showAlert: true,
			updateTable: true,
		}));
	};

	const getScheduledReport = async (form) => {
		createReport("Scheduled", form);
	};

	const onAlertHandleClose = (reason) => {
		if (reason === "clickaway") return;

		setState(previousState => {
			return {
				...previousState,
				showAlert: false,
			};
		});
	};

	const ReportsForm = () => {

		const getDateRangeAllowed = (reportTypeId) => {
			const reportType = state.reportTypes.find(rt => rt.id === reportTypeId);
			return reportType ? reportType.date_range_allowed : false;
		};

		return (
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE,
				}}
				enableReinitialize
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => createReport("OneTime", values)}
			>
				{({ values, touched, handleBlur, handleChange, setFieldValue, errors }) => {
					const isDateRangeAllowed = getDateRangeAllowed(values.report_type);
					const hasErrors = Object.keys(errors).length > 0;
					return (
						<Form noValidate autoComplete="off">
							<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
								<Box>
									<Grid container display="grid" justifyContent="left">
										
										<ReportType
											props={{ values, touched, handleBlur, handleChange }}
										/>

										{isDateRangeAllowed && (
											<DateRange
												values={values}
												setFieldValue={setFieldValue}
											/>
										)}
								
										<Grid item mt={2}>
											<Button
												variant="contained"
												type="submit"
												disabled={!values.report_type || hasErrors}
											>
												Create One-Time Report
											</Button>
										</Grid>

										<HelpCentre/>

									</Grid>
								</Box>
							</Paper>
						</Form>
					);
				}}
			</Formik>

		);
	};

	const onChangeTab = (event, tab) => {
		setState(previousState => {
			return {
				...previousState,
				activeTab: tab
			};
		});
	};

	const TabPanels = () => {
		return (
			<TabContext value={state.activeTab}>
				<Box sx={{ mb: 2 }}>
					<TabList onChange={onChangeTab} aria-label="reports tab panel" sx={{ m:0, p:0}}>
						<Tab label="One-Time Reports" value="tab_1" sx={{ fontSize: "1.5rem", textTransform: "capitalize", p:0, mt:0, mb:0, ml:0, mr:2 }} />
						<Tab label="Scheduled Reports" value="tab_2" sx={{ fontSize: "1.5rem", textTransform: "capitalize", p:0, mt:0, mb:0, ml:0, mr:2 }} />
					</TabList>
				</Box>
				<TabPanel value="tab_1" sx={{ m:0, p:0}}>
					<ReportsForm />
					<ReportsTable 
						seller={seller} 
						updateTable={state.updateTable} 
					/>						
				</TabPanel>
				<TabPanel value="tab_2" sx={{ m:0, p:0}}>
					<ScheduledReports
						reportTypes={state.reportTypes}
						seller={seller}
						updateTable={state.updateTable}
						getScheduledReport={getScheduledReport}
						helpCentre={<HelpCentre/>}
					/>
				</TabPanel>
			</TabContext>
		);
	};

	return (
		<Box id="reports-create">
			<Grid container>
				<SellerDependentView seller={seller}>
					<Grid item xs={12} lg={12} mb={4}>
						<TabPanels/>
					</Grid>
					<Grid item xs={12} lg={12} mb={4}>
						<Snackbar open={state.showAlert} autoHideDuration={8000} onClose={onAlertHandleClose} className="table-status" mb={2}>
							<Box>
								<AlertBox severity={state.alertSeverity} message={state.alertMessage} />
							</Box>
						</Snackbar>
					</Grid>
				</SellerDependentView>
			</Grid>
		</Box>
	);
}
