export const getASNDeliverySlotStatus = (obj) => {

	if (obj.delivery_slot_requests) {
		switch (obj.delivery_slot_requests[0].status) {
		case "pending":
			return "Pending";
		case "sent":
			return "Requested";
		case "approved":
			return "Approved";
		}
	}

	if (obj.delivery_slot_required) return "Required";
	return "N/A";

};
