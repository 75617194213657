import React, {
	useState,
	useRef,
	useCallback
} from "react";
import {
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	Grid,
	Dialog,
	DialogContent,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import ModalTable from "../Modals/Modal";
import { LoadingStatusTable } from "../LoadingStatus/LoadingStatus";
import "./UploadFile.scss";
import { DataContext } from "../../../App";
import { createDownloadLink } from "../../../utils/downloadFile";
import DropDown from "../DropDown/DropDown";
import useFetchData from "../FetchData/FetchData";
import {
	Form,
	Formik
} from "formik";
import * as Yup from "yup";
import { TextfieldFormik } from "../../Shared/TextField/TextField";
import { RadioGroupFormik } from "../../Shared/RadioGroup/RadioGroup";
import { AlertBox } from "../Alert/Alert";

export const UploadFile = ({
	name, type, fileTypes,
	col1Title, col2Title, tableData,
	onShippingUpdate, onInvoiceUpdate, onDeleteDocument,
	isLoaded, seller, platformOrderID,
	isPageInEditMode, pageType
}) => {
	
	const context = React.useContext(DataContext);
	const uploadInputRef = useRef([]);

	const [state, setState] = useState({
		isModalInlineOpen: false,
		ModalInlineError: "",
		isModalOpen: false,
		modalBodyTitle: "",
		modalShowFileUpload: false,
		modalFileUploadType: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalBodyInputComponent: null,
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		isLoaded: isLoaded || true,
		formValues: []
	});

	const documentSize = useFetchData(useCallback(() => context.dataProvider.getDocumentSizes(), [context.dataProvider]));
	const page_data = {
		documentSize: {
			resultsData: documentSize.results,
			status: documentSize.status,
			isLoading: documentSize.isLoading,
		},
	};

	const INITIAL_FORM_STATE = {
		number_of_labels: state.formValues.number_of_labels || "",
		document_size: state.formValues.document_size || "",
		invoice_type: state.formValues.invoice_type || "",
		file_upload: [],
	};

	const FORM_VALIDATION = Yup.object().shape(
		type === "shipping" ? {
			number_of_labels: Yup.number()
				.required("Quantity required")
				.moreThan(0, "Quantity must be greater than 0"),
			document_size: Yup.mixed()
				.required("Document size required"),
		} : {
			invoice_type: Yup.mixed()
				.required("Please confirm invoice type"),
		},
	);

	const onClickToggleAddDocumentBody = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalInlineOpen: true,
			};
		});
	};

	const InvoiceInput = ({ props }) => {
		return (
			<>
				<Box sx={{ mt: 3 }}>
					<RadioGroupFormik
						label="Is this a commercial invoice?"
						name="invoice_type"
						options={["yes", "no"]}
						labelPlacement="start"
						defaultValue={state.formValues.invoice_type}
						onChange={()=>{}}
						props={props}
					/>
				</Box>
			</>
		);
	};
	const isCommercial = (props) => {
		if (props === 1) {
			return "Yes";
		}
		return "No";
	};

	const ShippingLabelInput = ({ props }) => {
		return (
			<>
				<Box>
					<Typography variant="p" sx={{ mb: 3 }}>
						Please provide the number of labels in this document:
					</Typography>
				</Box>

				<Grid container mt={2} mb={1}>

					<Grid item sm={6}>
						<TextfieldFormik
							name="number_of_labels"
							label="Number of labels"
							type="number"
							size="small"
							sx={{ margin: 0, padding: 0, maxWidth: "210px" }}
							required
						/>
					</Grid>

					<Grid item sm={6}>
						<DropDown
							id="document_size"
							name="document_size"
							label="Document Size"
							sx={{ margin: 0, padding: 0, maxWidth: "190px" }}
							select={true}
							value={props.values.document_size}
							data={Object.keys(page_data.documentSize.resultsData).map((key, value) => ({
								key: key,
								value: `${(key)} ${page_data.documentSize.resultsData[value]}`,
								name: page_data.documentSize.resultsData[value]
							}))}
							required={true}
						/>
					</Grid>

				</Grid>
			</>
		);
	};

	const onClickDownloadDocument = (documentID, filename) => {
		const dataProvider = context.dataProvider.downloadOrderDocument(seller, platformOrderID, documentID);
		createDownloadLink(filename, dataProvider);
	};

	const AddDocumentBody = () => {
		return (
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values, errors) => onSubmitModal(values, errors)}
			>
				{({ errors, values, touched, handleBlur, handleChange }) => (
					<Form
						noValidate
						autoComplete="off"
					>
						<Box>
							<Typography variant="h5" sx={{ mb: 3 }}>
								Add {name}
							</Typography>
							<Box mb={3}>
								{type === "shipping" ?
									<ShippingLabelInput props={{ errors, values, touched, handleBlur, handleChange }} />
									:
									<InvoiceInput props={{ errors, values, touched, handleBlur, handleChange }} />
								}
							</Box>
							<Box mb={3}>
								<input
									type="file"
									name="file_upload"
									accept={fileTypes}
									ref={uploadInputRef}
								/>
							</Box>
							<Grid
								container
								justifyContent="center"
								sx={{ mt: 2 }}
							>
								<Grid item>
									<Button
										variant="outlined"
										onClick={handleCloseInline}
										sx={{ marginRight: 2 }}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										type="submit"
									>
										Add {(type === "shipping") ? "Label" : "Invoice"}
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Form>
				)}
			</Formik>
		);
	};

	const handleCloseInline = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalInlineOpen: false,
				ModalInlineError: "",
				formValues: []
			};
		});
	};

	const ModalInline = () => {
		return (
			<Dialog
				open={state.isModalInlineOpen}
				onClose={handleCloseInline}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
				fullWidth={true}
				maxWidth="sm"
				className="inline-modal"
			>
				<DialogContent>
					<AddDocumentBody />

					{state.ModalInlineError &&
						<AlertBox severity="error" sx={{ mt: 3 }} message={state.ModalInlineError}/>
					}

				</DialogContent>
			</Dialog>
		);
	};

	const AddDocumentHeading = () => {
		return (
			<>
				<Grid item xs={12} align="left" mt={3}>
					<Box>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => onClickToggleAddDocumentBody()}
						>
							Add {name} <AddIcon />
						</Button>
					</Box>
				</Grid>
			</>
		);
	};

	const onSubmitModal = (values) => {

		if (uploadInputRef.current.files.length === 0) {
			setState(previousState => {
				return {
					...previousState,
					formValues: values,
					ModalInlineError: "Please upload a file",
				};
			});
			return;
		}

		if (type === "shipping") {

			const documentSizeArray = values.document_size.split(" ", 2);
			const newDocument = {
				"id": Math.random(),
				"content": uploadInputRef.current.files,
				"filename": uploadInputRef.current.files[0].name,
				"document_count": Number(values.number_of_labels),
				"document_size": documentSizeArray[1],
				"paper_size_id": Number(documentSizeArray[0]),
				"type_id": 2,
				"type": "shipping",
			};
			onShippingUpdate(newDocument);
			handleCloseInline();
			return;
		}

		const newDocument = {
			"id": Math.random(),
			"content": uploadInputRef.current.files,
			"filename": uploadInputRef.current.files[0].name,
			"document_count": 1,
			"document_size": "",
			"paper_size_id": 0,
			"type_id": values.invoice_type === "yes" ? 1 : 3,
			"type": "invoice",
		};
		onInvoiceUpdate(newDocument);
		handleCloseInline();


	};

	const DocumentsTableData = () => {
		if (tableData !== null) {
			return (
				tableData.map((f, i) =>
					<TableRow key={i}>
						<TableCell>
							{f.filename}
						</TableCell>
						<TableCell className="capitalize-word">
							{f.type_id === 2 ? f.document_count : isCommercial(f.type_id)}
						</TableCell>
						{pageType === "view" &&
							<TableCell align="right">
								<Button
									title="Download"
									onClick={() => onClickDownloadDocument(f.platform_id, f.filename)}
									variant="contained"
									className="download-button"
								>
									<FileDownloadIcon />
								</Button>
							</TableCell>
						}
						{isPageInEditMode &&
							<TableCell align="right">
								<DeleteIcon
									title="Delete File"
									onClick={() => onClickDeleteDocumentModal(f.id, f.type_id)}
									className="delete-button"
								/>
							</TableCell>
						}
					</TableRow>
				));
		} else {
			return "";
		}
	};
	const DocumentsTableMain = () => {
		return (
			<>
				<Typography variant="h5" gutterBottom component="div" mt={2}>
					{name}s
				</Typography>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto", mt: 2, paddingBottom: 1 }}>
					<Table
						aria-label="documents table"
						className="documents-table"
						stickyHeader
					>
						<TableHead>
							<TableRow>
								<TableCell align="left">{col1Title}</TableCell>
								<TableCell align="left">{col2Title}</TableCell>
								<TableCell align="right" colSpan={2}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(!state.isLoaded) ?
								<LoadingStatusTable colSpan={2} message="Loading..." />
								:
								<DocumentsTableData />
							}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const onClickDeleteDocument = (id, type_id) => {
		onDeleteDocument(id, type_id);
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};
	const onClickDeleteDocumentModal = (id, type_id) => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Delete File?",
				modalBodyInputComponent: null,
				modalShowFileUpload: false,
				modalFileUploadType: "",
				modalBodyMessage1: "Are you sure you want to delete this file?",
				modalBodyMessage1Data: [],
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Delete File",
				modalButtonActionType2: "function",
				modalButtonAction2: () => onClickDeleteDocument(id, type_id),
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props
			};
		});
	};

	return (
		<Box className="upload-file">
			{isPageInEditMode && <AddDocumentHeading />}
			{tableData.length > 0 ? <DocumentsTableMain /> : null}
			<ModalInline />
			<ModalTable
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalShowFileUpload={state.modalShowFileUpload}
				modalFileUploadType={state.modalFileUploadType}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalBodyInputFields={state.modalBodyInputFields}
				modalBodyInputComponent={state.modalBodyInputComponent}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
			/>
		</Box>
	);

};