import React, {
	useState,
	useEffect
} from "react";
import Cookies from "js-cookie";
import "./AlertBar.scss";
import sanitizeHtml from "sanitize-html";
import {
	Typography,
	Button,
	Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/HighlightOffSharp";
import { useDispatch } from "react-redux";
import { setIsAlertBarVisible } from "../../../store/rootSlice";

export default function AlertBar({ bannerdata }) {

	const dispatch = useDispatch();
	const [state, setState] = useState({
		banner: "",
		bannerID: "",
		bannerMessage: "",
		isLoading: false,
	});

	const cookieName = "banner";
	const checkIfCookieExists = (cookie) => {
		if (Cookies.get(cookie) !== undefined) {
			return true;
		}
		return false;
	};

	useEffect(() => {

		if (bannerdata[0] === undefined) {
			return;
		}

		setState(previousState => {
			return {
				...previousState,
				banner: bannerdata[0],
				bannerID: bannerdata[0].id,
				isLoading: true,
			};
		});

		const checkIfCookieContainsID = (name, id) => {

			if (name === undefined || id === undefined) {
				return;
			}

			if (checkIfCookieExists(cookieName) === false) {
				dispatch(setIsAlertBarVisible(true));
				setState(previousState => {
					return {
						...previousState,
						bannerMessage: bannerdata[0].banner_text,
						isLoading: false,
					};
				});

				return;
			}

			const cookieArray = JSON.parse(Cookies.get(name));

			if (cookieArray === undefined || id === "") {
				return;
			}

			const cookieContainsID = cookieArray.includes(id);
			if (cookieContainsID === false) {
				dispatch(setIsAlertBarVisible(true));
				setState(previousState => {
					return {
						...previousState,
						bannerMessage: bannerdata[0].banner_text,
						isLoading: false,
					};
				});

				return;
			}

		};
		checkIfCookieContainsID(cookieName, state.bannerID);

	}, [bannerdata, state.banner, state.bannerID, dispatch]);

	const onClickDismissMessage = (cookie) => {

		if (checkIfCookieExists(cookie) === false) {
			Cookies.set(cookie, JSON.stringify([state.bannerID]), { expires: 365, path: "/", sameSite: "strict" });
		} else {
			let currentIDArray = JSON.parse(Cookies.get(cookie));
			currentIDArray = [...currentIDArray, state.bannerID];
			Cookies.set(cookie, JSON.stringify(currentIDArray), { expires: 365, path: "/", sameSite: "strict" });
		}

		dispatch(setIsAlertBarVisible(false));
		setState(previousState => {
			return {
				...previousState,
				banner: "",
				bannerID: "",
				bannerMessage: "",
				isLoading: false,
			};
		});

	};

	return (
		<Box
			className="alert-bar"
			data-testid="alert-bar"
		>

			{state.bannerMessage && <Box
				className="alert-box"
				data-testid="alert-box"
			>
				<Typography
					component='p'
					dangerouslySetInnerHTML={{ __html: sanitizeHtml(state.bannerMessage) }}
				/>
				<Button
					variant="text"
					sx={{ color: "#ccc", marginLeft: "1rem" }}
					onClick={() => onClickDismissMessage(cookieName)}
					title="Dismiss Message"
				>
					<CloseIcon />
				</Button>
			</Box>
			}

		</Box>
	);

}
