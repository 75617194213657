import React from "react";
import {
	Button,
	Avatar,
	Link,
} from "@mui/material";

export const Logo = () => {
	return (
		<>
			<Button
				color="inherit"
				component={Link}
				href={"/"}
				sx={{ border: "none", margin: 0, padding: 0 }}
			>
				<Avatar
					src={"/logo/bezos-logo-inline.svg"}
					alt="Bezos Seller Portal"
					title="Bezos Seller Portal"
					variant="square"
					sx={{ width: "140px" }}
				>
				</Avatar>
			</Button>        
		</>
	);
};
