import React, {
	useState,
	useCallback
} from "react";
import {
	Box,
	Typography,
	Paper,
	FormControl,
	MenuItem,
	TextField,
	Link,
} from "@mui/material";
import UseFetchData from "../../Shared/FetchData/FetchData";
import { getBlobFile } from "../../../utils/downloadFile";
import { DataContext } from "../../../App";
import { DownloadButton } from "../../Shared/Buttons/Buttons";

export default function DownloadATemplatePanel() {

	const [state, setState] = useState({
		enableDownloadTemplateButton: false,
		selectedTemplateFilename: "",
	});

	const helpCentreLink = process.env.REACT_APP_ORDER_TEMPLATE;
	const context = React.useContext(DataContext);
	const templateTypeSelectAPI = UseFetchData(useCallback(() => context.dataProvider.getTemplates(), [context.dataProvider]));
	const templateTypeSelectObj = {
		results: templateTypeSelectAPI.results,
		status: templateTypeSelectAPI.status,
		isLoading: templateTypeSelectAPI.isLoading,
	};

	const onChangeGetSelectedTemplateFilename = (props) => {
		setState(previousState => {
			return {
				...previousState,
				selectedTemplateFilename: props,
				enableDownloadTemplateButton: true,
			};
		});
	};

	const TemplateTypeSelect = () => {
		return (
			<FormControl sx={{ m: 0, minWidth: 200 }}>
				<TextField
					id="select"
					label="Select template"
					variant="outlined"
					margin="normal"
					size="small"
					required
					defaultValue=""
					value={state.selectedTemplateFilename}
					onChange={(e) => onChangeGetSelectedTemplateFilename(e.target.value)}
					select
				>
					{(templateTypeSelectObj.results.map(({ label, filename }, index) => (
						<MenuItem key={index} value={filename}>
							{label}
						</MenuItem>
					)
					))}
				</TextField>
			</FormControl>
		);
	};

	return (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>

			<Box>
				<Typography variant="h5" component="h2" mb={2}>
					Download Template
				</Typography>
			</Box>

			<Box mb={0}>
				<Typography component="p" sx={{ fontWeight: "bold" }}>
					Select which order template you would like to download?
				</Typography>
			</Box>
			<Box mb={2}>
				<TemplateTypeSelect />
			</Box>

			<Box>
				<Box mb={3}>
					<DownloadButton
						onClick={() => getBlobFile(context.dataProvider.getTemplate(state.selectedTemplateFilename))}
						disabled={!state.enableDownloadTemplateButton}
						icon={false}
						text={"Download Template"}
					/>
				</Box>
			</Box>

			<Box mb={1}>
				<Typography variant="p">
					Visit the <Link href={helpCentreLink} target="_blank" rel="noreferrer">help centre</Link> to get a description of each of the templates and the column details.
				</Typography>
			</Box>

		</Paper>
	);

}
