import * as React from "react";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Chip,
	Avatar
} from "@mui/material";

export default function MediaCard({
	titleText, 
	bodyText,
	icon,
	type,
}) {

	const CardWrapper = () => {

		return (
			type === "icon" ?

				<Card 
					sx={{ display: "flex", alignItems: "center", pl: 2, height: "100px" }}
				>

					<Avatar
						alt={titleText}
						title={titleText}
						src={`${icon}.png`}
						sx={{ width: 56, height: 56, border: "1px solid rgba(118, 118, 118, 0.25)", borderRadius: "0.25rem", backgroundColor: "rgba(255, 255, 255, 1)" }}
						variant="square"
					/>

					<CardContent>

						<Typography variant="h6" component="div" sx={{ wordBreak: "break-word"}}>
							{titleText}
						</Typography>

						<Typography variant="body2" color="text.secondary" sx={{ m: 0, p: 0 }}>
							{bodyText}
						</Typography>

					</CardContent>

				</Card>

				:

				<Card
					sx={{ width: "100%", height: "100%" }}
					className="align-items-vertical"
				>
					<CardContent>

						<Chip 
							label={titleText} 
							avatar={<Avatar src={`${icon}.png`} />}	
						/>

						{bodyText &&
							<Box className="card-body" mt={2}>
								<Typography
									className="card-text"
									variant="body2"
								>
									{bodyText}
								</Typography>

							</Box>
						}

					</CardContent>

				</Card>
		);

	};

	return (
		<>
			<CardWrapper />
		</>
	);

}