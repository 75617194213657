import React, { useRef } from "react";
import {
	TextField,
	Box,
	InputLabel,
	Typography,
} from "@mui/material";
import "./FileUpload.scss";

const FileUpload = ({ name, label, accept, multiple, setFieldValue, errors }) => {

	const inputRef = useRef(null);

	const handleFileChange = (event) => {
		if (multiple) {
			const files = Array.from(event.currentTarget.files);
			setFieldValue(name, files);
		} else {
			const file = event.currentTarget.files[0];
			setFieldValue(name, file);
		}
	};

	return (
		<Box 
			id="file-upload" 
			mt={2}
			sx={{ 
				display: "flex", 
				flexDirection: "column",
				alignItems: "flex-start",
				gap: "8px",
				width: "100%"
			}}
		>
			<Box>
				<InputLabel id={name} 
					sx={{ 
						marginTop: "8px", 
						lineHeight: "normal",
					}}
				>
					{label}:
				</InputLabel>
				<TextField
					type="file"
					onChange={handleFileChange}
					InputLabelProps={{ shrink: true }}
					inputProps={{ accept: accept, multiple: multiple }} // Passes attributes directly to the native HTML input element.
					InputProps={{ inputRef: inputRef }} // Passes props to the Material-UI Input component that wraps the native input.
					sx={{
						xs: "100%", 
						md: "auto",
						"& .MuiInputBase-root": { border: "none" },
						"& .MuiOutlinedInput-notchedOutline": { border: "none" },
					}}
				/>
			</Box>

			{errors && errors[name] && (
				<Box>
					<Typography className="error-alert" color="error">{errors[name]}</Typography>
				</Box>
			)}

		</Box>
	);
};

export default FileUpload;
