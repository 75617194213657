import React, { useState } from "react";
import {
	Grid,
	Button,
	Box
} from "@mui/material";
import {
	Form,
	Formik
} from "formik";
import { TextfieldFormik } from "../../Shared/TextField/TextField";
import * as Yup from "yup";
import { useEffect } from "react";
import axios from "axios";
import DropDown from "../../Shared/DropDown";

export const FindAddress = ({ onSelectAddress, onClickManualAddress }) => {
	const [state, setState] = useState({
		postcode: "",
		houseNumber: "",
		addresses: [],
		isLoading: false,
		status: true,
		apiKey: "",
	});

	const [address, setAddress] = useState({});

	const INITIAL_FORM_STATE = {
		postcode: "",
		house_number: "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		postcode: Yup.string()
			.required("Please enter the address postcode"),
		house_number: Yup.string()
	});

	const onSubmitFindAddress = (values) => {
		setState(previousState => {
			return {
				...previousState,
				postcode: values.postcode,
				houseNumber: values.house_number,
			};
		});
	};

	useEffect(() => {
		if (!state.postcode) {
			return;
		}

		setState(previousState => {
			return {
				...previousState,
				isLoading: true,
				status: true,
			};
		});

		const fetchAddresses = async () => {
			let url = "https://api.getaddress.io/find/" + state.postcode;
			if (state.houseNumber) {
				url += "/" + state.houseNumber;
			}
			url += "?api-key=" + process.env.REACT_APP_GET_ADDRESS_TOKEN + "&expand=true";

			await axios.get(url)
				.then((response) => {
					setState(previousState => {
						return {
							...previousState,
							addresses: response.data.addresses,
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							addresses: [],
							status: false,
						};
					});
				});
		};

		setState(previousState => {
			return {
				...previousState,
				isLoading: false,
			};
		});

		fetchAddresses();
	}, [state.postcode, state.houseNumber, state.apiKey]);

	const handleAddressChange = (e) => {
		e.postcode = state.postcode;

		setAddress(e);
		onSelectAddress(e);
	};

	return (
		<Box id="find-address">
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitFindAddress(values)}
			>
				<Form
					noValidate
					autoComplete="off"
				>
					<Grid item xs={12} mb={2}>
						<TextfieldFormik
							name="postcode"
							label="Postcode"
							required
						/>
						<TextfieldFormik
							name="house_number"
							label="House Name or Number"
						/>
					</Grid>
					{state.addresses.length !== 0 &&
						<DropDown
							id="order_create.find_address"
							data={state.addresses.map((c) => ({
								key: c.formatted_address.join(", "),
								value: c,
								name: c.formatted_address.join(", ")
							}))}
							name="address"
							label="Select address"
							value={address}
							select={true}
							variant="outlined"
							fullWidth={true}
							margin="normal"
							size="small"
							required={false}
							onChange={handleAddressChange}
						/>
					}
					<Grid item xs="auto">
						<Box className="align-content-right">
							<Button
								type="submit"
								variant="contained"
							>
								Find Address
							</Button>
							<Button
								onClick={() => onClickManualAddress()}
								variant="outlined"
								color="primary"
								sx={{ ml:2 }}
							>
								Enter Address Manually
							</Button>
							<Grid />
						</Box>
					</Grid>
				</Form>
			</Formik>
		</Box>
	);
};
