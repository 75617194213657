import fileDownload from "js-file-download";

export const createDownloadLink = async (filename, dataProvider) => {
	await dataProvider
		.then((res) => {
			fileDownload(res.data, filename);
		});
};

export const getBlobFile = async (dataProvider) => {
	const response = await dataProvider;
	const link = document.createElement("a");
	link.href = response.data.url;
	document.body.appendChild(link);
	link.click();
	setTimeout(() => document.body.removeChild(link), 100);
};
