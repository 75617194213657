import React, { useCallback } from "react";
import {
	TextField,
	MenuItem
} from "@mui/material";
import UseFetchData from "../../Shared/FetchData/FetchData";
import { DataContext } from "../../../App";

const SelectWarehouse = ({ seller, warehouse, onChangeWarehouse }) => {
	const context = React.useContext(DataContext);
	const warehouses = UseFetchData(useCallback(() => context.dataProvider.getSellerWarehouses(seller), [context.dataProvider, seller]));

	const handleWarehouseChange = (e) => {
		const newWarehouseValue = e.target.value;

		if (onChangeWarehouse) {
			onChangeWarehouse(newWarehouseValue);
		}
	};

	return (
		<TextField
			sx={{ minWidth: 150, marginRight: "2rem", marginTop: "0" }}
			label="Warehouse"
			name="warehouse"
			size="small"
			margin="normal"
			select={true}
			variant="outlined"
			onChange={handleWarehouseChange}
			value={warehouse || ""}
		>
			{warehouses.results.map(({ id, name }) => (
				<MenuItem key={id} value={id}>
					{name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default SelectWarehouse;