import React, { 
	useState,
	useContext,
	useCallback
} from "react";
import { DataContext } from "../../../App";
import UseFetchData from "../FetchData/FetchData";
import { ProductImage } from "../ProductImage/ProductImage";

export default function ProductModal ({ id }) {

	if (!id) return;

	const context = useContext(DataContext);
	const { status, results, isLoading } = UseFetchData(useCallback(
		() => context.dataProvider.getProduct(id), [context.dataProvider, id])
	);

	const [state, setState] = useState({
		isModalVisible: false,
	});

	const onClick = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalVisible: true
			};
		});
	};

	const onClose = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalVisible: false
			};
		});
	};

	return (
		status && results?.image_url && !isLoading &&
			<ProductImage 
				buttonType={"icon"}
				onClick={onClick}
				isVisible={state.isModalVisible}
				onClose={onClose}
				imageURL={results.image_url}
			/>
	);

}