import React from "react";
import "./OrderUpload.scss";
import {
	Grid,
	Box,
} from "@mui/material";
import DownloadATemplatePanel from "./views/DownloadATemplatePanel";
import UploadATemplatePanel from "./views/UploadATemplatePanel";
import SellerDependentView from "../Shared/SellerDependentView";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function OrderUpload({ seller, userdata }) {

	return (
		<Box id="order-upload">
			<PermissionDependentView userdata={userdata} permission="import_orders">
				<SellerDependentView seller={seller}>
					<Grid container>
						<Grid item xs={12} lg={12} mb={2}>
							<BreadCrumb
								breadcrumbs={[
									{ label: "Orders", url: "/order-status" },
									{ label: "Order Upload"}
								]}
							/>
						</Grid>
						<Grid item xs={12} lg={12} mb={4}>
							<DownloadATemplatePanel seller={seller} />
						</Grid>

						<Grid item xs={12} lg={12}>
							<UploadATemplatePanel seller={seller} />
						</Grid>
					</Grid>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);
}
