import React, {
	useState,
	useEffect,
	useRef,
} from "react";
import "./SettingsView.scss";
import SellerDependentView from "../Shared/SellerDependentView";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import {
	CustomTabPanel,
	a11yProps
} from "../Shared/CustomTabPanel/CustomTabPanel";
import {
	Box,
	Grid,
	Paper,
	Typography,
	FormGroup,
	Checkbox,
	TextField,
	FormLabel,
	Button,
	Snackbar,
	Tabs,
	Tab,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataContext } from "../../App";
import CourierConfiguration from "./components/CourierConfiguration";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import LightTooltip from "../Shared/LightTooltip";
import { AlertBox } from "../Shared/Alert/Alert";
import UserManagement from "./components/UserManagement";

export default function SettingsView({ seller, userdata }) {

	const context = React.useContext(DataContext);
	const [state, setState] = useState({
		settings: new Map(),
		isLoading: false,
		isLoadingUpdate: false,
		responseError: "",
		alertMessage: "",
		alertSeverity: "",
		showAlert: false,
		isReloaded: false,
	});

	const addSellerSettingToMap = (setting, source, destination) => {
		if (!setting || setting.id === 0) return;

		destination.set(setting.id, setting);

		// Need to populate a reference in the parent for form structuring
		if (setting.parent_id !== 0) {
			let parent = destination.get(setting.parent_id);
			if (!parent) {
				parent = source.get(setting.parent_id);
				destination = addSellerSettingToMap(parent, source, destination);
			}

			if (!parent.children) {
				parent.children = new Map();
			}

			parent.children.set(setting.id, setting);
			destination.set(setting.parent_id, parent);
		}

		return destination;
	};

	useEffect(() => {
		if (!seller) return;

		const loadSellerSettings = async () => {
			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			context.dataProvider.getSellerSettings(seller)
				.then(response => {
					const settingsMap = new Map(Object.entries(response.data));
					for (const [, setting] of settingsMap) {
						addSellerSettingToMap(setting, settingsMap, state.settings);
					}

					setState(previousState => {
						return {
							...previousState,
							settings: state.settings
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							responseError: "error",
						};
					});
				});

			setState(previousState => {
				return {
					...previousState,
					isLoading: false,
				};
			});

		};
		loadSellerSettings();
	}, [seller, context.dataProvider, state.isReloaded]);

	const onChangeSetting = (setting_id, value) => {
		setState(previousState => {
			const setting = state.settings.get(setting_id);

			if (setting.type === "boolean") {
				if (setting.value === "false") {
					setting.value = "true";
				} else {
					setting.value = "false";
				}
			} else {
				setting.value = String(value);
			}

			state.settings.set(setting_id, setting);
			return {
				...previousState,
				settings: state.settings,
			};
		});

	};

	const onChangeDurationSetting = (setting_id, value, unit) => {
		setState(previousState => {
			const setting = state.settings.get(setting_id);

			let durationInSeconds = 0;
			switch (unit) {
			case "seconds":
				durationInSeconds = (Math.floor(setting.value / 60) * 60) + (Number(value) % 60);
				break;
			case "minutes":
				durationInSeconds = (Number(value) * 60) + (setting.value % 60);
				break;
			default:
			}

			if (durationInSeconds < 30) {
				durationInSeconds = 30;
			}

			setting.value = String(durationInSeconds);
			state.settings.set(setting_id, setting);

			return {
				...previousState,
				settings: state.settings,
			};
		});
	};

	const onClickDoUpdate = () => {
		if (!seller) return;

		let clientSettings = [];
		for (const [, setting] of state.settings) {
			clientSettings.push({
				"client_id": seller,
				"setting_id": setting.id,
				"value": setting.value,
			});
		}
	
		for (const [setting_id, ref] of settingRefs.current.entries()) {
			if (ref.current) {
				const value = ref.current.value;
				clientSettings.push({
					"client_id": seller,
					"setting_id": parseInt(setting_id, 10),
					"value": value,
				});
			}
		}

		const updateSellerSettings = async () => {
			if (clientSettings.length === 0) return;

			setState(previousState => {
				return {
					...previousState,
					isLoadingUpdate: true,
					alertMessage: "",
					alertSeverity: "",
				};
			});

			await context.dataProvider.updateSellerSettings(seller, clientSettings)
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							alertMessage: "Settings updated successfully",
							alertSeverity: "success",
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							responseError: "Error",
							alertMessage: "Settings update failed, please try again or contact support@bezos.ai",
							alertSeverity: "error",
						};
					});
				});

			setState(previousState => {
				return {
					...previousState,
					isLoadingUpdate: false,
					showAlert: true,
					isReloaded: true,
				};
			});
		};
		updateSellerSettings();
	};

	const GenericSetting = ({ setting }) => {
		switch (setting.type) {
		case "boolean":
			return <BooleanSetting key={setting.id} setting={setting}/>;
		case "string":
			return <StringSetting key={setting.id} setting={setting}/>;
		case "integer":
			return <IntegerSetting key={setting.id} setting={setting}/>;
		case "duration":
			return <DurationSetting key={setting.id} setting={setting}/>;
		default:
		}
	};

	const onAlertHandleClose = (reason) => {
		if (reason === "clickaway") return;

		setState(previousState => {
			return {
				...previousState,
				showAlert: false,
			};
		});

	};

	const ParentSetting = ({ setting, ChildSetting }) => {
		return (
			<Grid key={setting.id} container className="vertical-align-elements">
				<Grid item xs={5} mb={1} sx={{
					display: "flex",
					justifyContent: "flex-start",
					verticalAlign: "middle"
				}}>
					<FormLabel>{setting.label}</FormLabel>
					{
						setting.description &&
						<LightTooltip title={setting.description} arrow>
							<InfoTwoToneIcon fontSize="small" sx={{ ml: "0.5rem" }}/>
						</LightTooltip>
					}
				</Grid>
				<ChildSetting setting={setting}/>
			</Grid>
		);
	};

	const BooleanSetting = ({ setting }) => {
		const isChecked = (setting.value === "true" || setting.value === true);

		const ChildBooleanSetting = ({ setting }) => {
			return (
				<>
					<Grid item xs={1}>
						<Checkbox
							checked={isChecked}
							onChange={(e) => onChangeSetting(setting.id, e.target.value)}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</Grid>
					{setting.children && isChecked &&
						[...setting.children.values()].map((child) => {
							return <GenericSetting key={child.id} setting={child}/>;
						})
					}
				</>
			);
		};

		if (setting.parent_id !== 0) {
			return <ChildBooleanSetting setting={setting}/>;
		} else {
			return <ParentSetting setting={setting} ChildSetting={ChildBooleanSetting}/>;
		}
	};

	const settingRefs = useRef(new Map());

	const StringSetting = ({ setting }) => {
		const inputRef = useRef(null);

		useEffect(() => {
			settingRefs.current.set(setting.id, inputRef);
			return () => {
				// Clean up the ref when the component unmounts
				settingRefs.current.delete(setting.id);
			};
		}, [setting.id]);

		const ChildStringSetting = ({ setting }) => {
			return (
				<Grid item xs={6}>
					<TextField
						name={setting.name}
						label={setting.label}
						size="small"
						margin="normal"
						type="text"
						sx={{ maxWidth: 300, marginLeft: "1rem" }}
						defaultValue={setting.value}
						InputProps={{ inputRef: inputRef }}
						id={setting.id.toString()}
					/>
				</Grid>
			);
		};

		if (setting.parent_id !== 0) {
			return <ChildStringSetting setting={setting}/>;
		} else {
			return <ParentSetting setting={setting} ChildSetting={ChildStringSetting}/>;
		}
	};

	const IntegerSetting = ({ setting }) => {
		const ChildIntegerSetting = ({ setting }) => {
			return (
				<Grid item xs={6}>
					<TextField
						name={setting.name}
						label={setting.label}
						size="small"
						margin="normal"
						type="number"
						sx={{ maxWidth: 170, marginLeft: "1rem" }}
						InputProps={{
							inputProps: {
								min: 0,
							}
						}}
						onChange={(e) => onChangeSetting(setting.id, e.target.value)}
						value={setting.value}
					/>
				</Grid>
			);
		};

		if (setting.parent_id !== 0) {
			return <ChildIntegerSetting setting={setting}/>;
		} else {
			return <ParentSetting setting={setting} ChildSetting={ChildIntegerSetting}/>;
		}
	};

	const DurationSetting = ({ setting }) => {
		const ChildDurationSetting = ({ setting }) => {
			return (
				<>
					<Grid item>
						<TextField
							name={setting.name + "_minutes"}
							label="Minutes"
							size="small"
							margin="normal"
							type="number"
							sx={{ maxWidth: 75, marginLeft: "1rem" }}
							InputProps={{
								inputProps: {
									min: 0,
									max: 119,
								}
							}}
							value={Math.floor(setting.value / 60)}
							onChange={(e) => onChangeDurationSetting(setting.id, e.target.value, "minutes")}
						/>
					</Grid>
					<Grid item>
						<TextField
							name={setting.name + "_seconds"}
							label="Seconds"
							size="small"
							margin="normal"
							type="number"
							sx={{ maxWidth: 75, marginLeft: "1rem" }}
							InputProps={{
								inputProps: {
									min: 0,
									max: 60,
								}
							}}
							value={setting.value % 60}
							onChange={(e) => onChangeDurationSetting(setting.id, e.target.value, "seconds")}
						/>
					</Grid>
				</>
			);
		};

		if (setting.parent_id !== 0) {
			return <ChildDurationSetting setting={setting}/>;
		} else {
			return <ParentSetting setting={setting} ChildSetting={ChildDurationSetting}/>;
		}
	};

	const SettingCategory = ({label, name}) => {
		return (
			<Box sx={{ mb: 2 }}>
				<Grid container spacing={0}>
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
						<LoadableContent isLoading={state.isLoading}>
							<Box>
								<Grid sx={{ mb: 0 }}>
									<Typography variant="h6" sx={{ mb: 0 }}>
										{label}
										{state.isLoadingUpdate ?
											<CircularProgress size={20} sx={{ ml: 1 }}/> : null}
									</Typography>
									<Grid item xs/>
									<Box sx={{ mb: 0 }}>
										<FormGroup>
											<Grid>
												{
													[...state.settings.values()]
														.filter(setting => setting.category === name)
														.map((setting) => {
															if (setting.parent_id === 0) {
																return <GenericSetting key={setting.id} setting={setting}/>;
															}
														})
												}
											</Grid>
										</FormGroup>
									</Box>
								</Grid>
							</Box>
						</LoadableContent>
					</Paper>
				</Grid>
			</Box>
		);
	};

	const SettingsTab = ({seller}) => {
		return (
			<>
				{(userdata.permissions && userdata.permissions.has("edit_courier_configuration")) &&
				<Box sx={{ mb: 2 }}>
					<Grid container spacing={0}>
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<Box>
								<LoadableContent isLoading={state.isLoading}>
									<Box>
										<CourierConfiguration seller={seller}/>
									</Box>
								</LoadableContent>
							</Box>
						</Paper>
					</Grid>
				</Box>
				}
				<SettingCategory label="Order Management" name="order_management"/>
				<SettingCategory label="Notification Management" name="notification_management"/>
				<Box sx={{ mb: 4 }}>
					<Button
						disabled={state.isLoadingUpdate}
						onClick={() => onClickDoUpdate()}
						variant="contained"
					>
						<Typography>
							Save Settings
						</Typography>
						{state.isLoadingUpdate &&
							<CircularProgress size={20} sx={{ ml: 1 }}/>
						}
					</Button>
				</Box>
				<Box>
					<Snackbar
						open={state.showAlert}
						autoHideDuration={8000}
						onClose={onAlertHandleClose}
					>
						<Box>
							<AlertBox
								severity={state.alertSeverity}
								message={state.alertMessage}
							/>
						</Box>
					</Snackbar>
				</Box>
			</>
		);
	};

	const [tabValue, setTabValue] = React.useState(0);
	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Box id="settings-view">
			<SellerDependentView seller={seller}>
				<Box sx={{ width: "100%" }}>
					<Box sx={{ mb: 2 }}>
						<Tabs value={tabValue} onChange={handleChangeTab}>
							<Tab label="Settings" {...a11yProps(0)} sx={{ fontSize: "1.5rem", textTransform: "capitalize", p:0, mt:0, mb:0, ml:0, mr:2 }} />
							<Tab label="User Management" {...a11yProps(1)} sx={{ fontSize: "1.5rem", textTransform: "capitalize", p:0, mt:0, mb:0, ml:0, mr:2 }} />
						</Tabs>
					</Box>
					<CustomTabPanel value={tabValue} index={0}>
						<SettingsTab seller={seller}/>
					</CustomTabPanel>
					<CustomTabPanel value={tabValue} index={1}>
						<UserManagement seller={seller}/>
					</CustomTabPanel>
				</Box>
			</SellerDependentView>
		</Box>
	);
}