import React, { 
	useState,
	useEffect
} from "react";
import { 
	Grid,
	Typography,
	Button
} from "@mui/material";
import MultiSelect from "./MultiSelect";
import {
	EndDatePicker,
	StartDatePicker,
} from "../../Shared/DatePicker/DatePicker";
import { 
	getDateMinusOneYearPlusOneDay,
	getCurrentDateTruncated,
} from "../../../utils/date";

export const FilterPanel = ({
	warehouseNameData,
	warehouseData,
	courierData,
	handleWarehouseName,
	handleWarehouse,
	handleCourier,
	selectedWarehouseNames,
	selectedWarehouseStatuses,
	selectedCourierStatuses,
	startDate,
	endDate,
	handleStartDate,
	handleEndDate,
	disabled,
}) => {

	const [state, setState] = useState({
		isDisabled: true,
	});

	useEffect(() => {
		if (selectedWarehouseNames.length === 0
			&& selectedWarehouseStatuses.length === 0
			&& selectedCourierStatuses.length === 0
			&& startDate.toISOString() === getDateMinusOneYearPlusOneDay().toISOString()
			&& endDate.toISOString() === getCurrentDateTruncated().toISOString()
		) {
			setState(previousState => {
				return {
					...previousState,
					isDisabled: true,
				};
			});
		} else {
			setState(previousState => {
				return {
					...previousState,
					isDisabled: false,
				};
			});
		}
	}, [
		selectedWarehouseNames,
		selectedWarehouseStatuses, 
		selectedCourierStatuses, 
		startDate,
		endDate,
	]);

	const onStartDateChange = (date) => {
		handleStartDate(date);
	};

	const onEndDateChange = (date) => {
		handleEndDate(date);
	};

	const onWarehouseNameChange = (id) => {
		handleWarehouseName(id);
	};

	const onWarehouseChange = (value) => {
		handleWarehouse(value);
	};

	const onCourierChange = (value) => {
		handleCourier(value);
	};

	const onClickResetFilters = () => {
		onWarehouseNameChange([]);
		onWarehouseChange([]);
		onCourierChange([]);
		onStartDateChange(getDateMinusOneYearPlusOneDay());
		onEndDateChange(getCurrentDateTruncated());
	};

	return (
		<>
			<Grid container>

				<Grid 
					item
					sx={{ 
						p: "1rem", 
						backgroundColor: theme => theme.palette.background.main,
						minHeight: 600, 
						borderRadius: "0.25rem",
					}}
				>

					<Grid item mb={3}>
						<Typography variant="h6" gutterBottom>
							Filters:
						</Typography>
					</Grid>

					{warehouseNameData.length > 1 &&
						<Grid item mb={1}>
							<MultiSelect
								select_label="Warehouse Name"
								selected={selectedWarehouseNames} 
								options={warehouseNameData}
								disabled={disabled}
								onChange={onWarehouseNameChange}
								sx={{ minWidth: 200 }}
								valueKey={"id"}
							/>
						</Grid>
					}

					<Grid item mb={1}>
						<MultiSelect
							select_label="Warehouse Status"
							selected={selectedWarehouseStatuses}
							options={warehouseData}
							disabled={disabled}
							onChange={onWarehouseChange}
							sx={{ minWidth: 200 }}
							valueKey={"name"}
						/>
					</Grid>

					<Grid item mb={1}>
						<MultiSelect
							select_label="Courier Status"
							selected={selectedCourierStatuses}
							options={courierData}
							disabled={disabled}
							onChange={onCourierChange}
							sx={{ minWidth: 200 }}
							valueKey={"name"}
						/>
					</Grid>
					
					<Grid item mb={1}>
						<StartDatePicker
							onChange={(date) => onStartDateChange(date)}
							date={startDate}
							sx={{ backgroundColor: theme => theme.palette.statusbox.main, minWidth: 200, mr: 0 }}
						/>
					</Grid>

					<Grid item mb={1}>
						<EndDatePicker
							onChange={(date) => onEndDateChange(date)}
							date={endDate}
							sx={{ backgroundColor: theme => theme.palette.statusbox.main, minWidth: 200, mr: 0 }}
						/>
					</Grid>

					<Grid item mt={2} mb={1}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onClickResetFilters}
							disabled={state.isDisabled}
							title="Reset Filters"
						>
							Reset
						</Button>
					</Grid>

				</Grid>

			</Grid>
		</>

	);

};