import {
	addDays,
	isValid,
	parseISO,
	formatISO,
	subYears,
	subDays, 
	format,
} from "date-fns";

const padNumber = (n) => {
	const padN = "00" + n;
	return padN.substring(padN.length - 2);
};

export const getFormattedTime = (t) => t.toISOString().substring(0, 19);

export const getUTCDatetime = (dt) => new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(),
	dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds()));

export const getUTCDisplayTime = (t, errorMsg, s) => {
	const isZeroTime = t === "0001-01-01T00:00:00Z";
	const separator = s ? s : "-";

	if (t && !isZeroTime) {
		const date = new Date(t);
		return date.getUTCFullYear() + separator +
			padNumber(date.getUTCMonth() + 1) + separator +
			padNumber(date.getUTCDate()) + " " +
			padNumber(date.getUTCHours()) + ":" +
			padNumber(date.getUTCMinutes());
	}

	if (!errorMsg) {
		return "Unavailable";
	}

	return errorMsg;
};

export const getDisplayTime = (t, errorMsg, s) => {
	const isZeroTime = t === "0001-01-01T00:00:00Z";
	const separator = s ? s : "-";

	if (t && !isZeroTime) {
		const date = new Date(t);
		return date.getFullYear() + separator +
            padNumber(date.getMonth() + 1) + separator +
            padNumber(date.getDate()) + " " +
            padNumber(date.getHours()) + ":" +
            padNumber(date.getMinutes());
	}

	if (!errorMsg) {
		return "Unavailable";
	}

	return errorMsg;
};

export const getUTCDisplayDate = (t, errorMsg, s) => {
	const isZeroTime = t === "0001-01-01T00:00:00Z";
	const separator = s ? s : "-";

	if (t && !isZeroTime) {
		const date = new Date(t);
		return date.getUTCFullYear() + separator +
			padNumber(date.getUTCMonth() + 1) + separator +
			padNumber(date.getUTCDate());
	}

	if (!errorMsg) {
		return "Unavailable";
	}

	return errorMsg;
};

export const getDisplayDate = (t, errorMsg, s) => {
	const isZeroTime = t === "0001-01-01T00:00:00Z";
	const separator = s ? s : "-";

	if (t && !isZeroTime) {
		const date = new Date(t);
		return date.getFullYear() + separator +
            padNumber(date.getMonth() + 1) + separator +
            padNumber(date.getDate());
	}

	if (!errorMsg) {
		return "Unavailable";
	}

	return errorMsg;
};

export const getDisplayDateTime = (t, errorMsg, s) => {
	const displayDate = getDisplayDate(t, errorMsg, s);
  
	if (typeof displayDate === "string" && displayDate !== "Unavailable") {
		const date = new Date(t);
		const time = `${padNumber(date.getHours())}:${padNumber(date.getMinutes())}:${padNumber(date.getSeconds())}`;
		return `${displayDate} ${time}`;
	}
  
	return displayDate;
};

export const getCurrentDateTruncated = () => {
	const date = new Date();
	date.setHours(0, 0, 0, 0);
	return date;
};

export const getNextDayTruncated = () => {
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	tomorrow.setHours(1);
	tomorrow.setMinutes(0);
	tomorrow.setSeconds(0);
	tomorrow.setMilliseconds(0);
	return tomorrow;
};

export const getNextAvailableDeliveryDay = (date, isUserAdmin) => {

	let newDate;

	if (isUserAdmin) {
		newDate = new Date();
	} else {
		newDate = new Date(date);
		let day = newDate.getDay(); // get the current day of the week (0 is Sunday, 1 is Monday, etc.)
		let daysToAdd;

		switch(day) {
		case 4: // Thursday
			daysToAdd = 4;
			break;
		case 5: // Friday
			daysToAdd = 4;
			break;
		case 6: // Saturday
			daysToAdd = 3;
			break;
		default:
			daysToAdd = 2;
		}

		newDate = addDays(newDate, daysToAdd);
	}

	return newDate;
};

export const isISODate = (d) => {
	return typeof d === "string" && isValid(parseISO(d));
};

export const convertToISO = (d) => {
	if (isISODate(d)) {
		return d;
	} else if (!(d instanceof Date)) {
		return ("Input must be a Date object or a valid ISO 8601 date string.");
	}

	const formattedDateString = formatISO(d).substring(0, 19);
	return formattedDateString;
};

export const getISODateTime = (d, t) => {
	if (!d || !t || !isISODate(d)) return;

	let date = d;
	date = date.substring(0, 10);
	let time = "T" + t;
	return date + time;
};

export const nullDate = "0001-01-01T00:00:00Z";

export const isDateNull = (date) => date === nullDate;

const normaliseDate = (dateString) => {
	const date = new Date(dateString);
	date.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)
	return date;
};

export const isDateValidAndMatch = (expectedDate, actualDate) => {
	if (isDateNull(actualDate) || isDateNull(expectedDate)) return false;

	const normalisedActualDate = normaliseDate(actualDate);
	const normalisedExpectedDate = normaliseDate(expectedDate);

	return normalisedActualDate <= normalisedExpectedDate;
};

export function getDateMinusOneYearPlusOneDay() {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const minusOneYear = subYears(today, 1);
	const minusOneYearPlusOneDay = addDays(minusOneYear, 1);
	return minusOneYearPlusOneDay;
}

export const getLast30DaysDates = () => {
	const today = new Date();
	const startDate = subDays(today, 30);

	return {
		startDate: format(startDate, "yyyy-MM-dd'T'00:00:00"),
		endDate: format(today, "yyyy-MM-dd'T'00:00:00"),
	};
};
