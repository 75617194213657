import React, {
	useState,
	useRef
} from "react";
import SellerDependentView from "../Shared/SellerDependentView";
import "./InvoiceUpload.scss";
import {
	Box,
	Grid,
	Typography,
	Button,
	Paper,
	FormControl,
	MenuItem,
	TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataContext } from "../../App";
import InvoiceView from "../InvoiceView";
import { DeleteButton } from "../Shared/Buttons/Buttons";
import { AlertBox } from "../Shared/Alert/Alert";

export default function InvoiceUpload({ seller }) {
	const context = React.useContext(DataContext);

	const padNumber = (n) => {
		const padN = "00" + n;
		return padN.substring(padN.length - 2);
	};

	const getBillingPeriods = () => {
		const curDate = new Date();
		let curYear = curDate.getFullYear();
		let curMonth = curDate.getMonth() + 1;

		const periods = [];

		for (let i = 0; i < 12; i++) {
			periods.push(curYear + "-" + padNumber(curMonth));
			curMonth--;
			if (curMonth <= 0) {
				curYear--;
				curMonth = 12;
			}
		}
		return periods;
	};

	const billingPeriods = getBillingPeriods();
	const [state, setState] = useState({
		isUploadDivOpen: false,
		isUploadButtonEnabled: false,
		filesToUpload: null,
		isUploadFileAdded: false,
		selectedBillingPeriod: billingPeriods[0],
		reloadTable: false,
		alert: {
			severity: "",
			message: "",
			isOpen: false
		},
	});

	const onClickDisplayUploadDiv = () => {
		setState(previousState => {
			return {
				...previousState,
				isUploadDivOpen: !previousState.isUploadDivOpen
			};
		});
	};

	const onClickUploadFile = async () => {

		if (!state.filesToUpload.length) return;

		const billingPeriod = state.selectedBillingPeriod;
		const arr = state.filesToUpload;
		const formData = new FormData();
		const promises = [];

		for (let i = 0; i < arr.length; i++) {
			formData.set("File", arr[i]);
			promises.push(await context.dataProvider.createSellerInvoice(seller, billingPeriod, formData));
		}

		Promise.all(promises)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						alert: {
							severity: "success",
							message: "File(s) uploaded successfully",
							isOpen: true
						},
						isUploadButtonEnabled: false,
						filesToUpload: null,
						isUploadFileAdded: false,
						selectedBillingPeriod: billingPeriods[0],
						reloadTable: !state.reloadTable,
					};
				});
			})
			.catch(err => {
				setState(previousState => {
					return {
						...previousState,
						alert: {
							severity: "error",
							message: `Error uploading file(s): ${err}`,
							isOpen: true
						},
					};
				});
			});

		uploadInputRef.current.value = "";

	};

	const uploadInputRef = useRef();

	const onChangeGetFileUpload = (e) => {
		setState(previousState => {
			return {
				...previousState,
				filesToUpload: e.target.files,
				isUploadFileAdded: true,
				isUploadButtonEnabled: true,
				alert: {
					severity: "",
					message: "",
					isOpen: false
				},
			};
		});
	};

	const onClickDeleteUploadFile = () => {
		uploadInputRef.current.value = "";
		setState(previousState => {
			return {
				...previousState,
				filesToUpload: null,
				isUploadFileAdded: false,
				isUploadButtonEnabled: false
			};
		});
	};

	const BillingPeriodSelect = () => {
		return (
			<FormControl sx={{ mb: 2, minWidth: 200 }}>
				<TextField
					select
					id="select"
					label="Billing period"
					variant="outlined"
					margin="normal"
					size="small"
					required
					defaultValue=""
					value={state.selectedBillingPeriod}
					onChange={(e) => {
						setState(previousState => {
							return {
								...previousState,
								selectedBillingPeriod: e.target.value
							};
						});
					}}
				>
					{billingPeriods.map((bp, index) =>
						(<MenuItem key={index} value={bp}>{bp}</MenuItem>))
					}
				</TextField>
			</FormControl>
		);
	};

	const DeleteUploadFileButton = () => {
		return (
			<>
				<Box variant="span" sx={{ marginRight: "1rem" }}>{state.filesToUpload.length + " file(s) "}</Box>
				<DeleteButton
					onClick={() => onClickDeleteUploadFile()}
					icon={true}
				/>
			</>
		);
	};

	const InvoiceUploadBody = () => {
		return (
			<Grid item xs={12} mb={2}>
				<Box className={`panel${state.isUploadDivOpen === true ? "-show" : "-hide"}`}>
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
						<Box>
							<Typography variant="h5" sx={{ marginBottom: 2 }}>
								Upload Invoices
							</Typography>
							<Box>
								<BillingPeriodSelect />
							</Box>
							<Box mb={3}>
								<input
									type="file"
									name="File Upload"
									accept='text/csv, .pdf'
									ref={uploadInputRef}
									onChange={(e) => onChangeGetFileUpload(e)}
									multiple
								/>
								<Box className="upload-file-count">
									{(state.isUploadFileAdded === true) ? <DeleteUploadFileButton /> : "add file(s)"}
								</Box>
							</Box>

							<Button
								variant="contained"
								component="span"
								onClick={() => onClickUploadFile()}
								disabled={!state.isUploadButtonEnabled}
							>
								Upload
							</Button>
							<Typography mt={2} sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>
								Only upload PDF or CSV files
							</Typography>

							{state.alert.isOpen &&
								<Box>
									<Box mt={2} mb={5} sx={{ width: "100%" }}>
										<AlertBox severity={state.alert.severity} message={state.alert.message}/>
									</Box>
								</Box>
							}
						</Box>
					</Paper>
				</Box>
			</Grid>
		);
	};

	const InvoiceUploadHeading = () => {
		return (
			<>
				<Grid item xs={12}>
					<Box>
						<Button
							variant="contained"
							onClick={() => onClickDisplayUploadDiv()}
						>
							Invoice <AddIcon />
						</Button>
					</Box>
				</Grid>
				{(state.isUploadDivOpen) ? <InvoiceUploadBody /> : null}
			</>
		);
	};

	return (
		<Box id="invoice-upload">
			<SellerDependentView seller={seller}>
				<Grid container spacing={2}>
					<InvoiceUploadHeading />
					<Grid item xs={12}>
						<InvoiceView seller={seller} reload={state.reloadTable} />
					</Grid>
				</Grid>
			</SellerDependentView>
		</Box>
	);
}