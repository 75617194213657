import React, {
	useEffect,
	useState
} from "react";
import {
	getDisplayTime,
	getUTCDisplayDate
} from "../../../utils/date.js";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Button,
	Pagination,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getPageValue } from "../../../utils/pagination";
import * as tableConstants from "../../Shared/Config/TableConfig";
import { getReportTypeNameFromID } from "../../../utils/reports";
import { DataContext } from "../../../App";
import { getBlobFile } from "../../../utils/downloadFile";
import PageRowsDropDown from "../../Shared/PageRows/PageRows";
import { DownloadButton } from "../../Shared/Buttons/Buttons";
import { RecordsTotal } from "../../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";
import { LoadingStatusTable } from "../../Shared/LoadingStatus/LoadingStatus.jsx";

export default function ReportsTable({ seller, updateTable }) {

	const context = React.useContext(DataContext);
	const pageRowOptions = tableConstants.PAGINATION_OPTIONS;
	const [state, setState] = useState({
		isLoaded: false,
		resultsData: [],
		resultsTotalCount: 0,
		reloadTable: false,
		paginationCurrentPage: 1,
		paginationPostsPerPage: pageRowOptions[0],
		paginationPages: 0
	});

	useEffect(() => {
		if (!seller) return;

		const getResultsDataTotalOnly = async () => {

			await context.dataProvider.getReportsTotal(seller)
				.then(response => {
					setState(previousState => {
						return {
							...previousState,
							resultsTotalCount: response.data,
							paginationPages: Math.ceil(Number(response.data) / Number(state.paginationPostsPerPage)),
						};
					});
				});
		};
		getResultsDataTotalOnly();

		const getResultsData = async () => {
			await context.dataProvider.getReports(seller, state.paginationCurrentPage, state.paginationPostsPerPage)
				.then(response => {
					setState(previousState => {
						return {
							...previousState,
							resultsData: response.data.sort((a, b) => a.requested_date > b.requested_date ? -1 : 1),
							isLoaded: true,
						};
					});
				});
		};
		getResultsData();

	}, [seller, state.paginationCurrentPage, state.paginationPostsPerPage, updateTable, state.reloadTable, context.dataProvider]);

	const getStatusValue = (available, errors) => {
		if (available === false && errors === "") {
			return "processing";
		} else if (available === true) {
			return "ready to download";
		} else {
			return "error processing report";
		}
	};

	const onClickDownloadReportFile = (seller, filename) => {
		getBlobFile(context.dataProvider.getReportFileURL(seller, filename));
	};

	const summaryTableRows = state.resultsData.map(
		({ requested_date, report_type_id, start_date, end_date, filename, available, errors }, index) => <TableRow key={index}>
			<TableCell>{getDisplayTime(requested_date, "", "/")}</TableCell>
			<TableCell>
				{getReportTypeNameFromID(report_type_id)}
				{(report_type_id !== 3 && report_type_id !== 4) ? ": " + getUTCDisplayDate(start_date, "", "/") + " - " + getUTCDisplayDate(end_date, "", "/") : ""}
			</TableCell>
			<TableCell>
				<StatusBox 
					status={getStatusValue(available, errors)}
					statusType="reports"
				/>
			</TableCell>
			<TableCell align="right">
				<DownloadButton
					onClick={() => onClickDownloadReportFile(seller, filename)}
					disabled={available === true ? false : true}
					icon={true}
				/>
			</TableCell>
		</TableRow>
	);

	let paginationView;
	if (state.resultsTotalCount <= state.paginationPostsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={state.paginationPages}
				page={state.paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							paginationCurrentPage: getPageValue(input, state.paginationCurrentPage),
						};
					});
				}}
			/>
		);
	}

	const onClickReloadTable = () => {
		setState(previousState => {
			return {
				...previousState,
				reloadTable: !state.reloadTable,
				paginationCurrentPage: 1,
			};
		});
	};

	const onPageRowChange = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				paginationPostsPerPage: pageRows,
				paginationCurrentPage: 1,
			};
		});
	};

	return (
		<Box>
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>

				<Typography variant="h5">
					One-Time Reports <RecordsTotal total={state.resultsTotalCount} />
				</Typography>

				<Button
					variant="outlined"
					color="secondary"
					onClick={() => onClickReloadTable()}
					className="reload-button"
					title="Refresh Table"
					sx={{ mb: 2 }}
				>
					Refresh <RefreshIcon />
				</Button>

				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
					<Table
						aria-label="reports table"
						id="reportsTable"
						stickyHeader
					>
						<TableHead>
							<TableRow>
								<TableCell align="left">Requested date</TableCell>
								<TableCell align="left">Name</TableCell>
								<TableCell align="left">Status</TableCell>
								<TableCell align="left"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(state.isLoaded === true) ?
								(summaryTableRows)
								:
								<LoadingStatusTable colSpan={4} message="Loading.." />
							}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid
					container
					spacing={0}
					padding={2}
					className="table-footer"
				>
					{paginationView}
					<PageRowsDropDown
						resultsTotalCount={state.resultsTotalCount}
						paginationPostsPerPage={state.paginationPostsPerPage}
						pageRowOptions={pageRowOptions}
						onPageRowChange={onPageRowChange}
					/>
				</Grid>
			</Paper>
		</Box>
	);
}
