import React from "react";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/DeleteForever";

export const DownloadButton = ({
	onClick,
	disabled,
	icon,
	text,
	sx,
}) => {

	return (
		<>
			<Button
				title="Download File"
				variant="contained"
				onClick={() => onClick()}
				disabled={disabled}
				sx={{ ...sx }}
			>
				{text} {icon && <FileDownloadIcon />}
			</Button>
		</>
	);

};

export const DeleteButton = ({
	onClick,
	icon,
	text,
	sx,
}) => {

	return (
		<>
			<Button
				title="Delete File"
				variant="contained"
				color="destructive"
				onClick={() => onClick()}
				sx={{ ...sx }}
			>

				{text} {icon && <DeleteIcon />}
			</Button>
		</>
	);

};
