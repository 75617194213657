import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
	Grid,
	Typography,
	Box,
	Paper
} from "@mui/material";
import "./ProductCreate.scss";
import { ProductDetails } from "./views/ProductDetails";
import { ProductDimensions } from "./views/ProductDimensions";
import { ExtraDetails } from "./views/ExtraDetails";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import SellerDependentView from "../Shared/SellerDependentView";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function ProductCreate({ seller, userdata }) {
	const sellerID = seller.toString();

	const { state } = useLocation();
	const existingProduct = state ? state.product : {};

	let returnPath = "/inventory";
	if (existingProduct.product_id) {
		returnPath = "/inventory-detail/" + existingProduct.product_id;
	}

	const [pageState, setPageState] = useState({
		productDetailsForm: {},
		productDimensionsForm: {},
		pageTitle: existingProduct.sku ? "Edit Product" : "Create Product",
		pageSubTitle: "",
		panelsVisible: {
			isProductDetailsPanelVisible: true,
			isProductDimensionsPanelVisible: false,
			isExtraDetailsPanelVisible: false,
		},
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const onPanelsVisibleChange = (props) => {
		setPageState(previousState => {
			return {
				...previousState,
				panelsVisible: props,
			};
		});
	};

	const onProductDetailsFormSubmit = (props) => {
		setPageState(previousState => {
			return {
				...previousState,
				productDetailsForm: props,
			};
		});
	};

	const onProductDimensionsFormSubmit = (props) => {
		setPageState(previousState => {
			return {
				...previousState,
				productDimensionsForm: props,
			};
		});
	};

	const TitleHeading = () => {
		return (
			<Box mb={0}>
				<Grid container spacing={1}>
					<Grid item xs={12} container spacing={2} mt={0} mb={1}>
						<Grid item xs="auto">
							<Typography component="h1" variant="h5">
								{pageState.pageTitle}{pageState.pageSubTitle}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	};

	return (
		<Box id="product-create">
			<PermissionDependentView userdata={userdata} permission="create_product">
				<SellerDependentView seller={seller}>
					<BreadCrumb
						breadcrumbs={[
							{ label: existingProduct.sku ? existingProduct.sku : "Inventory", url: returnPath },
							{ label: pageState.pageTitle }
						]}
					/>
					<TitleHeading />
					<Box>
						<Grid container spacing={1}>
							<Box className={`panel${pageState.panelsVisible.isProductDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
								<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
									<Typography component="h3" variant="h6">1. Add Product Details</Typography>
									<Box className={`panel${pageState.panelsVisible.isProductDetailsPanelVisible === true ? "-show" : "-hide"}`}>
										<ProductDetails
											onProductDetailsFormSubmit={onProductDetailsFormSubmit}
											onPanelsVisibleChange={onPanelsVisibleChange}
											existingProduct={existingProduct}
										/>
									</Box>
								</Paper>
							</Box>

							<Box className={`panel${pageState.panelsVisible.isProductDimensionsPanelVisible === true ? "-active" : "-disabled"}`}>
								<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
									<Typography component="h3" variant="h6">2. Add Product Dimensions</Typography>
									<Box className={`panel${pageState.panelsVisible.isProductDimensionsPanelVisible === true ? "-show" : "-hide"}`}>
										<ProductDimensions
											onProductDimensionsFormSubmit={onProductDimensionsFormSubmit}
											onPanelsVisibleChange={onPanelsVisibleChange}
											existingProduct={existingProduct}
										/>
									</Box>
								</Paper>
							</Box>

							<Box className={`panel${pageState.panelsVisible.isExtraDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
								<Paper sx={{ p: 2, width: "100%", marginY: 1 }} elevation={2}>
									<Typography component="h3" variant="h6">3. Add Extra Details</Typography>
									<Box className={`panel${pageState.panelsVisible.isExtraDetailsPanelVisible === true ? "-show" : "-hide"}`}>
										<ExtraDetails
											seller={sellerID}
											productDetailsForm={pageState.productDetailsForm}
											productDimensionsForm={pageState.productDimensionsForm}
											onPanelsVisibleChange={onPanelsVisibleChange}
											existingProduct={existingProduct}
										/>
									</Box>
								</Paper>
							</Box>

						</Grid>
					</Box>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);
}