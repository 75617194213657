import React from "react";
import "./LoadingStatus.scss";
import {
	Typography,
	TableRow,
	TableCell,
	Box
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingStatusTable = ({ colSpan, message }) => {
	return (
		<TableRow>
			<TableCell colSpan={colSpan}>
				<Box sx={{ width: "100%", display: "grid", justifyContent: "center", mt: 3, mb: 5 }}>
					<Typography mb={4}>{message}</Typography>
					<CircularProgress size={50} />
				</Box>
			</TableCell>
		</TableRow>
	);
};

export const LoadingStatusBox = ({ message, marginBottom, sx }) => {
	return (
		<Box className="loading-status-box">
			<Box sx={{...sx, mb: marginBottom }}>
				<Typography mb={4}>{message}</Typography>
				<CircularProgress />
			</Box>
		</Box>
	);
};
