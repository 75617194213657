import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
} from "@mui/material";
import { cleanString } from "../../../utils/text";
import LinkIcon from "@mui/icons-material/Link";

const BasicTable = ({ data }) => {

	if (!data) return;

	let headers, values;
	if (Array.isArray(data)) {
		headers = Object.keys(data[0]);
		values = data;
	} else {
		headers = Object.keys(data);
		values = Object.values(data);
	}

	const renderCellValue = (value) => {
		if (typeof value === "boolean") {
			return value ? "true" : "false";
		}

		if (typeof value === "object" && value !== null && value.constructor === Object) {
			return JSON.stringify(value);
		}

		if (typeof value === "string") {
			if (value === "") {
				return "-";
			} else if (value.includes("http://") || value.includes("https://")) {
				return (
					<Button
						component="a"
						variant="outlined"
						href={value}
						target="_blank"
						rel="noopener noreferrer"
						title="Open Link"
					>
						Link <LinkIcon sx={{ ml: 1 }} />
					</Button>
				);
			}
		}

		return value;
	};

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow sx={{ fontWeight: "900" }}>
						{headers.map((header, index) => (
							<TableCell key={index}>{cleanString(header)}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{Array.isArray(data) && values.map((row, index) => {
						return (
							<TableRow key={index}>
								{headers.map((header, index) => (
									<TableCell key={index}>{renderCellValue(row[header])}</TableCell>
								))}
							</TableRow>
						);
					})}
					{(typeof data === "object" && data.constructor === Object) &&
						<TableRow key="audit-object-row">
							{values.map((row, index) => {
								return (
									<TableCell key={index}>{renderCellValue(row)}</TableCell>
								);
							})}
						</TableRow>
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default BasicTable;
