import React from "react";
import { Grid } from "@mui/material";
import { AlertBox } from "../Alert/Alert";

const ChooseSellerMessage = () => {
	return (
		<Grid item xs={12} lg={12} mb={4}>
			<AlertBox 
				severity="error"
				message="Please choose seller from dropdown"
			/>
		</Grid>
	);
};

export default function SellerDependentView(props) {
	return (!props.seller) ? <ChooseSellerMessage /> : <>{props.children}</>;
}
