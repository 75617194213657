import React, {
	useState,
	useRef,
} from "react";
import {
	Paper,
	Box,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	CircularProgress,
} from "@mui/material";
import { DeleteButton } from "../../Shared/Buttons/Buttons";
import { Auth } from "aws-amplify";
import { capitalizeFirstLetter } from "../../../utils/text";
import { convertToBase64String } from "../../../utils/file";

export default function UploadATemplatePanel({ seller }) {

	const [state, setState] = useState({
		selectedFile: null,
		fileUploaded: false,
		formSubmitted: false,
		enableUploadTemplateButton: false,
		uploadResponseSuccess: {},
		uploadResponseErrors: {},
		showModal: false,
		modalLoading: false,
		modalTitle: "",
		modalBody1: "",
		modalBody2: "",
	});

	const isUploadButtonDisabled = () => {
		return (state.fileUploaded === false || state.formSubmitted === true);
	};

	const onClickDeleteUploadFile = () => {
		uploadInputRef.current.value = "";
		setState(previousState => {
			return {
				...previousState,
				selectedFile: null,
				fileUploaded: false,
			};
		});
	};

	const uploadInputRef = useRef();
	const onChangeGetFileUpload = (e) => {
		setState(previousState => {
			return {
				...previousState,
				selectedFile: e.target.files[0],
				fileUploaded: true,
				formSubmitted: false,
			};
		});
	};

	const createProductsFromFileViaWebsocket = async (sellerID, file) => {
		const data = await Auth.currentSession();
		const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_API_URL + `?token=${data.accessToken.jwtToken}`);
		const fileDataQueue = [];
		let errors = {};

		ws.onmessage = (event) => {
			const data = JSON.parse(event.data);

			if (data.errors && Object.keys(data.errors).length !== 0) {
				errors = data.errors;

				setState(previousState => {
					return {
						...previousState,
						uploadResponseSuccess: data.successes,
						uploadResponseErrors: data.errors,
						modalTitle: "Unable to upload products",
						modalBody1: "The following errors were encountered while uploading:",
						modalBody2: data.errors,
					};
				});
			} else if (data.message === "upload complete") {
				ws.close();

				if (errors && Object.keys(errors).length !== 0) {
					setState(previousState => {
						return {
							...previousState,
							modalTitle: "Unable to upload products",
							modalBody1: "The following errors were encountered while uploading:",
							modalBody2: errors,
						};
					});
				} else {
					setState(previousState => {
						return {
							...previousState,
							modalTitle: "Successfully uploaded all products",
							modalBody1: "All products have been successfully uploaded",
							modalBody2: "Note: It can take a few minutes for these products to appear in your product summary",
						};
					});
				}
			} else if (data.message === "file data received") {
				let fileData = fileDataQueue.shift();
				if (fileData === undefined) {
					fileData = "";
				}

				ws.send(JSON.stringify({
					"action": "import_products",
					"data": {
						"seller": sellerID,
						"file_name": file.name,
						"file_data": fileData,
					}
				}));
			} else if (data.message) {
				ws.close();

				setState(previousState => {
					return {
						...previousState,
						modalTitle: "Unable to upload products",
						modalBody1: "An internal error occurred, please contact Bezos support.",
					};
				});
			}
		};

		ws.onerror = () => {
			ws.close();

			setState(previousState => {
				return {
					...previousState,
					modalTitle: "Unable to upload products",
					modalBody1: "An internal error occurred, please contact Bezos support.",
				};
			});
		};

		ws.onopen = async () => {
			const chunkSize = 30000;
			const base64File = await convertToBase64String(file);

			setState(previousState => {
				return {
					...previousState,
					showModal: true,
					modalTitle: "Processing File",
					modalBody1: file.name,
					formSubmitted: true,
				};
			});

			for (let start = 0; start < base64File.length; start += chunkSize) {
				const base64Chunk = base64File.slice(start, start + chunkSize);
				fileDataQueue.push(base64Chunk);
			}

			ws.send(JSON.stringify({
				"action": "import_products",
				"data": {
					"seller": sellerID,
					"file_name": file.name,
					"file_data": fileDataQueue.shift(),
				}
			}));
		};
	
	};

	const onClickUploadTemplate = async () => {
		if (!seller) return;

		await createProductsFromFileViaWebsocket(seller, state.selectedFile);

		setState(previousState => {
			return {
				...previousState,
				modalLoading: false,
				selectedFile: null,
				fileUploaded: false,
				enableUploadTemplateButton: false,
			};
		});
		uploadInputRef.current.value = "";
	};

	const SubmitPageModalAlert = () => {
		const handleClose = () => {
			setState(previousState => {
				return {
					...previousState,
					showModal: false,
					modalTitle: "",
					modalBody1: "",
					modalBody2: "",
					uploadResponseErrors: {},
					uploadResponseSuccess: {},
				};
			});
		};

		const Transition = React.forwardRef(function Transition(props, ref) {
			return <Slide direction="up" ref={ref} {...props} />;
		});

		return (
			<Dialog
				open={state.showModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
				fullWidth={true}
				maxWidth="sm"
			>
				{(state.modalLoading === true) ?
					(<>
						<DialogTitle>Loading...</DialogTitle>
						<DialogContent sx={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress/>
						</DialogContent>
					</>)
					:
					(
						(state.uploadResponseSuccess === null && state.uploadResponseErrors === null) ?
							(<>
								<DialogTitle>Unable to upload products</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-slide-description" component="div">
										No products found
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button
										variant="contained"
										onClick={handleClose}
									>
										Confirm
									</Button>
								</DialogActions>
							</>)
							:
							(<>
								<DialogTitle>{state.modalTitle}</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-slide-description" component="div">
										{state.modalBody1}
									</DialogContentText>
									{(typeof state.uploadResponseErrors === "object" && state.uploadResponseErrors != null) ?
										(<DialogContentText id="alert-dialog-slide-description" component="div">
											<ul>
												{Object.values(state.uploadResponseErrors).map((value, index) => (
													<li key={index}>
														{capitalizeFirstLetter(value)}
													</li>
												))}
											</ul>
										</DialogContentText>)
										:
										(<DialogContentText id="alert-dialog-slide-description" component="div">
											<br/>{state.modalBody2}
										</DialogContentText>)
									}
								</DialogContent>
								<DialogActions>
									<Button
										variant="contained"
										onClick={handleClose}
									>
										Confirm
									</Button>
								</DialogActions>
							</>)
					)
				}
			</Dialog>
		);
	};

	return (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Box mb={1}>
				<Typography variant="h5" component="h2" mb={2}>
					Upload Product Template
				</Typography>
			</Box>

			<Box mb={3}>
				<input
					type="file"
					name="File Upload"
					accept='text/csv'
					ref={uploadInputRef}
					onChange={(e) => onChangeGetFileUpload(e)}
				/>
				{(state.fileUploaded === true) &&
					<Button title="Remove file">
						<DeleteButton
							onClick={() => onClickDeleteUploadFile()}
							icon={true}
						/>
					</Button>
				}
			</Box>

			<Box mb={1}>
				<Button
					variant="contained"
					component="span"
					onClick={() => onClickUploadTemplate()}
					disabled={isUploadButtonDisabled()}
				>
					Upload
				</Button>
			</Box>

			<SubmitPageModalAlert/>
		</Paper>
	);
}