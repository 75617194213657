import React from "react";
import { Box } from "@mui/material";
import {
	getStatusColor,
	getOrderStatusLabel,
	getASNStatusLabel,
	getCourierStatusLabel,
	getASNStatusColor,
	getASNDeliverySlotStatusLabel,
	getASNDeliverySlotStatusColor,
	getReportStatusLabel,
	getClaimsStatusColor,
	getInventoryStatusLabel,
	getInventoryStatusColor
} from "../../../utils/statusColors";
import { styled } from "@mui/material/styles";

export const StatusBox = ({ status, statusType, sx }) => {

	if (status === "") return "";

	const StatusText = () => {
		switch (statusType) {
		case "inventory":
			return getInventoryStatusLabel(status);			
		case "order":
			return getOrderStatusLabel(status);
		case "asn":
			return getASNStatusLabel(status);
		case "delivery":
			return getASNDeliverySlotStatusLabel(status);
		case "reports":
			return getReportStatusLabel(status);			
		default:
			return getCourierStatusLabel(status);
		}
	};

	const statusColor = () => {
		if (statusType === "asn") return getASNStatusColor(status);		
		if (statusType === "delivery") return getASNDeliverySlotStatusColor(status);
		if (statusType === "claims") return getClaimsStatusColor(status);
		if (statusType === "inventory") return getInventoryStatusColor(status);
		return getStatusColor(status);
	};

	const StyledBox = styled(Box)(({ theme }) => ({
		backgroundColor: theme.palette.statusbox.main,
		padding: "0",
		minWidth: "100px",
	}));

	const statusTextValue = StatusText();

	return (
		<StyledBox 
			component="span" 
			className="status-button"
			sx={{
				...sx,
				border: `1px solid ${statusColor(status)}`,
				color: statusColor(status),
			}}
		>
			{statusTextValue?.toUpperCase() ?? "UNKNOWN STATUS"}
		</StyledBox>
	);

};
