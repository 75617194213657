import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	Button,
	MenuItem,
	Menu,
	ListItemText,
	ListItemIcon,
	Fade
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";
import SearchDetail from "@mui/icons-material/ContentPasteSearchTwoTone";

export const ViewButtonSingle = ({
	buttonTitle,
	buttonLink,
	buttonProps,
	buttonOnClick
}) => {

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				variant="outlined"
				id="fade-button"
				aria-controls="fade-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				disableElevation
			>
				<MoreHorizIcon fontSize="small"/>
			</Button>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				elevation={5}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem component={!buttonOnClick ? Link : null} to={`${buttonLink + buttonProps}`} onClick={buttonOnClick}>
					<ListItemIcon>
						<SearchDetail/>
					</ListItemIcon>
					<ListItemText>
						{buttonTitle}
					</ListItemText>
					<ArrowRight/>
				</MenuItem>
			</Menu>
		</>
	);

};
