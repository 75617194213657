import React, { useState } from "react";
import { DataContext } from "../../../App";
import Modal from "../Modals/Modal";
import {
	Button,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Box
} from "@mui/material";
import DeliveryProof from "@mui/icons-material/FactCheck";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";

export default function PODRequest({
	buttonName, buttonType, orderNumber,
	trackingNumber, courierService, sellerID, podRequestSent
}) {
	const context = React.useContext(DataContext);
	const mapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

	const [state, setState] = useState({
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const onClickRequestProofOfDelivery = () => {
		const getProofOfDeliveryStatus = async () => {
			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
					modalBodyTitle: "Request Proof of Delivery",
					modalBodyMessage1: state.modalBodyMessage1,
					modalBodyMessage2: state.modalBodyMessage2,
					modalButtonVariant1: "outlined",
					modalButtonText1: "Close",
					modalButtonActionType1: "close",
					modalButtonAction1: "",
				};
			});

			if (podRequestSent === true) {
				setState(previousState => {
					return {
						...previousState,
						modalBodyMessage1: "You have already submitted this request.",
						modalBodyMessage2: "You will receive email confirmation of your request shortly, if you do not receive confirmation please contact support@bezos.ai",
					};
				});
				return;
			}

			const hasProofOfDelivery = await context.dataProvider.getProofOfDelivery(orderNumber, sellerID, trackingNumber, courierService)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							modalSize: "md",
							modalBodyTitle: "Proof of Delivery",
							modalBodyMessage1: res.data.scan_time ? "Last scan time: " + res.data.scan_time : "",
							modalBodyMessage1Data: <ProofOfDelivery image={res.data.image} location={res.data.location} />,
						};
					});
					return true;
				})
				.catch(() => {
					return false;
				});

			if (hasProofOfDelivery) {
				return;
			}

			return context.dataProvider.createProofOfDeliveryRequest(orderNumber, sellerID)
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyMessage1: "Proof of Delivery request, successfully sent.",
							modalBodyMessage2: "You will receive email confirmation of your request shortly. We will also email you the Proof of Delivery once your request has been completed.",
						};
					});
				})
				.catch(error => {
					if (error.response.status === 409) {
						setState(previousState => {
							return {
								...previousState,
								modalBodyMessage1: "You have already submitted this request.",
								modalBodyMessage2: "You will receive email confirmation of your request shortly, if you do not receive confirmation please contact support@bezos.ai",
							};
						});
					} else {
						setState(previousState => {
							return {
								...previousState,
								modalBodyMessage1: "Error: Please try again or contact support@bezos.ai",
								modalBodyMessage2: "",
							};
						});
					}
				});

		};
		getProofOfDeliveryStatus();
	};

	const onModalClose = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};

	const ProofOfDelivery = ({ image, location }) => {
		return (
			<>
				{(image.data && image.type) &&
					<Box
						sx={{ width: "46%", height: "90%", m: "2%" }}
						component="img"
						alt={"Proof of Delivery"}
						src={`data:image/${image.type};base64,${image.data}`}
					/>
				}
				{(location.longitude && location.latitude) &&
					<Box
						sx={{ width: "46%", height: "90%", m: "2%", border: 0 }}
						component="iframe"
						frameBorder="0"
						src={`https://www.google.com/maps/embed/v1/place?key=${mapsAPIKey}&q=${location.latitude},${location.longitude}`}
						referrerPolicy="no-referrer-when-downgrade"
						allowFullScreen
					/>
				}
			</>
		);
	};

	return (
		<>
			{buttonType === "button" ?
				<Button
					variant="outlined"
					color="secondary"
					onClick={onClickRequestProofOfDelivery}
				>
					{buttonName}
				</Button>
				:
				<MenuItem
					data-testid="menu-button"
					onClick={onClickRequestProofOfDelivery}
				>
					<ListItemIcon>
						<DeliveryProof />
					</ListItemIcon>
					<ListItemText>
						{buttonName}
					</ListItemText>
					<ArrowRight />
				</MenuItem>
			}

			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalSize={state.modalSize}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalBodyInputFields={state.modalBodyInputFields}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
			/>
		</>
	);

}
