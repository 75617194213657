
import {capitalizeFirstLetter} from "./text.js";

export const getReportTypeSelectLabels = (name) => {
	switch (name.toLowerCase()) {
	case "orders":
		return "Orders - All Orders";
	case "returns":
		return "Returns - All Returns";
	case "inventory":
		return "Inventory - Levels Today";
	case "on_back_order":
		return "On Back Order Products - Levels Today";
	case "despatch":
		return "Despatched Orders";
	case "product_sales_by_channel":
		return "Product Sales - By Channel";
	case "order_claims":
		return "Claims - All Order Claims";
	case "historic_inventory":
		return "Inventory - Historic Levels";
	case "batch_information":
		return "Batch/Serial Information";
	case "product_alt_codes":
		return "Product Alt Codes";
	case "bundles":
		return "Bundles";
	case "sales_by_sku":
		return "Sales By SKU";
	case "all_products":
		return "All Products";
	default:
		return capitalizeFirstLetter(name.replace("_", " "));
	}
};

export const getReportTypeNameFromID = (id) => {
	switch (id) {
	case 1:
		return "All Orders";
	case 2:
		return "All Returns";
	case 3:
		return "Inventory Levels: Today";
	case 4:
		return "On Back Order Products: Today";
	case 5:
		return "Despatched Orders";
	case 6:
		return "Product Sales By Channel";
	case 7:
		return "All Claims";
	case 8:
		return "Historic Inventory";
	case 9:
		return "Batch/Serial Information";
	case 10:
		return "Product Alt Codes";
	case 11:
		return "Bundles";
	case 12:
		return "Sales By SKU";
	case 13:
		return "All Products";
	default:
		return (`Report ID: ${id}`);
	}
};

export const getScheduledReportsFrequency = () => {
	const data = ["Daily", "Weekly", "Monthly"];
	return data.map((name, index) => ({
		id: index + 1,
		name: name,
	}));
};

export const getDaysOfTheWeek = () => {
	const data = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	return data.map((name, index) => ({
		id: index,
		name: name,
	}));
};

export const getDatesOfTheMonth = () => {
	const data = Array.from({ length: 28 }, (_, index) => index);
	return data.map((day) => ({
		id: day,
		name: getDayName(day + 1),
	}));
};
const getDayName = (day) => {
	const suffixes = ["th", "st", "nd", "rd"];
	const digit = day % 10;
	const suffix = day > 10 && day < 20 ? suffixes[0] : suffixes[digit] || suffixes[0];
	return `${day}${suffix}`;
};
  
  
  
