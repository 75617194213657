// Handles async requests with a monotonically increasing clock, to prevent earlier requests overwriting
// later ones when responses arrive out of order
export default class ClockedRequest {
	constructor() {
		this.state = {
			fetchFn: function() {},
			pathParams: {},
			queryParams: URLSearchParams,
			requestClock: 0,
			responseClock: 0,
			data: {},
			isFetching: false,
		};
	}

	setFetchFn = (fn) => {
		this.state.fetchFn = fn;
	};
	setPathParams = (params) => {
		this.state.pathParams = params;
	};
	setQueryParams = (params) => {
		this.state.queryParams = params;
	};

	isFetching = () => this.state.isFetching;

	fetch = async () => {
		this.state.requestClock++;
		this.isFetching = true;
		const thisRequestClock = this.state.requestClock;

		const res = await this.state.fetchFn(
			this.state.pathParams,
			this.state.queryParams,
		);

		if (thisRequestClock > this.state.responseClock) {
			this.state.responseClock = thisRequestClock;
			this.state.data = res?.data;
		}

		this.isFetching = false;
		return this.state.data;
	};
}
