export const checkIfOrderSentToWarehouse = (date) => {
	if (date === "0001-01-01 00:00" || date === "0001-01-01T00:00:00Z" || date === null || date === "" || date === undefined) {
		return (
			false
		);
	} else {
		return (
			true
		);
	}
};
