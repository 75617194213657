const productBarcodeTypes = [
	{
		id: 0,
		name: "None",
	},
	{
		id: 1,
		name: "EAN",
	},
	{
		id: 2,
		name: "UPC",
	},
	{
		id: 3,
		name: "Other",
	}
];

export const getProductBarcodeTypes = () => {
	return productBarcodeTypes;
};

export const getProductBarcodeTypeName = (id) => {
	let typeName = "";

	for (let type of productBarcodeTypes) {
		if (type.id === id) {
			typeName = type.name;
			break;
		}
	}

	return typeName;
};
