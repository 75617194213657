import React from "react";
import {
	Breadcrumbs,
	Box,
	Typography,
	Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const BreadCrumb = ({ breadcrumbs }) => {

	if (!breadcrumbs || breadcrumbs.length === 0) return;

	return (
		<Box className="bread-crumb">
			<Breadcrumbs aria-label="breadcrumb">
				{breadcrumbs.map((crumb, index) => (
					index === breadcrumbs.length - 1 ? (
						<Typography 
							key={index} 
							color="inherit"
						>
							{crumb.label ?? "-"}
						</Typography>
					) : (
						<Link 
							key={index} 
							color="inherit"
							component={RouterLink} 
							to={crumb.url}
							sx={{ 
								display: "inline-block",
								padding: "4px",
								margin: 0,
								backgroundColor: "primary",
								borderRadius: "4px",
								textDecoration: "underline",
								"&:hover": {
									backgroundColor: theme => theme.palette.statusbox.main,
								}
							}}
						>
							{crumb.label ?? "-"}
						</Link>
					)
				))}
			</Breadcrumbs>
		</Box>
	);

};
