import React, {
	useEffect,
	useState
} from "react";
import { getDisplayTime } from "../../utils/date.js";
import {
	Box,
	Dialog,
	DialogTitle,
	Paper,
	Slide,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { DataContext } from "../../App";
import { StatusBox } from "../Shared/StatusBox/StatusBox.jsx";

export default function OrderStatusDetail({ orderNumber, onClose }) {
	const context = React.useContext(DataContext);

	const [state, setState] = useState({
		trackingData: [],
		token: "",
		status: true,
		orderNumber: orderNumber
	});

	useEffect(() => {
		if (state.orderNumber === "") {
			return;
		}

		const getTrackingData = async () => {
			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			await context.dataProvider.getWIMODetail(state.orderNumber)
				.then(response => {
					setState(previousState => {
						return {
							...previousState,
							trackingData: response.data,
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							status: false,
						};
					});
				});

			setState(previousState => {
				return {
					...previousState,
					isLoading: false,
				};
			});

		};
		getTrackingData();
	}, [state.orderNumber, context.dataProvider]);

	const DetailTableRows = () => {
		return (state.trackingData.map((item, index) => <TableRow key={index}>
			<TableCell>{item.order_number}</TableCell>
			<TableCell>{getDisplayTime(item.event_time)}</TableCell>
			<TableCell>{item.event_message}</TableCell>
			<TableCell>{item.location}</TableCell>
			<TableCell>
				<StatusBox 
					status={item.tag} 
					statusType="courier" 
				/>
			</TableCell>
		</TableRow>));
	};

	const Detail = () => {
		return (
			<Box>
				{(state.status) ?
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Order Number</TableCell>
									<TableCell>Event Time</TableCell>
									<TableCell>Message</TableCell>
									<TableCell>Location</TableCell>
									<TableCell>Courier Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<DetailTableRows />
							</TableBody>
						</Table>
					</TableContainer>
					:
					<Typography>Error encountered whilst fetching tracking data.</Typography>
				}
			</Box>
		);
	};

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	return (
		<Dialog
			maxWidth="lg"
			open={!state.isLoading}
			TransitionComponent={Transition}
			onClose={onClose}
		>
			<Paper sx={{ p: 1 }}>
				<DialogTitle>Courier Detail: {orderNumber}</DialogTitle>
				<Detail />
			</Paper>
		</Dialog>
	);
}
