import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import "./index.css";
import App from "./App";
import { Authenticator } from "@aws-amplify/ui-react";
import reportWebVitals from "./reportWebVitals";
import { 
	Amplify, 
	Auth } 
	from "aws-amplify";
import ErrorBoundary from "./utils/errorBoundary";

// Configure Amplify
Amplify.configure({
	Auth: {
		region: "eu-west-1",
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID
	}
});
Auth.configure();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<StrictMode>
		<ErrorBoundary>
			<Authenticator.Provider>
				<Provider store={store}>
					<App />
				</Provider>
			</Authenticator.Provider>
		</ErrorBoundary>
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
