import React from "react";
import {
	Typography,
	Button,
} from "@mui/material";
import { Link } from "react-router-dom";

export const BackButton = ({ message, link }) => {
	
	if (!message || !link) return;
     
	return (
		<>
			<Typography component="h1" variant="h6" mb={2}>
				{message}
			</Typography>
			<Button variant="contained" component={Link} to={link}>
                Go Back
			</Button>
		</>
	);
};
