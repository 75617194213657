import React, {
	useEffect,
	useState
} from "react";
import { Link } from "react-router-dom";
import SellerDependentView from "../Shared/SellerDependentView";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Pagination,
	Button
} from "@mui/material";
import * as tableConstants from "../Shared/Config/TableConfig";
import AddIcon from "@mui/icons-material/Add";
import "./GoodsInStatus.scss";
import { getPageValue } from "../../utils/pagination";
import GoodsInTable from "./components/GoodsInTable";
import PageRowsDropDown from "../Shared/PageRows/PageRows";
import { DataContext } from "../../App";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";

export default function ASNSummary({ seller, userdata }) {

	const context = React.useContext(DataContext);
	const pageRowOptions = tableConstants.PAGINATION_OPTIONS;
	const page_data = {
		selectedSeller: seller,
	};

	const [state, setState] = useState({
		isLoading: false,
		resultsData: [],
		resultsTotalCount: 0,
		summaryData: [],
		paginationCurrentPage: 1,
		paginationPostsPerPage: pageRowOptions[0],
		paginationPages: 0
	});

	useEffect(() => {

		if (!page_data.selectedSeller) return;

		const getSummaryDataTotalOnly = async () => {
			await context.dataProvider.getSellerASNTotal(page_data.selectedSeller)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							resultsTotalCount: res.data,
							paginationPages: Math.ceil(Number(res.data) / Number(state.paginationPostsPerPage)),
						};
					});
				});
		};

		const getSummaryData = () => {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			getSummaryDataTotalOnly();

			context.dataProvider.getSellerASNs(page_data.selectedSeller, state.paginationCurrentPage, state.paginationPostsPerPage)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							resultsData: res.data,
							summaryData: res.data.sort((a, b) => a.id > b.id ? -1 : 1),
							isLoading: false,
						};
					});
				});
		};

		getSummaryData();
	}, [page_data.selectedSeller, state.paginationCurrentPage, state.paginationPostsPerPage, context.dataProvider]);

	let paginationView;
	if (state.resultsTotalCount <= state.paginationPostsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={state.paginationPages}
				page={state.paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							paginationCurrentPage: getPageValue(input, state.paginationCurrentPage),
						};
					});
				}}
			/>
		);
	}

	const onPageRowChange = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				paginationPostsPerPage: pageRows,
				paginationCurrentPage: 1,
			};
		});
	};

	return (
		<Box id="goods-in-status">
			<SellerDependentView seller={seller}>
				<Box>
					<Grid item xs="auto" sx={{ mb: 3 }}>
						<Button variant="contained" component={Link} to={"/asn-create"}>
							Create ASN<AddIcon />
						</Button>
					</Grid>
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							Goods In <RecordsTotal total={state.resultsTotalCount} />
						</Typography>
						<GoodsInTable
							tableData={state.summaryData}
							userdata={userdata}
							isLoading={state.isLoading}
						/>
						<Grid
							container
							padding={2}
							className="table-footer"
						>
							{paginationView}
							<PageRowsDropDown
								resultsTotalCount={state.resultsTotalCount}
								paginationPostsPerPage={state.paginationPostsPerPage}
								pageRowOptions={pageRowOptions}
								onPageRowChange={onPageRowChange}
							/>
						</Grid>
					</Paper>
				</Box>
			</SellerDependentView>
		</Box>
	);
}
