import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	Button,
	MenuItem,
	Menu,
	ListItemText,
	ListItemIcon,
	Fade,
	Box
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";
import SearchDetail from "@mui/icons-material/ContentPasteSearchTwoTone";
import NoteAltTwoToneIcon from "@mui/icons-material/NoteAltTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import Modal from "../Shared/Modals/Modal";
import { DataContext } from "../../App";
import DownloadDocument from "../Shared/DownloadDocument/DownloadDocument";

export default function GoodsInStatusButton({ asn, asnStatus, hasPaperwork }) {
	
	const context = React.useContext(DataContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [state, setState] = useState({
		warehouse_id: "",
		shippingDetailsForm: {},
		addProductsForm: {},
		isASNDraft: false,
		pageTitle: "Create ASN",
		pageSubTitle: "",
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const asnNumber = asn;
	const hasPaperworkStatus = hasPaperwork; // show `View ASN document` link if true

	const onClickDeleteASNDraft = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Delete ASN draft " + asn,
				modalBodyMessage1: "Are you sure you'd like to delete this ASN draft? This action cannot be undone.",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Delete ASN draft",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickDeleteASNDraftModalConfirmation,
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onClickDeleteASNDraftModalConfirmation = async () => {

		// close confirm modal
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "", // ensures 2nd button does not render in modal
			};
		});

		await context.dataProvider.deleteASN(asn)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN draft " + asn + ", successfully deleted",
						modalBodyMessage1: "This ASN draft has been deleted",
						modalBodyMessage2: "Note: It can take a few minutes for these changes to update in your ASN summary.",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/asn-summary",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN draft " + asn + ", deletion was unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try deleting the ASN draft again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Go to ASN Draft " + asn,
						modalButtonActionType1: "link",
						modalButtonAction1: "/asn-create/" + asn, // empty if action type = close
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	return (
		<>
			<Button
				variant="outlined"
				id="fade-button"
				aria-controls="fade-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				disableElevation
			>
				<MoreHorizIcon fontSize="small" />
			</Button>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				elevation={5}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{(asnStatus.toLowerCase() === "draft") ?
					<Box>
						<MenuItem component={Link} to={"/asn-create/" + asnNumber}>
							<ListItemIcon>
								<NoteAltTwoToneIcon />
							</ListItemIcon>
							<ListItemText>
								Edit ASN Draft
							</ListItemText>
							<ArrowRight />
						</MenuItem>
						<MenuItem onClick={onClickDeleteASNDraft}>
							<ListItemIcon>
								<DeleteForeverTwoToneIcon />
							</ListItemIcon>
							<ListItemText>
								Delete ASN Draft
							</ListItemText>
							<ArrowRight />
						</MenuItem>
					</Box>
					:
					<MenuItem component={Link} to={"/asn-detail/" + asnNumber}>
						<ListItemIcon>
							<SearchDetail />
						</ListItemIcon>
						<ListItemText>
							View ASN Details
						</ListItemText>
						<ArrowRight />
					</MenuItem>
				}

				{hasPaperworkStatus &&
				<DownloadDocument
					name="ASN Document"
					pageType="summary"
					id={asnNumber}
				/>
				}
			</Menu>
			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalBodyInputFields={state.modalBodyInputFields}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
			/>
		</>
	);

}
