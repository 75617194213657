import React, {
	useContext,
	useCallback,
	useMemo
} from "react";
import useFetchData from "../FetchData/FetchData";
import { DataContext } from "../../../App";
import { LoadableContent } from "../LoadableContent/LoadableContent";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
} from "@mui/material";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CardGiftcardIcon from "@mui/icons-material/Input";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
	getDisplayTime,
	getDisplayDate,
	isDateNull,
	isDateValidAndMatch,
} from "../../../utils/date";

const OrderTimeline = ({ orderID, seller }) => {

	const context = useContext(DataContext);
	const timelineData = useFetchData(useCallback(() => context.dataProvider.getOrderTimeline(seller, orderID), [context.dataProvider, seller, orderID]));

	const page_data = {
		expected: {
			sent_to_warehouse: timelineData.results.expected_sent_to_warehouse,
			despatch: timelineData.results.expected_despatch,
			collection: timelineData.results.expected_collection,
			delivery: timelineData.results.expected_delivery,
		},
		actual: {
			sent_to_warehouse: timelineData.results.actual_sent_to_warehouse,
			despatch: timelineData.results.actual_despatch,
			collection: timelineData.results.actual_collection,
			delivery_attempted: timelineData.results.actual_delivery_attempted,
			delivery: timelineData.results.actual_delivery,
		},
	};

	const headerTitles = [
		"Sent to Warehouse", 
		"Despatched", 
		"Collected", 
		"Delivered"
	];

	const iconData = [
		{ icon: WarehouseIcon, key: "sent_to_warehouse" },
		{ icon: CardGiftcardIcon, key: "despatch" },
		{ icon: LocalShippingIcon, key: "collection" },
		{ icon: HomeWorkIcon, key: "delivery" },
	];

	const getColor = useCallback((expectedDate, actualDate) => isDateNull(actualDate) ? "black" : isDateValidAndMatch(expectedDate, actualDate) ? "green" : "red", [isDateNull, isDateValidAndMatch]);

	const timelineTable = useMemo(() => (
		<TableContainer component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="center" sx={{ maxWidth: 75 }}></TableCell>
						{headerTitles.map((title, index) => (
							<TableCell key={index} align="center">{title}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell align="center" sx={{ maxWidth: 75}}></TableCell>
						{iconData.map(({ icon: Icon, key }) => (
							<TableCell key={key} align="center">
								<Icon fontSize="large" color={isDateNull(page_data.actual[key]) ? "disabled" : "primary"} />
							</TableCell>
						))}
					</TableRow>
					<TableRow>
						<TableCell align="center" sx={{ maxWidth: 75, fontStyle: "italic", fontSize: "0.65rem" }}>EXPECTED</TableCell>
						{Object.keys(page_data.expected).map((key) => (
							<TableCell key={`expected-${key}`} align="center" style={{ color: getColor(page_data.expected[key], page_data.actual[key]) }}>
								{
									key === "sent_to_warehouse"
										? getDisplayTime(page_data.expected[key], "-", "/")
										: getDisplayDate(page_data.expected[key], "-", "/")
								}
							</TableCell>
						))}
					</TableRow>
					<TableRow>
						<TableCell 
							align="center" 
							sx={{ maxWidth: 75, fontStyle: "italic", fontSize: "0.65rem" }}
						>
							ACTUAL
						</TableCell>
						{Object.keys(page_data.expected)
							.map((key) => {
								if (key === "delivery" && isDateNull(page_data.actual.delivery)) {
									if (!isDateNull(page_data.actual.delivery_attempted)) {
										return (
											<TableCell 
												key={"actual-delivery-attempted"} 
												align="center"
											>
												{getDisplayTime(page_data.actual.delivery_attempted, "-", "/")}
												<Box 
													sx={{ fontStyle: "italic", fontSize: "0.65rem" }}
												>
													delivery attempted
												</Box>
											</TableCell>
										);
									}
									// Return a cell with '-' if both 'delivery' and 'delivery_attempted' are isDateNull
									return <TableCell key={"actual-delivery-attempted"} align="center">-</TableCell>;
								} else {
									// For other keys, render normally
									return (
										<TableCell 
											key={`actual-${key}`} 
											align="center" 
											style={{ color: getColor(page_data.expected[key], page_data.actual[key]) }}
										>
											{getDisplayTime(page_data.actual[key], "-", "/")}
										</TableCell>
									);
								}
							})
						}
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	), [timelineData, getColor]);

	return (
		<LoadableContent isLoading={timelineData.isLoading}>
			{timelineTable}
		</LoadableContent>
	);
};

export default OrderTimeline;
