import React, { 
	useState,
	useEffect 
} from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import ShippingDetails from "./views/ShippingDetails/ShippingDetails";
import {
	Grid,
	Typography,
	Box,
	Paper
} from "@mui/material";
import "./OrderCreate.scss";
import AddProducts from "../Shared/AddProducts";
import AdditionalDetails from "./views/AdditionalDetails";
import SellerDependentView from "../Shared/SellerDependentView";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import { scrollToTop } from "../../utils/scrollToTop";
import { capitalizeFirstLetter } from "../../utils/text";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

const OrderCreate = ({ seller, userdata }) => {
	
	const location = useLocation();
	const existingOrder = location.state?.existingOrder;
	const existingOrderInvoiceDocuments = location.state?.existingOrderInvoiceDocuments;
	const existingOrderShippingDocuments = location.state?.existingOrderShippingDocuments;
	const orderOperation = location.state?.orderOperation;

	const [state, setState] = useState({
		warehouse_id: "",
		isDomestic: true,
		title: "Create",
		shippingDetailsForm: {},
		addProductsForm: {},
		panelsVisible: {
			isShippingDetailsPanelVisible: true,
			isAddProductsPanelVisible: false,
			isAdditionalDetailsPanelVisible: false,
		},
		orderError: {
			orderNumber: "",
			errorMessage: "",
		},
	});

	const onPanelsVisibleChange = (props) => {
		setState(previousState => {
			return {
				...previousState,
				panelsVisible: props,
			};
		});
	};

	const onOrderError = (props) => {
		if (!props) return;

		onPanelsVisibleChange({
			isShippingDetailsPanelVisible: true,
			isAddProductsPanelVisible: false,
			isAdditionalDetailsPanelVisible: false,
		});
		scrollToTop();
		setState(previousState => {
			return {
				...previousState,
				orderError: props,
			};
		});
	};

	const onWarehouseChange = (id) => {
		setState(previousState => {
			return {
				...previousState,
				warehouse_id: id
			};
		});
	};

	const onShippingDetailsFormSubmit = (props) => {
		setState(previousState => {
			return {
				...previousState,
				shippingDetailsForm: props,
			};
		});
	};

	const onAddProductsFormSubmit = (props) => {
		setState(previousState => {
			return {
				...previousState,
				addProductsForm: props,
			};
		});
	};

	const onIsDomesticChange = (isDomestic) => {
		setState(previousState => {
			return {
				...previousState,
				isDomestic: isDomestic,
			};
		});
	};

	useEffect(() => {

		if (!orderOperation) return;

		setState(previousState => {
			return {
				...previousState,
				title: capitalizeFirstLetter(orderOperation),
			};
		});

	}, [orderOperation]);

	const TitleHeading = () => {
		return (
			<Typography component="h1" variant="h5" mt={2} mb={1}>
				{state.title} Order
			</Typography>
		);
	};

	return (
		<Box id="order-create">
			<PermissionDependentView userdata={userdata} permission="create_order">
				<SellerDependentView seller={seller}>
					<BreadCrumb
						breadcrumbs={[
							{ label: "Orders", url: "/order-status" },
							{ label: `${state.title} Order`}
						]}
					/>
					<TitleHeading />
					<Box>
						<Grid container spacing={1}>
							<Box
								className={`panel${state.panelsVisible.isShippingDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
								<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
									<Typography component="h3" variant="h6">1. Add Shipping Details</Typography>
									<Box
										className={`panel${state.panelsVisible.isShippingDetailsPanelVisible === true ? "-show" : "-hide"}`}>
										<ShippingDetails
											sellerID={seller}
											existingOrder={existingOrder}
											onWarehouseChange={onWarehouseChange}
											onShippingDetailsFormSubmit={onShippingDetailsFormSubmit}
											onIsDomesticChange={onIsDomesticChange}
											onPanelsVisibleChange={onPanelsVisibleChange}
											orderError={state.orderError}
											orderOperation={orderOperation}
										/>
									</Box>
								</Paper>
							</Box>

							<Box
								className={`panel${state.panelsVisible.isAddProductsPanelVisible === true ? "-active" : "-disabled"}`}>
								<Paper sx={{ p: 2, width: "100%", marginY: 1 }} elevation={2}>
									<Typography component="h3" variant="h6">2. Add Products</Typography>
									<Box
										className={`panel${state.panelsVisible.isAddProductsPanelVisible === true ? "-show" : "-hide"}`}>
										{state.warehouse_id && <AddProducts
											sellerID={seller}
											cartName="Cart"
											isStockAvailableColumnVisible={true}
											warehouseID={state.warehouse_id}
											onAddProductsFormSubmit={onAddProductsFormSubmit}
											initialCart={existingOrder?.items}
											onPanelsVisibleChange={onPanelsVisibleChange}
										/>
										}
									</Box>
								</Paper>
							</Box>

							<Box
								className={`panel${state.panelsVisible.isAdditionalDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
								<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
									<Typography component="h3" variant="h6">3. Additional Details</Typography>
									<Box
										className={`panel${state.panelsVisible.isAdditionalDetailsPanelVisible === true ? "-show" : "-hide"}`}>
										<AdditionalDetails
											sellerID={seller}
											existingOrder={existingOrder}
											existingOrderInvoiceDocuments={existingOrderInvoiceDocuments}
											existingOrderShippingDocuments={existingOrderShippingDocuments}
											shippingDetailsForm={state.shippingDetailsForm}
											addProductsForm={state.addProductsForm}
											isDomestic={state.isDomestic}
											onPanelsVisibleChange={onPanelsVisibleChange}
											onOrderError={onOrderError}
											orderOperation={orderOperation}
										/>
									</Box>
								</Paper>
							</Box>

						</Grid>
					</Box>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);

};

OrderCreate.propTypes = {
	seller: PropTypes.number,
	existingOrder: PropTypes.object
};

export default OrderCreate;