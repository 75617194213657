import * as Yup from "yup";

const countryNameUK = "UNITED KINGDOM";

export const requiresContactDetails = (warehouse, country) => {
	const requiresContactDomestic = warehouse ? warehouse.requires_contact_details_for_domestic_orders : true;
	const requiresContactInternational = warehouse ? warehouse.requires_contact_details_for_international_orders : true;

	if (requiresContactDomestic && (country === countryNameUK || country === "")) {
		return true;
	}

	if (requiresContactInternational && country !== countryNameUK) {
		return true;
	}

	return false;
};

export const registerValidateRequiredContactMethod = (getSelectedWarehouse, getSelectedCountry) => {
	Yup.addMethod(Yup.string, "validateRequiredContact", function(message) {
		return Yup.string().test("requiredContact", message, function(value) {
			const {path, createError} = this;

			if (!value && requiresContactDetails(getSelectedWarehouse(), getSelectedCountry())) {
				return createError({path, message});
			}

			return true;
		});
	});
};

export const registerValidateRequiredForNonUK = (getSelectedCountry) => {
	Yup.addMethod(Yup.string, "validateRequiredForNonUK", function(message) {
		return Yup.string().test("requiredForNonUK", message, function(value) {
			const {path, createError} = this;

			if (!value && getSelectedCountry() !== countryNameUK) {
				return createError({path, message});
			}

			return true;
		});
	});
};
