import React, {
	useState,
	useEffect
} from "react";
import {
	Route,
	Routes,
	useLocation,
	useNavigate
} from "react-router-dom";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import Summary from "../../Summary";
import OrderStatus from "../../OrderStatus";
import OrderView from "../../OrderView";
import OrderCreate from "../../OrderCreate";
import OrderUpload from "../../OrderUpload";
import InvoiceUpload from "../../InvoiceUpload";
import InvoiceView from "../../InvoiceView";
import ProductView from "../../ProductView";
import ProductDetail from "../../ProductDetail";
import ProductUpload from "../../ProductUpload";
import GoodsInStatus from "../../GoodsInStatus";
import GoodsInDetail from "../../GoodsInDetail";
import GoodsInCreate from "../../GoodsInCreate";
import ReturnView from "../../ReturnView";
import ReturnDetail from "../../ReturnDetail";
import LateDeliveries from "../../LateDeliveries/LateDeliveries";
import Claim from "../../Claim/Claim";
import Claims from "../../Claims/Claims";
import ReportsCreate from "../../ReportsCreate";
import AuditLogs from "../../AuditLogs/AuditLogs";
import Hub from "../../Hub/Hub";
import DebugView from "../../DebugView/DebugView";
import SettingsView from "../../SettingsView";
import Error from "../../ErrorPage/ErrorPage";
import Frozen from "../../FrozenPage/FrozenPage";
import { DataContext } from "../../../App";
import ProductCreate from "../../ProductCreate";
import { Alert } from "@mui/material";

export default function MainPanel({ userdata, setSellerAccountActive }) {
	const context = React.useContext(DataContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [features, setFeatures] = useState([]);
	const [showAlert, setShowAlert] = useState(false);

	ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
	}, [location]);

	const seller = useSelector(state => state.SelectedSellerRDX);
	const frozenPage = "/frozen";

	useEffect(() => {
		if (!seller || seller.length === 0 || !seller.status || !seller.id) return;

		const checkIfSellerFrozen = async () => {
			const accessFrozenSeller = await userdata.permissions && userdata.permissions.has("access_frozen_seller");

			if ((seller.status.toLowerCase() === "frozen") && (accessFrozenSeller === false)) {
				setSellerAccountActive(false);
				setShowAlert(false);
				return navigate(frozenPage);
			}

			if ((seller.status.toLowerCase() === "frozen") && (accessFrozenSeller === true)) {
				setShowAlert(true);
			}

			if (seller.status.toLowerCase() !== "frozen") {
				setShowAlert(false);
			}

		};

		const getSellerFeatures = async () => {
			context.dataProvider.getSellerFeatures(seller.id)
				.then(response => {
					setFeatures(response.data);
				});
		};
		
		setSellerAccountActive(true);
		checkIfSellerFrozen();
		getSellerFeatures();
		if (location.pathname === frozenPage) return navigate("/");

	}, [context.dataProvider, seller, userdata]);

	return (
		<>
			{showAlert === true &&
				<Alert 
					severity="error" 
					sx={{ mb:2, border: "1px solid #CCC" }}
				>
					This account is FROZEN!
				</Alert>
			}

			<Routes>
				<Route exact path="/" element={<Summary seller={seller} />} />
				<Route exact path="/home" element={<Summary seller={seller} />} />

				<Route exact path="/order-status" element={<OrderStatus seller={seller.id} userdata={userdata} />} />
				<Route path="/order-view/:id" element={<OrderView seller={seller.id} userdata={userdata} />} />
				<Route exact path="/order-create" element={<OrderCreate seller={seller.id} userdata={userdata} />} />
				<Route exact path="/order-update" element={<OrderCreate seller={seller.id} userdata={userdata} />} />
				<Route exact path="/order-copy" element={<OrderCreate seller={seller.id} userdata={userdata} />} />
				<Route exact path="/order-upload" element={<OrderUpload seller={seller.id} userdata={userdata} />} />

				<Route exact path="/returns" element={<ReturnView seller={seller.id} />} />
				<Route path="/return-detail/:id" element={<ReturnDetail seller={seller.id} />} />

				<Route exact path="/late-deliveries" element={<LateDeliveries seller={seller.id} />} />

				<Route exact path="/claim" element={<Claim seller={seller.id} userdata={userdata} />} />
				<Route exact path="/claims" element={<Claims seller={seller.id} userdata={userdata} />} />

				<Route exact path="/inventory" element={<ProductView seller={seller} userdata={userdata} />} />
				<Route path="/inventory-upload" element={<ProductUpload seller={seller.id} userdata={userdata} />} />
				<Route path="/inventory-detail/:id" element={<ProductDetail seller={seller.id} userdata={userdata} />} />
				<Route exact path="/product-create" element={<ProductCreate seller={seller.id} userdata={userdata}/>}/>
				<Route exact path="/product-update" element={<ProductCreate seller={seller.id} userdata={userdata}/>}/>

				<Route exact path="/asn-summary" element={<GoodsInStatus seller={seller.id} userdata={userdata}/>} />
				<Route path="/asn-detail/:id" element={<GoodsInDetail seller={seller.id} userdata={userdata} />} />
				<Route path="/asn-create/:id" element={<GoodsInCreate seller={seller.id} userdata={userdata} />} />
				<Route exact path="/asn-create" element={<GoodsInCreate seller={seller.id} userdata={userdata}/>} />

				<Route exact path="/reports" element={<ReportsCreate seller={seller.id} userdata={userdata} />} />

				<Route exact path="/invoice-view" element={<InvoiceView seller={seller.id} />} />
				<Route exact path="/invoice-upload" element={<InvoiceUpload seller={seller.id} />} />

				<Route exact path="/audit-logs" element={<AuditLogs seller={seller.id} userdata={userdata} />} />

				<Route exact path="/hub" element={<Hub seller={seller.id} />} />

				<Route exact path="/debug" element={<DebugView seller={seller.id} userdata={userdata} />} />

				<Route exact path="/settings" element={<SettingsView seller={seller.id} userdata={userdata} features={features} />} />

				<Route exact path={frozenPage} element={<Frozen />} />

				<Route path="/*" element={<Error />} />
			</Routes>
		</>

	);
}
