import React, {
	useState,
	useEffect
} from "react";
import "../SettingsView.scss";
import {
	Checkbox,
	Grid,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Paper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataContext } from "../../../App";
import SelectWarehouse from "../../Shared/SelectWarehouse/SelectWarehouse";

export default function CourierConfiguration({ seller }) {
	const context = React.useContext(DataContext);

	const [state, setState] = useState({
		warehouse: 0,
		warehouseCourierServices: [],
		sellerCourierServices: [],
	});

	const [page, setPage] = useState(0);
	const [rowsPerPage,] = useState(10);

	const rows = () => {
		if (!state.warehouseCourierServices) {
			return [];
		}

		return state.warehouseCourierServices.sort((a, b) => a.name > b.name ? 1 : -1);
	};

	useEffect(() => {
		const loadSellerCourierServices = async () => {
			context.dataProvider.getSellerCourierServicesForWarehouse(seller, state.warehouse)
				.then(response => {
					setState(previousState => {
						let courierServiceIDs = [];
						for (let index in response.data) {
							courierServiceIDs.push(response.data[index].id);
						}

						return {
							...previousState,
							sellerCourierServices: courierServiceIDs,
						};
					});
				});
		};
		loadSellerCourierServices();
	}, [context.dataProvider, state.warehouse, seller]);

	useEffect(() => {
		if (state.warehouse == null || state.warehouse === 0) return;

		const loadWarehouseCourierServices = async () => {
			context.dataProvider.getAllCourierServicesForWarehouse(state.warehouse)
				.then(response => {
					setState(previousState => {
						return {
							...previousState,
							warehouseCourierServices: response.data,
						};
					});
				});
		};
		loadWarehouseCourierServices();
	}, [context.dataProvider, state.warehouse]);

	useEffect(() => {
		if (!state.warehouse || !seller) {
			return;
		}

		const updateSellerCourierServices = async () => {
			context.dataProvider.updateSellerCourierServicesForWarehouse(seller, state.warehouse, state.sellerCourierServices)
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							responseError: "Error updating seller courier services",
						};
					});
				});
		};

		const timeoutId = setTimeout(() => updateSellerCourierServices(), 3000);
		return () => clearTimeout(timeoutId);
	}, [context.dataProvider, seller, state.warehouse, state.sellerCourierServices]);

	const onChangeCourierConfigurationWarehouse = (warehouseID) => {
		setState(previousState => {
			return {
				...previousState,
				warehouse: warehouseID
			};
		});
	};

	const toggleCourierService = (e) => {
		const courierServiceSet = new Set(state.sellerCourierServices);

		const id = Number(e.target.value);

		if (courierServiceSet.has(id)) {
			courierServiceSet.delete(id);
		} else {
			courierServiceSet.add(id);
		}

		setState(previousState => {
			return {
				...previousState,
				sellerCourierServices: [...courierServiceSet]
			};
		});
	};

	const isCourierServiceSelected = (id) => {
		const courierServiceSet = new Set(state.sellerCourierServices);
		return courierServiceSet.has(id);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const CourierServiceTable = () => {
		return (
			<TableContainer component={Paper}>
				<Table
					aria-label="courier service table"
					id="courier-service-table"
					stickyHeader
					size={"small"}
				>
					<TableHead>
						<TableRow>
							<TableCell>Courier Service</TableCell>
							<TableCell>Enabled</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							(state.warehouseCourierServices) ?
								rows().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cs, index) =>
									<TableRow key={index}>
										<TableCell>
											{cs.name}
										</TableCell>
										<TableCell>
											<Checkbox
												value={cs.id}
												onChange={toggleCourierService}
												checked={isCourierServiceSelected(cs.id)}
												disabled={cs.is_seller_default}
											/>
										</TableCell>
									</TableRow>
								) :
								<TableRow>
									<TableCell colSpan={2} align="center">
										No courier services found
									</TableCell>
								</TableRow>
						}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={rows().length}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[]}
					page={page}
					onPageChange={handleChangePage}
				/>
			</TableContainer>
		);
	};

	return (
		<Grid container sx={{ mb: 1 }}>
			<Grid item xs="auto">
				<Typography variant="h6" sx={{ mb: 2 }}>
					Courier Configuration
					{state.isLoadingUpdate ? <CircularProgress size={20} sx={{ ml: 1 }} /> : null}
				</Typography>
			</Grid>
			<Grid item xs />
			<Grid item xs="auto">
				<SelectWarehouse seller={seller} warehouse={state.warehouse}
					onChangeWarehouse={onChangeCourierConfigurationWarehouse} />
			</Grid>
			<CourierServiceTable />
		</Grid>
	);
}
