import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
	Link,
	Button,
	MenuItem,
	Menu,
	ListItemText,
	ListItemIcon,
	Fade
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/LocalShippingTwoTone";
import ProofOfDeliveryIcon from "@mui/icons-material/Grading";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";
import SearchDetail from "@mui/icons-material/ContentPasteSearchTwoTone";
import OrderStatusDetail from "../OrderStatusDetail";
import RequestProofofDeliveryButton from "../Shared/RequestProofofDelivery/RequestProofofDelivery";
import OrderTimelineIcon from "@mui/icons-material/Timeline";
import Modal from "../Shared/Modals/Modal";
import OrderTimeline from "../Shared/OrderTimeline/OrderTimeline";
import DownloadDocument from "../Shared/DownloadDocument/DownloadDocument";

export default function FadeMenu({
	order, orderID, sellerID, trackingNumber, courierService,
	status, orderTrackingLink, manual_pod_request,
	podRequestSent
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [state, setState] = useState({
		statusDetailHidden: true,
		isModalOpen: false,
		modalBodyTitle: "",
		modalButtonVariant1: "outlined",
		modalButtonText1: "Close",
		modalButtonActionType1: "close",
		modalButtonAction1: "",
	});

	const open = Boolean(anchorEl);
	const courierStatus = status;
	const orderNumber = order;
	const trackingLink = orderTrackingLink;
	const manualPodRequest = manual_pod_request;

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickStatusDetail = () => {
		setState(previousState => {
			return {
				...previousState,
				statusDetailHidden: false,
			};
		});
	};
	const handleCloseStatusDetail = () => {
		setState(previousState => {
			return {
				...previousState,
				statusDetailHidden: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const getOrderTimelineModal = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: `${orderNumber} Order Timeline`,
				modalBodyMessage1Data: <OrderTimeline orderID={orderID} seller={sellerID} />,
				isModalOpen: true,
			};
		});
		handleClose();
	};

	return (
		<>
			<Button
				variant="outlined"
				id="fade-button"
				aria-controls="fade-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				disableElevation
			>
				<MoreHorizIcon fontSize="small" />
			</Button>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				elevation={5}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>

				{courierStatus === "delivered" && manualPodRequest === true ?
					<RequestProofofDeliveryButton
						buttonName={"Request Proof of Delivery"}
						buttonType={"menu"}
						orderNumber={orderNumber}
						sellerID={sellerID}
						trackingNumber={trackingNumber}
						courierService={courierService}
						podRequestSent={podRequestSent}
					/>
					:
					courierStatus === "delivered" && trackingLink && <MenuItem component={Link} href={trackingLink} target="_blank">
						<ListItemIcon>
							<ProofOfDeliveryIcon />
						</ListItemIcon>
						<ListItemText>
							View Proof Of Delivery
						</ListItemText>
						<ArrowRight />
					</MenuItem>
				}

				{courierStatus && <MenuItem onClick={handleClickStatusDetail}>
					<ListItemIcon>
						<SearchIcon />
					</ListItemIcon>
					<ListItemText>
						View Tracking Information
					</ListItemText>
					<ArrowRight />
				</MenuItem>
				}

				{!state.statusDetailHidden &&
					<OrderStatusDetail onClose={handleCloseStatusDetail} orderNumber={orderNumber} />
				}

				<MenuItem component={RouterLink} to={"/order-view/" + encodeURIComponent(orderNumber)}>
					<ListItemIcon>
						<SearchDetail />
					</ListItemIcon>
					<ListItemText>
						View Order Details
					</ListItemText>
					<ArrowRight />
				</MenuItem>

				<MenuItem onClick={getOrderTimelineModal}>
					<ListItemIcon>
						<OrderTimelineIcon />
					</ListItemIcon>
					<ListItemText>
						View Order Timeline
					</ListItemText>
					<ArrowRight />
				</MenuItem>

				<DownloadDocument
					name="Commercial Invoice"
					pageType="summary"
					id={orderID}
				/>

			</Menu>
			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
			/>
		</>
	);

}
