import React from "react";
import {
	Box,
	Button
} from "@mui/material";
import DynamicFeedIcon from "@mui/icons-material/ListAlt";
import BasicTable from "../Table/BasicTable.jsx";

export default function CourierTracking({
	urls, 
	numbers, 
	order, 
	onModalOpen
}) {
	
	if (!urls || !numbers || !order) return;

	const onClickCallModalMultipleTracking = () => {
		const data = numbers.map((number, index) => ({
			order,
			tracking: number,
			link: urls[index],
		}));

		const modalData = {
			modalBodyTitle: `Order ${order} Tracking`,
			modalBodyMessage1: "",
			modalBodyMessage2: "", 
			modalButtonVariant1: "contained",
			modalButtonText1: "Close",
			modalButtonActionType1: "close",
			modalButtonAction1: "",
			modalBodyMessage1Data: <BasicTable data={data} />,
			modalButtonVariant2: "",
			modalButtonText2: "",
			modalButtonActionType2: "",
			modalButtonAction2: "",
			modalButtonColor2: "",
			isModalOpen: true,
		};

		onModalOpen(modalData);
	};

	const TrackingTableCell = () => {
		if (urls.length === 1 && numbers.length === 1) {
			return (
				urls[0] !== "" ? (
					<Box
						component="a"
						href={urls[0]}
						target="_blank"
						rel="noopener noreferrer"
						className="external-link"
					>
						{numbers[0]}
					</Box>
				) : (
					<Box component="span">
						{numbers[0]}
					</Box>
				)
			);
		}

		if (numbers.length > 1) {
			return (
				<Button
					variant="contained"
					color="info"
					onClick={onClickCallModalMultipleTracking}
				>
                    Multi <DynamicFeedIcon sx={{ ml: 1 }}/>
				</Button>
			);
		}

		return;
	};

	return <TrackingTableCell />;
}
