import React, {
	useCallback,
	useContext
} from "react";
import {
	Link,
	useParams
} from "react-router-dom";
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	Grid
} from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/text";
import { DataContext } from "../../App";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { setDocumentTitle } from "../../utils/page";
import UseFetchData from "../Shared/FetchData/FetchData";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";

export default function ReturnDetail({ seller }) {

	const { id: return_id } = useParams();
	const context = useContext(DataContext);
	const { status, results, isLoading } = UseFetchData(
		useCallback(() => context.dataProvider.getSellerReturn(seller, return_id), [context.dataProvider, seller, return_id])
	);

	const BackButton = () => (
		<Button variant="contained" component={Link} to="/returns">
			Go Back
		</Button>
	);

	const pageName = `Return Details: ${results?.order_number ?? ""}`;
	setDocumentTitle(pageName);

	const TitleHeading = () => (
		<Box mb={0}>
			<Grid container spacing={1}>
				<Grid item xs={12} container spacing={3} mt={0} mb={2}>
					<Grid item xs="auto">
						<Typography component="h1" variant="h5">
							{pageName}
						</Typography>
					</Grid>
					<Grid item xs="auto">
						<StatusBox status={results?.status ?? ""} statusType="order" />
					</Grid>
					<Grid item xs />
				</Grid>
			</Grid>
		</Box>
	);

	const BodyHeading = () => (
		<Box mb={2}>
			<TextField
				variant="outlined"
				id="return-id"
				label="Return ID"
				value={results?.bezos_system_id ?? ""}
				fullWidth
				margin="normal"
				size="small"
				InputProps={{ readOnly: true }}
			/>
			<TextField
				variant="outlined"
				id="status"
				label="Status"
				value={capitalizeFirstLetter(results?.status ?? "")}
				fullWidth
				margin="normal"
				size="small"
				InputProps={{ readOnly: true }}
			/>
			<TextField
				variant="outlined"
				id="order-number"
				label="Order Number"
				value={results?.order_number ?? ""}
				fullWidth
				margin="normal"
				size="small"
				InputProps={{ readOnly: true }}
			/>
		</Box>
	);

	const ListItems = () => {
		if (Array.isArray(results?.items) && results?.items.length > 0) {
			return results?.items.map((s) => (
				<TableRow key={s?.sku}>
					<TableCell align="left">{s?.sku ?? ""}</TableCell>
					<TableCell align="left">{s?.name ?? ""}</TableCell>
					<TableCell align="left">{s?.quantity ?? ""}</TableCell>
					<TableCell align="left">{s?.return_reason ?? ""}</TableCell>
					<TableCell align="left">{s?.comments ?? ""}</TableCell>
				</TableRow>
			));
		} else {
			return (
				<TableRow>
					<TableCell colSpan={5} align="center">
						No items found
					</TableCell>
				</TableRow>
			);
		}
	};

	const ProductsTable = () => (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="left">SKU</TableCell>
						<TableCell align="left">Name</TableCell>
						<TableCell align="left">Quantity</TableCell>
						<TableCell align="left">Return Reason</TableCell>
						<TableCell align="left">Comments</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<ListItems />
				</TableBody>
			</Table>
		</TableContainer>
	);

	return (
		<LoadableContent isLoading={isLoading}>
			<Box>
				{status ? (
					<>
						<BreadCrumb
							breadcrumbs={[
								{ label: "Returns", url: "/returns" },
								{ label: results?.order_number }
							]}
						/>
						<TitleHeading />
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<BodyHeading />
							<ProductsTable />
						</Paper>
					</>
				) : (
					<>
						<Typography mb={2}>Invalid Return ID</Typography>
						<BackButton />
					</>
				)}
			</Box>
		</LoadableContent>
	);
}
