import React, {
	useState,
	useEffect
} from "react";
import { DataContext } from "../../../App";
import DropDown from "../../Shared/DropDown";
import { AlertBox } from "../../Shared/Alert/Alert";

const SelectWrapper = ({
	warehouses,
	selectedWarehouseID,
	selectedCourier,
	seller,
	onChangeWarehouse,
	onChangeCourier,
	disabled,
	countryID,
	...otherProps
}) => {

	const context = React.useContext(DataContext);
	const [couriers, setCouriers] = useState({ data: [], isLoading: false });

	useEffect(() => {
		if (selectedWarehouseID && countryID) {
			const getCourierList = async () => {
				setCouriers({ isLoading: true });
				await context.dataProvider.getSellerCourierServicesForWarehouse(seller, selectedWarehouseID, countryID)
					.then((response) => {
						setCouriers({ isLoading: false, data: response.data || [] });
					})
					.catch(() => {
						setCouriers({ isLoading: false, data: [] });
					});
			};
			getCourierList();
		}
	}, [selectedWarehouseID, seller, context.dataProvider, countryID]);

	const handleWarehouseChange = e => {
		setCouriers(prevState => ({ ...prevState, isLoading: true }));
		onChangeWarehouse(e);
	};

	const handleCourierChange = e => {
		onChangeCourier(e);
	};

	const WarehouseDropDown = () => {
		return (
			<DropDown
				id="warehouse_id"
				name="warehouse_id"
				label="Warehouse"
				onChange={handleWarehouseChange}
				data={warehouses.resultsData.map(({ id, name }) => ({ key: id, value: id, name: name }))}
				value={selectedWarehouseID}
				disabled={disabled}
				isLoading={warehouses.isLoading}
				{...otherProps}
			/>
		);
	};

	const CourierDropDown = () => {
		return (
			<>
				{countryID && selectedWarehouseID && 
					(couriers.isLoading || couriers.data.length > 0) && 
					<DropDown
						id="courier_service"
						name="courier_service"
						label="Courier"
						onChange={handleCourierChange}
						value={selectedCourier}
						data={Array.isArray(couriers.data) ? couriers.data.map(c => ({ key: c.id, value: c.name, name: c.name })) : []}
						{...otherProps}
						required={true}
						isLoading={couriers.isLoading}
					/>
				}

				{countryID && selectedWarehouseID 
				&& couriers.isLoading === false && couriers.data.length === 0 &&
				<AlertBox 
					severity="error" 
					message="No couriers found, please contact support@bezos.ai"
					sx={{ mt: 1.5 }}
				/>
				}
			</>
		);
	};

	return (
		<>
			<WarehouseDropDown />
			<CourierDropDown />
		</>
	);
};

export default SelectWrapper;